import React, { useState } from "react";
import "./index.scss";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
} from "@coreui/react";
import { API_URL } from "utils/settings";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
function CouponsForm(props) {
  const [count, setCount] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [amount, setAmount] = useState("");
  const { t } = useTranslation();
  const submitForm = (e) => {
    e.preventDefault();

    Axios.post(`${API_URL}/admin/coupon`, {
      count: +count,
      currency: currency,
      amount: amount,
    })
      .then(function (response, error) {
        if (response) {
          toast.success(t("toast.coupons"));
          props.callback();
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <form onSubmit={(e) => submitForm(e)} className="coupons-form">
        <div>
          <CFormLabel>{t(`table.count`)}</CFormLabel>
          <CFormInput
            placeholder={t(`table.count`)}
            value={count}
            type="number"
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
            onChange={(e) => {
              if (e.target.value.split(".")?.[1]?.length > 2) {
                return;
              }
              const val = e.target.value.replace("e", "").replace("е", "");
              if (val > 1000) {
                setCount(1000);
              } else {
                setCount(e.target.value);
              }
            }}
            required
            min="1"
            max="1000"
            style={{ minWidth: "150px" }}
          />
        </div>
        <div>
          <CFormLabel>{t(`table.amount`)}</CFormLabel>
          <CFormInput
            type="number"
            placeholder={t(`table.amount`)}
            value={amount}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
            onChange={(e) => {
              if (e.target.value.split(".")?.[1]?.length > 2) {
                return;
              }
              const val = e.target.value.replace("e", "").replace("е", "");
              if (val > 9999) {
                setAmount(1000);
              } else {
                setAmount(e.target.value);
              }
            }}
            style={{ minWidth: "150px" }}
            required
            min="1"
            max="9999"
          />
        </div>
        <div>
          <CFormLabel>{t(`placeholder.currency`)}</CFormLabel>

          <CFormSelect
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
            style={{ minWidth: "150px" }}
            value={currency}
            options={[
              { label: "USD", value: "USD" },
              { label: "RUB", value: "RUB", disabled: true },
              { label: "CNY", value: "CNY", disabled: true },
            ]}
            // value={el.value}
            // options={el.items.map((item) => {
            //   return {
            //     label: t(`table.${item}`),
            //     value: item,
            //   };
            // })}
          />
        </div>
        <CButton type="submit">{t("modal.create")}</CButton>
      </form>
    </>
  );
}

export default CouponsForm;
