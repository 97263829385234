import React, { useState, useEffect } from "react";
import {
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
  CFormInput as Input,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CFormSelect,
  CModal,
  CModalTitle,
  CModalBody,
  CModalHeader,
  CForm,
  CCol,
  CFormLabel,
  CFormInput,
  CButton,
  CModalFooter,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser, cilHttps, cilEyedropper } from "@coreui/icons";
import "./index.scss";
import { Link } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import ToastComponent from "components/ToastComponent";
import PasswordValidation from "components/PasswordValidation";
import { toast } from "react-toastify";
import ViewIcon from "../../assets/images/view.png";
import Generator from "generate-password";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useLocalStorage } from "utils/utils";
function AuthPage() {
  const [activeKey, setActiveKey] = useState(1);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [resetPass, setResetPass] = useState("");
  const [panelType, setPanelType] = useLocalStorage("panelType", "user");
  const [qr, setQR] = useState(null);
  const [mailNotify, showMailNotify] = useState(false);
  const [loginMailNotify, showLoginMailNotify] = useState(false);
  const [code, setCode] = useState("");
  const [show2fa, setShow2fa] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [regUser, setRegUser] = useState("");
  const [regPass, setRegPass] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (queryParams.has("reset_token")) {
      setActiveKey(4);
    }
  }, []);
  const confirm2FA = () => {
    Axios.post(`${API_URL}/auth/2fa_check`, {
      oneTimeCode: code,
    })
      .then(function (response) {
        if (response?.data?.token) {
          localStorage.setItem("token", response.data.token);
          panelType == "user"
            ? (window.location.href = "/panel/main")
            : (window.location.href = "/admin/stats/users");
        }
      })
      .catch((error) => {});
  };

  const login = (e) => {
    e.preventDefault();

    Axios.post(`${API_URL}/auth/login_check`, {
      email: user,
      password: pass,
    })
      .then(function (response, error) {
        if (response?.data?.emailConfirmation === false) {
          toast.info(t("register.login_notify"));
          showLoginMailNotify(true);
          return;
        }
        if (response?.data?.token) {
          localStorage.setItem("token", response.data.token);
          window.location.href = "/panel/main";
        } else if (
          response?.data?.login == "success" &&
          response?.data?.twoFactorComplete === false
        ) {
          setShow2fa(true);
        }
      })
      .catch((error) => {});
  };
  const register = (e) => {
    e.preventDefault();
    if (
      !passwordIsValid ||
      /[^\w\-!$%^&*()_+|~={}\[\]:\/;<>?,.@#№]/.test(regPass)
    ) {
      toast.info(t("password.valid"));
    } else {
      Axios.post(`${API_URL}/register`, {
        email: regUser,
        password: regPass,
        currency: currency,
      })
        .then(function (response) {
          if (response) {
            showMailNotify(true);
            toast.info(t("register.notify"));
          }
        })
        .catch((error) => {});
    }
  };
  const forgot = (e) => {
    e.preventDefault();

    Axios.post(`${API_URL}/password/forgot`, {
      email: user,
    })
      .then(function (response, error) {
        if (response) {
          toast.info(t("common.forgot_info"));
        }
      })
      .catch((error) => {});
  };
  const resetPassword = (e) => {
    e.preventDefault();

    Axios.post(`${API_URL}/password/reset`, {
      token: queryParams.get("reset_token"),
      password: resetPass,
    })
      .then(function (response, error) {
        if (response) {
          toast.info(t("common.password_updated"));
          setActiveKey(1);
          localStorage.removeItem("token");

          queryParams.delete("reset_token");
          history.replace({
            search: queryParams.toString(),
          });
        }
      })
      .catch((error) => {});
  };
  const resendMail = () => {
    Axios.post(`${API_URL}/email/resend`, {
      email: user,
    })
      .then(function (response, error) {
        if (response) {
          toast.success(t("resend.true"));
        } else {
          toast.error(t("resend.false"));
        }
      })
      .catch((error) => {
        toast.error(t("resend.false"));
      });
  };
  const generatePass = (setValue) => {
    setValue("");
    const min = 10;
    const max = 12;
    const passwordLength = Math.floor(Math.random() * (max - min + 1) + min);

    let password = Generator.generate({
      length: passwordLength,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      strict: true,
    });
    do {
      password = Generator.generate({
        length: passwordLength,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
        strict: true,
      }).replace(/['`"]+/g, "");
    } while (password?.length < min);
    setValue(password);
    navigator.clipboard.writeText(password);
    toast.info(t("password.is_generated"));
  };
  return (
    <>
      <section className="auth bg-light min-vh-100 d-flex flex-row align-items-center">
        <div className="container">
          <div className="auth__form">
            <div className="card p-4 w-50 m-auto">
              <div className="card-body">
                <CNav variant="tabs" role="tablist">
                  <CNavItem>
                    <CNavLink
                      active={activeKey === 1}
                      onClick={() => setActiveKey(1)}
                    >
                      {t("common.login")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      active={activeKey === 2}
                      onClick={() => setActiveKey(2)}
                    >
                      {t("common.registration")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem className="hidden" style={{ display: "none" }}>
                    <CNavLink
                      active={activeKey === 3}
                      onClick={() => setActiveKey(3)}
                    >
                      {t("common.password")}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <br />
                <CTabContent>
                  <CTabPane
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    visible={activeKey === 1}
                  >
                    <form onSubmit={(e) => login(e)}>
                      <h3>{t("login.title")}</h3>
                      {loginMailNotify && (
                        <CAlert color="info">
                          <span>{t("register.login_notify")}</span>
                          <span
                            className="d-block text-decoration-underline"
                            style={{ cursor: "pointer" }}
                            onClick={() => resendMail()}
                          >
                            {t("register.resend")}
                          </span>
                        </CAlert>
                      )}

                      <InputGroup className="mb-3">
                        <InputGroupText id="user">
                          <CIcon icon={cilUser} />
                        </InputGroupText>
                        <Input
                          placeholder={t("common.username")}
                          onChange={(e) => {
                            setUser(e.target.value);
                            if (loginMailNotify) {
                              showLoginMailNotify(false);
                            }
                          }}
                          aria-label="Имя пользователя"
                          aria-describedby="user"
                          name="email"
                          required
                          type="email"
                          autoComplete
                          value={user}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupText id="password">
                          <CIcon icon={cilHttps} />
                        </InputGroupText>
                        <Input
                          placeholder={t("common.password")}
                          onChange={(e) => setPass(e.target.value)}
                          type="password"
                          aria-label="Пароль"
                          name="password"
                          autoComplete
                          required
                          value={pass}
                          aria-describedby="password"
                        />
                      </InputGroup>
                      <button
                        className="link link-primary d-flex mb-3"
                        type="button"
                        onClick={() => setActiveKey(3)}
                        style={{ marginTop: "-10px" }}
                      >
                        {t("common.forgot")}
                      </button>
                      <div className="d-flex justify-content-end">
                        <Link to="/" className="btn btn-secondary">
                          {t("common.cancel")}
                        </Link>
                        <button
                          type="submit"
                          className="btn btn-primary mx-xl-3"
                        >
                          {t("common.login")}
                        </button>
                      </div>
                    </form>
                  </CTabPane>
                </CTabContent>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  visible={activeKey === 2}
                >
                  <form onSubmit={(e) => register(e)}>
                    <h3>{t("common.registration")}</h3>
                    <CAlert color="secondary" style={{fontSize: '14px'}}>{t("register.alert")}</CAlert>
                    {mailNotify && (
                      <CAlert color="primary">{t("register.notify")}</CAlert>
                    )}
                    <InputGroup className="mb-3">
                      <InputGroupText id="user">
                        <CIcon icon={cilUser} />
                      </InputGroupText>
                      <Input
                        placeholder={t("common.username")}
                        onChange={(e) => setRegUser(e.target.value)}
                        aria-label="Имя пользователя"
                        aria-describedby="user"
                        value={regUser}
                        name="email"
                        type="email"
                        required
                        autoComplete
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupText id="password">
                        <CIcon icon={cilHttps} />
                      </InputGroupText>
                      <Input
                        placeholder={t("common.password")}
                        onChange={(e) =>
                          // setRegPass(e.target.value.replace(/['`"]+/g, ""))
                          setRegPass(e.target.value)
                        }
                        type={showPass ? "text" : "password"}
                        name="password"
                        autoComplete
                        required
                        aria-label="Пароль"
                        aria-describedby="password"
                        value={regPass}
                      />
                      <CButton
                        type="button"
                        color="secondary"
                        variant="outline"
                        onClick={() => setShowPass(!showPass)}
                      >
                        <div className={`icon-eye ${showPass ? "active" : ""}`}>
                          <img src={ViewIcon} alt="" />
                        </div>
                      </CButton>
                    </InputGroup>
                    <button
                      className="link link-primary d-flex mb-3"
                      type="button"
                      onClick={() => generatePass(setRegPass)}
                      style={{ marginTop: "-10px" }}
                    >
                      {t("password.generate")}
                    </button>
                    <PasswordValidation
                      value={regPass}
                      setPasswordValidation={setPasswordIsValid}
                    />

                    <InputGroup className="mb-3" style={{ display: "none" }}>
                      <CFormSelect
                        aria-label="Set currency"
                        placeholder="Set currency"
                        value={currency}
                        onChange={(event) => setCurrency(event.target.value)}
                        options={[
                          { label: "RUB", value: "RUB" },
                          { label: "USD", value: "USD" },
                        ]}
                      />
                    </InputGroup>
                    <p className="auth-notice">
                      {t("auth.notice")}{" "}
                      <a href="/rules" target="_blank">
                        {t("auth.agree")}
                      </a>
                    </p>
                    <div className="d-flex justify-content-end">
                      <Link to="/" className="btn btn-secondary">
                        {t("common.cancel")}
                      </Link>
                      <button type="submit" className="btn btn-primary mx-xl-3">
                        {t("front_page.body.register")}
                      </button>
                    </div>
                  </form>
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  visible={activeKey === 3}
                >
                  <form onSubmit={(e) => forgot(e)}>
                    <h3>{t("common.password_forgot")}</h3>
                    <h6 style={{ fontWeight: "400", marginBottom: "15px" }}>
                      {t("common.write_mail")}
                    </h6>
                    {mailNotify && (
                      <CAlert color="primary">{t("register.notify")}</CAlert>
                    )}
                    <InputGroup className="mb-3">
                      <InputGroupText id="user">
                        <CIcon icon={cilUser} />
                      </InputGroupText>
                      <Input
                        placeholder={t("common.email")}
                        onChange={(e) => setUser(e.target.value)}
                        aria-label="Имя пользователя"
                        aria-describedby="user"
                        value={user}
                        name="email"
                        type="email"
                        required
                        autoComplete
                      />
                    </InputGroup>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={() => setActiveKey(1)}
                        className="btn btn-secondary"
                      >
                        {t("common.cancel")}
                      </button>
                      <button type="submit" className="btn btn-primary mx-xl-3">
                        {t("common.restore")}
                      </button>
                    </div>
                  </form>
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  visible={activeKey === 4}
                >
                  <form onSubmit={(e) => resetPassword(e)}>
                    <h3>{t("common.password_forgot")}</h3>
                    <h6 style={{ fontWeight: "400", marginBottom: "15px" }}>
                      {t("common.update_password")}
                    </h6>
                    <InputGroup className="mb-3">
                      <InputGroupText id="password">
                        <CIcon icon={cilHttps} />
                      </InputGroupText>
                      <Input
                        placeholder={t("common.password")}
                        onChange={(e) =>
                          // setRegPass(e.target.value.replace(/['`"]+/g, ""))
                          setResetPass(e.target.value)
                        }
                        type={showPass ? "text" : "password"}
                        name="password"
                        autoComplete
                        required
                        aria-label="Пароль"
                        aria-describedby="password"
                        value={resetPass}
                      />
                      <CButton
                        type="button"
                        color="secondary"
                        variant="outline"
                        onClick={() => setShowPass(!showPass)}
                      >
                        <div className={`icon-eye ${showPass ? "active" : ""}`}>
                          <img src={ViewIcon} alt="" />
                        </div>
                      </CButton>
                    </InputGroup>
                    <button
                      className="link link-primary d-flex mb-3"
                      type="button"
                      onClick={() => generatePass(setResetPass)}
                      style={{ marginTop: "-10px" }}
                    >
                      {t("password.generate")}
                    </button>
                    <PasswordValidation
                      value={resetPass}
                      setPasswordValidation={setPasswordIsValid}
                    />
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={() => setActiveKey(1)}
                        className="btn btn-secondary"
                      >
                        {t("common.cancel")}
                      </button>
                      <button type="submit" className="btn btn-primary mx-xl-3">
                        {t("common.submit")}
                      </button>
                    </div>
                  </form>
                </CTabPane>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CModal
        backdrop="static"
        visible={show2fa}
        onClose={() => setShow2fa(false)}
      >
        <CModalHeader>
          <CModalTitle>{t("2fa.enable")}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>{t("2fa.login")}</p>
          <div>
            <CForm className="row g-3">
              <CCol xs="auto">
                <CFormInput
                  type="number"
                  id="inputPassword2"
                  placeholder={t("common.your_code")}
                  value={code}
                  required
                  maxLength="6"
                  onChange={(e) => setCode(e.target.value)}
                />
              </CCol>
            </CForm>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShow2fa(false)}>
            {t("common.cancel")}
          </CButton>
          <CButton color="primary" onClick={() => confirm2FA()}>
            {t("common.login")}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default AuthPage;
