import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo-sms.png';
function About() {
    const { t } = useTranslation()

    return <section className="about" id="about">
        <div className="container">
            <div className="about__content">
                <div>
                    <h1>{t('heading.tagline')}</h1>
                    <h4>{t('heading.subtitle')}</h4>
                </div>
                <img src={Logo} alt="" />
            </div>

            <div className="about__info">
                <h3>{t('nav.about')}</h3>
                <p>{t('about.text')}</p>
                <h5>{t('about.subtitle')}</h5>
                <ul>
                    <li>{t('about.list1')}</li>
                    <li>{t('about.list2')}</li>
                    <li>{t('about.list3')}</li>
                </ul>
                <p>{t('about.subtext')}</p>
            </div>
        </div>
    </section>
}

export default About