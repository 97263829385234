import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import CIcon from '@coreui/icons-react';
import { cilUserPlus, cilDollar, cilCommentBubble } from '@coreui/icons';

function Work() {
    const { t } = useTranslation()
    const items = [
        {
            icon: cilUserPlus,
            label: t('work.text1'),
        },
        {
            icon: cilDollar,
            label: t('work.text2'),
        },
        {
            icon: cilCommentBubble,
            label: t('work.text3'),
        },
    ]
    return <section className="work" id="work">
        <div className="container">
            <div className="work__content">
                <h3>
                    {t('nav.work')}
                </h3>
                <p> {t('work.subtitle')}</p>
                <div className="work__content-row">
                    {items.map((el) => {
                        return <div className="work__content-item">
                            <CIcon icon={el.icon} height="60px"  />
                            <span>{el.label}</span>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </section>
}

export default Work