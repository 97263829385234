import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import CIcon from "@coreui/icons-react";
import { cilCalendar } from "@coreui/icons";
import ToastComponent from "components/ToastComponent";
function FilterDatePicker(props) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [val, setVal] = useState(null);
  const [startDate, setStartDate] = useState(props.defaultValue);
  useEffect(() => {
    props.callBack(startDate || null);
  }, [startDate]);
  const handleChange = (date, need) => {
    let d1 = new Date(date);
    let d2 = new Date(startDate);
    const diffTime = Math.abs(d2 - d1);
    if (need && diffTime > 0) {
      const dt = new Date(date);
      setStartDate(dt);
    }
    setVal(date);
  };
  return (
    <div className="date-picker" onClick={() => ref.current.focus()}>
      <input
        type="datetime-local"
        placeholderText={t("filter.datepicker")}
        showIcon
        className="form-control"
        isClearable
        ref={ref}
        onSelect={(e) => {
          if (!e.bubbles) {
            handleChange(e.target.value, true);
          }
        }}
        onBlur={(e) => {
          const inputDate = new Date(e.target.value);
          const isValid = !isNaN(inputDate?.getDate());
          if (!isValid) {
            ToastComponent("error", t("date.invalid"));
            return;
          }
          handleChange(e.target.value, true);
        }}
        defaultValue={props.defaultValue}
        value={val}
        onChange={(e) => {
          handleChange(e.target.value, false);
        }}
      />
    </div>
  );
}

export default FilterDatePicker;
