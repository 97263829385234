import React from "react";
import "./index.scss";
import { cilBurn, cilInfo, cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";

function Notifier({ items }) {
    const { t } = useTranslation();
  const icons = {
    error: cilBurn,
    warning: cilWarning,
    info: cilInfo,
  };
  return (
    <div className="notifier__wrapper">
      {items.map((el) => {
        return (
          <div className={`notifier notifier_${el.level}`}>
            {el.message && (
              <div className={`notifier__text`}>
                {t(el.message)} <CIcon icon={icons[el.level]} size="lg"/>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Notifier;
