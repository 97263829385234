import Toggle from "react-toggle";
import {
  changeToggle,
  deactivateRestriction,
  deactivateUser,
  deactivatePartner,
} from "./confirmEvents";

export const generateRoles = (
  value,
  isLoading,
  setLoading,
  callback,
  id,
  t
) => {
  const onChangeHandler = (e) =>
    boolConstructorHandlers(e, value, isLoading, setLoading, callback, id);

  const commonProps = {
    disabled: isLoading,
    checked: value.value,
    onChange: onChangeHandler,
    id: value.name,
  };

  return (
    <label>
      <Toggle {...commonProps} />
      <span>{t(`placeholder.${value.name}`)}</span>
    </label>
  );
};

const boolConstructorHandlers = (
  e,
  val,
  isLoading,
  setLoading,
  callback,
  id
) => {
  const handlers = {
    isActivateCouponRestricted: () =>
      deactivateRestriction(e, isLoading, setLoading, callback, id),
    isActivated: () => deactivateUser(e, isLoading, setLoading, callback, id),
    isPartner: () => deactivatePartner(e, isLoading, setLoading, callback, id),
  };
  const handler =
    handlers[val.name] ||
    (() => changeToggle(val, e, setLoading, callback, id));
  return handler();
};
