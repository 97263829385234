import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { UserContext } from "core/contexts";
import OrderTable from "pages/AdminMainPage/OrderTable";
import { CFormSelect } from "@coreui/react";
import Filters from "components/Filters";
import {
  defaultBackWeekDate,
  defaultEndDate,
  defaultStartDate,
  useLocalStorage,
} from "utils/utils";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import _ from "lodash";
import ToastComponent from "components/ToastComponent";

function AdminStatsPage(props) {
  const { t } = useTranslation();
  const [orderStats, setOrderStats] = useState([]);
  const [userStats, setUserStats] = useState({});
  const [activeOrdersList, setActiveOrdersList] = useState([]);
  const [adminActiveInterval, setAdminActiveInterval] = useLocalStorage(
    "adminActiveOrdersInterval",
    10000
  );
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [finishedOrdersList, setFinishedOrdersList] = useState([]);
  const userContext = useContext(UserContext);
  const [activeLoading, setActiveLoading] = useState(true);
  const [finishLoading, setFinishLoading] = useState(true);
  const [activeTotal, setActiveTotal] = useState(0);
  const [finishTotal, setFinishTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [activePerPage, setActivePerPage] = useState(20);
  const [needFetch, setNeedFetch] = useState(true);
  const [finishPage, setFinishPage] = useState(1);
  const [finishPerPage, setFinishPerPage] = useState(20);
  const [aggrFilters, setAggrFilters] = useState([
    {
      name: "dateFrom",
      type: "date",
      value: defaultBackWeekDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);
  const [filters, setFilters] = useState([
    {
      name: "dateFrom",
      type: "date",
      value: defaultBackWeekDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);
  const [orderSorting, setOrderSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
    {
      name: "status",
      label: t("sorting.status"),
      value: null,
    },
    {
      name: "user",
      label: t("sorting.user"),
      value: null,
    },
  ]);
  const [activeSorting, setActiveSorting] = useState(null);
  const [finishedSorting, setFinishedSorting] = useState(null);
  const user = userContext.user;
  const getOrdersStats = () => {
    let filters = aggrFilters;
    let params = {};
    filters.forEach((el) => {
      if (el.value && el.value !== "all") {
        if (el.type == "date") {
          let date = new Date(el.value);
          // date.setDate(date.getDate() + 1);
          params = {
            ...params,
            [el.name]: date.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    if (!needFetch) {
      return;
    }
    Axios.get(`${API_URL}/admin/order/aggregation`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.data) {
          setOrderStats(response.data.data);
        }
        setNeedFetch(true);
      })
      .catch((error) => {
        setNeedFetch(false);
      });
  };
  const [defaultInterval, setDefaultInterval] = useState(adminActiveInterval);
  useEffect(() => {
    setAdminActiveInterval(defaultInterval);
    const interval = setInterval(() => {
      getActiveOrders(activePage, activePerPage, null, activeSorting);
      getOrdersStats();
    }, defaultInterval);
    return () => clearInterval(interval);
  }, [defaultInterval, activePerPage, activePage, aggrFilters, activeSorting]);

  const getActiveOrders = (page, perPage, filters, file = false) => {
    setActiveLoading(true);
    let params = {
      isPaginated: 1,
      statuses: ["waiting_sms", "waiting_phone"],
      page: page,
      perPage: perPage,
    };

    if (activeSorting !== "sorting.null" && activeSorting !== null) {
      params["sort"] = [activeSorting];
    }
    let dateTo = new Date(params.dateTo?.split("T")?.[0]);
    let dateFrom = new Date(params.dateFrom?.split("T")?.[0]);

    const differenceInMilliseconds = dateTo.getTime() - dateFrom.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (differenceInDays > 14 && !file) {
      ToastComponent("info", t("filters.date_two_week"));
      setActiveLoading(false);
      return;
    }
    setActiveLoading(true);
    if (!needFetch) {
      return;
    }
    Axios.get(`${API_URL}/admin/order/all`, {
      params: params,
    })
      .then(function (response) {
        setActiveLoading(false);
        if (response.data?.data?.length > 0) {
          setActiveOrdersList(response.data.data);
          setActiveTotal(response.data.total);
        } else {
          setActiveOrdersList([]);
          setActiveTotal(0);
        }
        setNeedFetch(true);
      })
      .catch((error) => {
        setNeedFetch(false);
        setActiveLoading(false);
      });
  };
  const getFinishedOrders = (page, perPage, filters, file = false) => {
    setFinishLoading(true);
    let params = {
      isPaginated: !file ? 1 : 0,
      statuses: ["finished", "canceled", "declined"],
    };
    if (!file) {
      params["page"] = page;
      params["perPage"] = perPage;
    }
    if (finishedSorting !== "sorting.null" && finishedSorting !== null) {
      params["sort"] = [finishedSorting];
    }
    filters.forEach((el) => {
      if ((el.value && el.value !== "all") || el.type == "date") {
        if (el.type == "multiselect") {
          params = { ...params, [el.name]: [el.value] };
          return;
        }
        if (el.type == "date") {
          if (!el.value) {
            return;
          }
          let date = new Date(el.value);
          params = {
            ...params,
            [el.name]: date.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    if (file) {
      params["statuses"] = ["finished"];
    }
    let dateTo = new Date(params.dateTo?.split("T")?.[0]);
    let dateFrom = new Date(params.dateFrom?.split("T")?.[0]);

    const differenceInMilliseconds = dateTo.getTime() - dateFrom.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (differenceInDays > 14 && !file) {
      ToastComponent("info", t("filters.date_two_week"));

      setFinishLoading(false);
      return;
    }

    Axios.get(`${API_URL}/admin/order/all`, {
      params: params,
    })
      .then(function (response) {
        setFinishLoading(false);
        if (response.data.data?.length > 0 && file) {
          const fileType = "text/csv;charset=utf-8";
          const fileExtension = ".csv";

          const ws = XLSX.utils.json_to_sheet(
            _.flatten(
              response.data.data.map((el) => {
                return {
                  id: el.id,
                  [t("table.amount")]: el.amount
                    ? new Intl.NumberFormat("ru-RU", {
                        style: "currency",
                        currency: user?.currency || "USD",
                      }).format(el.amount || 0)
                    : 0,
                  [t("table.country")]: el.countryAlpha2,
                  [t("table.service")]: el.serviceName,
                  [t("table.status")]: t(`table.${el.status}`),
                  [t("common.phone")]: `${el.phone}`,
                  [t("table.sms_content")]: el.smsContent,
                  [t("table.user")]: el.user?.email,
                  [t("table.date")]:
                    new Date(el.createdAt).toLocaleString().split(",")?.[0] ||
                    "—",
                };
              })
            )
          );
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "csv",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, "orders" + fileExtension);
          return;
        }
        if (response.data?.data?.length > 0 && !file) {
          setFinishedOrdersList(response.data.data);
          setFinishTotal(response.data.total);
        } else if (response.data?.data?.length == 0 && !file) {
          setFinishedOrdersList([]);
          setFinishTotal(0);
        } else if (response?.data?.data?.length == 0 && file) {
          ToastComponent("error", t("table.notFound"));
        }
      })
      .catch((error) => {
        setFinishLoading(false);
      });
  };
  useEffect(() => {
    getOrdersStats();
  }, [aggrFilters]);

  return (
    <>
      <div className={`page ${expanded ? "expanded" : ""}`}>
        <Sidebar />
        <div className="container container-panel">
          <div className="panel__content">
            <div className="balance stats">
              <div className="shadow-inset">
                <h6>
                  {t("heading.orders")}{" "}
                  {aggrFilters && (
                    <Filters items={aggrFilters} setFilters={setAggrFilters} />
                  )}
                </h6>

                <div className="stats-list">
                  {orderStats?.map((el) => {
                    return (
                      <div className="stats-item">
                        <span>{t(`table.${el.status}`)}</span> <b>{el.count}</b>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <br />
            <div className="stats-tables">
              <h5>
                {t("heading.active_orders")}{" "}
                <div className="stats-tables__select">
                  <div className="stats-tables__placeholder">
                    <b>{t("heading.interval")}</b>
                  </div>
                  <CFormSelect
                    value={defaultInterval}
                    onChange={(event) => setDefaultInterval(event.target.value)}
                    options={[
                      { label: "5", value: "5000" },
                      { label: "10", value: "10000" },
                      { label: "15", value: "15000" },
                      { label: "20", value: "20000" },
                    ]}
                  />
                </div>
              </h5>
              <OrderTable
                orders={activeOrdersList}
                setOrders={setActiveOrdersList}
                getOrders={getActiveOrders}
                total={activeTotal}
                sorting={orderSorting}
                activeSorting={activeSorting}
                setSorting={setActiveSorting}
                setTotal={setActiveTotal}
                currency={user?.currency}
                page={activePage}
                perPage={activePerPage}
                setPage={setActivePage}
                setPerPage={setActivePerPage}
                isLoading={activeLoading}
                needUser
              />
              <br /> <br />
              <h5>{t("heading.finished_orders")}</h5>
              <OrderTable
                orders={finishedOrdersList}
                setOrders={setFinishedOrdersList}
                getOrders={getFinishedOrders}
                total={finishTotal}
                setTotal={setFinishTotal}
                currency={user?.currency}
                page={finishPage}
                sorting={orderSorting}
                activeSorting={finishedSorting}
                setSorting={setFinishedSorting}
                perPage={finishPerPage}
                filters={filters}
                setFilters={setFilters}
                setPage={setFinishPage}
                setPerPage={setFinishPerPage}
                isLoading={finishLoading}
                needUser
              />
              <br /> <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminStatsPage;
