import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import { UserContext } from "core/contexts";
import { useTranslation } from "react-i18next";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CModal,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTooltip,
} from "@coreui/react";
import Axios from "axios";
import { API_URL } from "utils/settings";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { toast } from "react-toastify";
import CIcon from "@coreui/icons-react";
import { cibAppStore, cibGooglePlay } from "@coreui/icons";
import QRCode from "react-qr-code";
import TokenBlock from "./TokenBlock";
import UserForm from "./userForm";
import { generateUserInfo } from "pages/AdminUserPage/userUtils";
import { useLocalStorage } from "utils/utils";
import PrefixIgnore from "./prefixIgnore";

function SettingsPage(props) {
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [show2fa, setShow2fa] = useState(false);
  const [code, setCode] = useState("");
  const [qr, setQR] = useState(null);
  const { t } = useTranslation();

  const toggleTFA = (val) => {
    if (
      userContext.user.twoFactorEnabled &&
      userContext.user.twoFactorConfirmed
    ) {
      setShow2fa(true);
    } else if (
      !userContext.user.twoFactorEnabled &&
      !userContext.user.twoFactorConfirmed
    ) {
      Axios.post(`${API_URL}/user/2fa/state`)
        .then(function (response) {
          userContext.updateUser();
        })
        .catch((error) => {});
    }
  };
  const confirm2FA = (e) => {
    e.preventDefault();
    Axios.post(`${API_URL}/user/2fa/confirm`, {
      oneTimeCode: code,
    })
      .then(function (response) {
        userContext.updateUser();
      })
      .catch((error) => {});
  };
  const cancel2fa = () => {
    Axios.delete(`${API_URL}/user/2fa/state`)
      .then(function (response) {
        userContext.updateUser();
        setQR(null);
        setCode("");
        setShow2fa(false);
      })
      .catch((error) => {});
  };
  const getQR = () => {
    Axios.get(`${API_URL}/user/2fa/qr-code`).then(function (response) {
      setQR(response.data.data);
    });
  };
  useEffect(() => {
    if (!qr && user?.twoFactorEnabled) {
      getQR();
    }
  }, [user]);
  const getUserInfo = (val) => {
    let data = {};
    let newData = {
      email: user.email,
      balance: user.balance,
      currency: user.currency,
      frozenBalance: user.frozenBalance,
    };
    if (user?.partner?.isActive && user?.partner?.hash) {
      newData["hash"] = user?.partner?.hash;
    }
    return generateUserInfo(newData, data);
  };

  return (
    <div className={`page ${expanded ? "expanded" : ""}`}>
      <Sidebar />
      <div className="container container-panel">
        <div className="panel__content">
          <CModal
            backdrop="static"
            visible={show2fa}
            onClose={() => setShow2fa(false)}
          >
            <CModalHeader>
              <CModalTitle>{t("2fa.cancel")}</CModalTitle>
            </CModalHeader>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setShow2fa(false)}>
                {t("common.no")}
              </CButton>
              <CButton color="primary" onClick={() => cancel2fa()}>
                {t("common.yes")}
              </CButton>
            </CModalFooter>
          </CModal>
          <div className="balance settings">
            <br />
            <h4>
              {t("heading.user")} {user?.email}
            </h4>
            <div className="columns columns_md">
              <div className="shadow-block">
                {user && (
                  <>
                    <UserForm user={getUserInfo(user)} />
                  </>
                )}
                <br /><br />
                <PrefixIgnore />
              </div>
              <div className="shadow-block">
                <h6>{t("menu.settings")}</h6>
                {userContext.user && (
                  <>
                    <div>
                      <label>
                        <Toggle
                          checked={
                            userContext.user.twoFactorEnabled &&
                            userContext.user.twoFactorConfirmed
                          }
                          onChange={(e) => {
                            qr &&
                            userContext.user.twoFactorEnabled &&
                            !userContext.user.twoFactorConfirmed
                              ? toast.info(t("2fa.notify_about_qr"))
                              : toggleTFA(e.target.value);
                          }}
                          id="2fa"
                        />
                        <span>{t("2fa.enable")}</span>
                      </label>
                    </div>

                    {qr &&
                      userContext.user.twoFactorEnabled &&
                      !userContext.user.twoFactorConfirmed && (
                        <div>
                          <br />
                          <p>{t("2fa.notify_about_qr")}</p>
                          <CForm
                            onSubmit={(e) => confirm2FA(e)}
                            className="row g-3"
                          >
                            <CCol xs="auto">
                              <CFormInput
                                type="number"
                                id="inputPassword2"
                                placeholder={t("common.your_code")}
                                value={code}
                                required
                                maxLength="6"
                                onChange={(e) => setCode(e.target.value)}
                              />
                            </CCol>
                            <CCol xs="auto">
                              <CButton type="submit" className="mb-3">
                                {t("common.submit_form")}
                              </CButton>
                            </CCol>
                          </CForm>
                          <div className="d-flex align-items-center">
                            <img
                              src={qr}
                              width="200px"
                              style={{ marginRight: "20px" }}
                            />
                            <div className="settings__stores">
                              <p>{t("2fa.stores")}</p>
                              <div>
                                <CTooltip
                                  content={
                                    <div>
                                      {" "}
                                      <QRCode
                                        size={256}
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                          padding: "10px",
                                        }}
                                        value={
                                          "https://apps.apple.com/ru/app/google-authenticator/id388497605"
                                        }
                                        viewBox={`0 0 256 256`}
                                      />
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <CButton
                                    component="a"
                                    target="_blank"
                                    //   variant="outline"
                                    href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                                  >
                                    <CIcon
                                      color="primary"
                                      icon={cibAppStore}
                                      size="xl"
                                    />
                                  </CButton>
                                </CTooltip>
                                <CTooltip
                                  content={
                                    <div>
                                      {" "}
                                      <QRCode
                                        size={256}
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                          padding: "10px",
                                        }}
                                        value={
                                          "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
                                        }
                                        viewBox={`0 0 256 256`}
                                      />
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <CButton
                                    component="a"
                                    target="_blank"
                                    //   variant="outline"
                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
                                  >
                                    <CIcon icon={cibGooglePlay} size="xl" />
                                  </CButton>
                                </CTooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
                <TokenBlock />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
