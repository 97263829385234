import React, { useState, useEffect } from "react";
import "./list.scss";
import Axios from "axios";
import { API_URL } from "utils/settings";
import CIcon from "@coreui/icons-react";
import { CFormInput } from "@coreui/react";

import { cilDelete, cilHeart, cilSearch, cilX } from "@coreui/icons";
import { useTranslation } from "react-i18next";
import ServiceItem from "./ServiceItem";
import { useLocalStorage } from "utils/utils";
function sortByData(a, b, data) {}

function ServicesList(props) {
  const { getOrders, page, perPage, filters, updateUser } = props;
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState("");
  const [services, setServices] = useState(null);
  const [defaultServices, setDefaultServices] = useState(null);
  const [search, setSearch] = useState(null);
  const [favoriteServices, setFavoriteServices] = useLocalStorage(
    "favoriteServices",
    null
  );
  const { t } = useTranslation();
  const handleChange = (val) => {
    setSearch(val);
  };
  useEffect(() => {
    if (search?.length > 1) {
      setServices(
        defaultServices.filter((el) => {
          let data = search.toLowerCase()?.trim();
          if (
            el.service?.toLowerCase()?.includes(data) ||
            el.serviceName?.toLowerCase()?.includes(data) ||
            el.serviceAliases?.toLowerCase()?.includes(data)
          ) {
            return el;
          }
        })
      );
    } else {
      setServices(defaultServices);
    }
  }, [search]);
  const getServices = () => {
    setLoading(true);
    Axios.get(`${API_URL}/service/list`)
      .then(function (response) {
        setLoading(false);
        if (response.data.data) {
          const items = response.data.data;
          if (favoriteServices) {
            const data = JSON.parse(favoriteServices);
            const result = items?.sort(function (a, b) {
              const aIndex = data.indexOf(a.service);
              const bIndex = data.indexOf(b.service);
              if (aIndex !== -1 && bIndex !== -1) {
                return aIndex - bIndex;
              }
              if (aIndex !== -1) {
                return -1;
              }
              if (bIndex !== -1) {
                return 1;
              }
              return 0;
            });
            setServices(result);
            setDefaultServices(result);
          } else {
            setServices(items);
            setDefaultServices(items);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getServices();
  }, []);
  return (
    <div className="services-list">
      <>
        <div className="services__header-wrapper">
          <div className="services__header">
            <div className="services__header-item">{t("table.service")}</div>
            <div className="services__header-item">{t("table.price")}</div>
          </div>
          <div className="services__search">
            <CFormInput
              placeholder={t("search.services")}
              value={search}
              onChange={(e) => handleChange(e.target.value)}
            />
            {search?.length > 1 ? (
              <CIcon icon={cilX} onClick={() => setSearch("")} />
            ) : (
              <CIcon icon={cilSearch} />
            )}
          </div>
        </div>
        {services?.length > 0
          ? services?.map((el, i) => {
              return (
                <ServiceItem
                  el={el}
                  index={i}
                  favoriteServices={favoriteServices}
                  setFavoriteServices={setFavoriteServices}
                  getOrders={getOrders}
                  page={page}
                  perPage={perPage}
                  filters={filters}
                  updateUser={updateUser}
                  show={show}
                  setShow={setShow}
                  key={el.service}
                />
              );
            })
          : !loading && (
              <div className="services__not-found">{t("table.not_found")}</div>
            )}
      </>
    </div>
  );
}

export default ServicesList;
