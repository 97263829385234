import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CFormSelect
} from '@coreui/react';

function Select(props) {
    const { t } = useTranslation()
    const { id, placeholder, initialValues, val, handleChange } = props;
    return (
        <>
            <label htmlFor={id}>
                {t(placeholder)}
            </label>
            <CFormSelect
                aria-label={placeholder}
                onChange={(e) => {
                    initialValues[id] = +e.target.value
                    if (handleChange) {
                        handleChange(e)
                    }
                }}
                defaultValue={val.val}
                options={val.values.map((el) => {
                    return {
                        label: el?.label ? el?.label : el,
                        value: el?.value ? el?.value : el,
                    }
                })}
            />
        </>
    )
}

export default Select;