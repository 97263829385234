import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilCheck,
  cilListFilter,
  cilOptions,
  cilSettings,
} from "@coreui/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "utils/settings";
import { getDate } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";

function UsersTable(props) {
  let history = useHistory();

  const {
    getUsers,
    users,
    total,
    page,
    setTotal,
    perPage,
    filters,
    setFilters,
    title,
    sorting,
    activeSorting,
    setSorting,
    setPage,
    setPerPage,
    isLoading,
    partner,
  } = props;
  const [count, setCount] = useState(0);

  const [showFilters, setShowFilters] = useState(false);
  const windowSize = useWindowSize()[0];
  const isMobile = windowSize < 767;
  useEffect(() => {
    getUsers(page, perPage, filters, activeSorting);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage]);
  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: !isMobile ? t("common.email") : "",
      center: true,
      selector: (row) => row.email || "—",
      sortable: false,
    },
    {
      id: "balance",
      name: t("common.balance"),
      center: true,
      selector: (row) => {
        if (row.balanceAmount) {
          return new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: row.currency || "USD",
          }).format(row.balanceAmount || 0);
        } else {
          return 0;
        }
      },
      sortable: false,
    },
    {
      id: "createDate",
      name: t("table.date"),
      center: true,
      selector: (row) =>
        (new Date(row.createdAt).toLocaleString() && !isMobile
          ? new Date(row.createdAt).toLocaleString()
          : new Date(row.createdAt).toLocaleString().split(",")?.[0]) || "—",
      sortable: false,
      minWidth: "190px",
    },
    {
      id: "emailConfirmed",
      name: t("table.email_confirmed"),
      center: true,
      selector: (row) =>
        row.emailConfirmed ? (
          <CIcon icon={cilCheckAlt} />
        ) : (
          <CIcon icon={cilX} />
        ),
      sortable: false,
    },
    {
      id: "linkTo",
      name: "",
      center: true,
      selector: (row) => (
        <CIcon
          icon={cilSettings}
          onClick={() =>
            !partner
              ? history.push(`/admin/users/${row.id}`)
              : history.push(`/admin/users/${row.id}/products`)
          }
        />
      ),
      sortable: false,
      notMobile: true,
    },
  ];
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <CSpinner color="primary" />
          </div>
        )}
        {(filters?.length > 0 || sorting?.length > 0) && (
          <>
            <div className="filters-mobile">
              <h6>{t("heading.users")}</h6>

              <span onClick={() => setShowFilters(true)}>
                <CIcon icon={cilListFilter} />
                {t("filters")}
              </span>
            </div>{" "}
            {showFilters && (
              <div
                className="filters-plug"
                onClick={() => setShowFilters(false)}
              />
            )}
            <div className={`filters-wrapper ${showFilters ? "active" : ""}`}>
              {showFilters && (
                <div
                  className="filters-close"
                  onClick={() => setShowFilters(false)}
                >
                  <CIcon icon={cilX} />
                </div>
              )}
              {filters?.length > 0 && (
                <Filters
                  title={title || ""}
                  items={filters}
                  setFilters={(val) => {
                    setFilters(val);
                    setCount(count + 1);
                    if (page == 1 && count > 0) {
                      getUsers(page, perPage, val, activeSorting);
                    } else if (page !== 1 && count > 0) {
                      setPage(1);
                    }
                  }}
                />
              )}
              {sorting?.length > 0 && users?.length > 0 && (
                <>
                  <Sorting
                    items={sorting}
                    setItems={setSorting}
                    active={activeSorting}
                  />
                </>
              )}
            </div>
          </>
        )}
        {!isMobile ? (
          <DataTable
            columns={columns}
            data={users}
            sortIcon={<CIcon icon={cilArrowTop} />}
            noDataComponent={t("table.notFound")}
            onRowClicked={(row) =>
              !partner
                ? history.push(`/admin/users/${row.id}`)
                : history.push(`/admin/users/${row.id}/products`)
            }
            // progressPending={isLoading}
            // progressComponent={<CSpinner color="primary" />}
          />
        ) : (
          <div className="mobile-table">
            {users?.length > 0 ? (
              users.map((row) => {
                return (
                  <div className="mobile-table__item mobile-table__item_xs">
                    {columns.map((el) => {
                      return !el.notMobile ? (
                        <div className="mobile-table__row">
                          {el.name && (
                            <div className="mobile-table__key">{el.name}</div>
                          )}
                          {el.selector(row) && (
                            <div className="mobile-table__value">
                              {el.selector(row)}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className="mobile-info mobile-info_transparent">
                            <CIcon
                              icon={cilOptions}
                              onClick={() =>
                                !partner
                                  ? history.push(`/admin/users/${row.id}`)
                                  : history.push(
                                      `/admin/users/${row.id}/products`
                                    )
                              }
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <div className="mobile-table__empty">{t("table.notFound")}</div>
            )}
          </div>
        )}
        {users?.length > 0 && (
          <TablePagination
            page={page}
            setPage={(page) => {
              setPage(page);
            }}
            total={total}
            perPage={perPage}
            setPerPage={(val) => {
              if (page == 1) {
                getUsers(page, val, filters, activeSorting);
              }
              setPerPage(val);
            }}
          />
        )}
      </div>
    </>
  );
}

export default UsersTable;
