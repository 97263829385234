import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";
import { API_URL } from "utils/settings";
import Axios from "axios";
import Toggle from "react-toggle";
import { useLocalStorage } from "utils/utils";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle } from "@coreui/icons";
import ToastComponent from "components/ToastComponent";

function AdminSettingsPage() {
  const { t } = useTranslation();
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [defaultPaymentData, setDefaultPaymentData] = useState([]);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  useEffect(() => {
    getSettings();
    getPaymentsData();
  }, []);
  const getSettings = () => {
    setLoading(true);
    Axios.get(`${API_URL}/admin/setting`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setSettings(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getPaymentsData = () => {
    Axios.get(`${API_URL}/admin/payment/provider/all`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setPaymentData(response.data.data);
          setDefaultPaymentData(response.data.data);
        }
      })
      .catch((error) => {});
  };
  const changeSettings = (type, value) => {
    setLoading(true);
    let intValue;
    if (value === true) {
      intValue = "1";
    } else {
      intValue = "0";
    }
    Axios.put(`${API_URL}/admin/setting`, {
      type: type,
      value: intValue,
    })
      .then((res) => {
        getSettings();
        // setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const updateCommission = (comId, comVal) => {
    Axios.put(`${API_URL}/admin/payment/provider/${comId}/update`, {
      commission: comVal.toString(),
    })
      .then((res) => {
        ToastComponent("success", t("commission.success"));
        getPaymentsData();
      })
      .catch((err) => {});
  };
  return (
    <div className={`page ${expanded ? "expanded" : ""}`}>
      <Sidebar />
      <div className="container container-panel">
        <div className="panel__content">
          <div className="balance admin-settings">
            <h3>{t("menu.admin_settings")}</h3>
            {settings.map((el) => {
              return (
                <label disabled={loading}>
                  <Toggle
                    checked={!!Number.parseInt(el.value)}
                    onChange={(e) => {
                      changeSettings(el.type, !Number.parseInt(el.value));
                    }}
                    id={el.type}
                  />
                  <span>{t(`settings.${el.type}`)}</span>
                </label>
              );
            })}
            <br />
            <br />
            <h4>{t("commission")}</h4>
            <div className="settings__commission">
              {paymentData.map((el) => {
                return (
                  <div className="settings__commission-item">
                    <CFormLabel>{t(`commission.${el.provider}`)}</CFormLabel>
                    <CInputGroup>
                      <CFormInput
                        placeholder={t("table.amount")}
                        type="number"
                        min={-100}
                        max={100}
                        value={el.commission}
                        onChange={(e) => {
                          if (e.target.value.split(".")?.[1]?.length > 2) {
                            return;
                          } else {
                            let newArr = paymentData.map((item) => {
                              if (item.provider == el.provider) {
                                let val = e.target.value;
                                if (val >= 99.99) {
                                  val = 99.99;
                                } else if (val <= -100) {
                                  val = -99.99;
                                }
                                return {
                                  id: item.id,
                                  commission: val,
                                  provider: item.provider,
                                };
                              } else {
                                return item;
                              }
                            });
                            setPaymentData(newArr);

                            // setValue(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            const neededItem = defaultPaymentData.filter(
                              (dpd) => dpd.provider == el.provider
                            );
                            let newArr = paymentData.map((item) => {
                              if (item.provider == el.provider) {
                                return {
                                  id: item.id,
                                  commission: neededItem[0].commission,
                                  provider: item.provider,
                                };
                              } else {
                                return item;
                              }
                            });
                            setPaymentData(newArr);
                          }
                        }}
                      />
                      <CInputGroupText>
                        {defaultPaymentData.filter((c) => c.id == el.id)?.[0]
                          ?.commission !== el.commission ? (
                          <button
                            className="settings__commission-button btn btn-primary"
                            onClick={() =>
                              updateCommission(el.id, el.commission)
                            }
                          >
                            <CIcon icon={cilCheckCircle} />
                          </button>
                        ) : (
                          "%"
                        )}
                      </CInputGroupText>
                    </CInputGroup>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSettingsPage;
