import React from 'react';
import ToastComponent from "components/ToastComponent";

function FileUploader(props) {
    return <><label htmlFor={props.id} className="file-upload">
        <input
            id={props.id}
            size="1100000"
            onChange={(e) => {
                if (e.target.files[0].size > 1100000) {
                    ToastComponent('error', 'Размер файла слишком большой')
                } else {
                    props.handler(e.target.files[0])
                
                }

            }}
            accept="image/png, image/jpeg"
            type="file" />
        <span>{!props.file?.name ? "Выберите файл" : props.file.name}</span>
    </label>
    </>
}

export default FileUploader