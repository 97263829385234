import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";
import { API_URL } from "utils/settings";
import Axios from "axios";
import Toggle from "react-toggle";
import CouponsForm from "./form";
import { UserContext } from "core/contexts";
import CouponsTable from "./table";
import { defaultBackMonthDate, defaultEndDate, defaultStartDate, useLocalStorage } from "utils/utils";

function AdminPromoPage() {
  const { t } = useTranslation();
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const userContext = useContext(UserContext);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const user = userContext.user;
  const [userInfo, setUserInfo] = useState(null);
  const [page, setPage] = useState(1);
  const [coupons, setCoupons] = useState([]);
  const [activeSorting, setActiveSorting] = useState(null);

  const [actionCounter, setActionCounter] = useState(0);
  const getUsers = (val) => {
    const params = {
      email: val,
    };
    if (!val || val?.length < 3) {
      return;
    }
    return Axios.get(`${API_URL}/admin/user/all`, {
      params: params,
    })
      .then(function (response) {
        setLoading(false);
        if (response.data?.data?.length > 0) {
          // setUsers(response.data.data);
          return response.data.data.map((el) => {
            return {
              label: el.email,
              value: el.id,
            };
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  const [filters, setFilters] = useState([
    {
      name: "statuses",
      items: ["all", "new", "activated", "deleted"],
      type: "multiselect",
      value: null,
    },
    {
      name: "userId",
      value: null,
      type: "autocomplete",
      searchFunc: (val) => getUsers(val),
    },
    {
      name: "dateFrom",
      type: "date",
      value: defaultBackMonthDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);
  const [sorting, setSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
  ]);
  const getCoupons = (pageVal, perPageVal, filtersVal, activeSortingVal) => {
    setIsLoading(true);

    let params = {
      perPage: perPageVal,
      page: pageVal,
    };
    console.log('active', activeSortingVal)
    if (activeSortingVal !== "sorting.null" && activeSortingVal !== null) {
      params["sort"] = [activeSortingVal];
    }
    filtersVal?.forEach((el) => {
      if ((el.value && el.value !== "all") || el.type == "date") {
        if (el.type == "multiselect") {
          params = { ...params, [el.name]: [el.value] };
          return;
        }
        if (el.type == "date") {
          if (!el.value) {
            return;
          }
          let date = new Date(el.value);
          // date.setDate(date.getDate() + 1);
          params = {
            ...params,
            [el.name]: date?.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    Axios.get(`${API_URL}/admin/coupon/all`, {
      params: params,
    })
      .then(function (response) {
        setIsLoading(false);

        if (response.data.data?.length > 0) {
          setCoupons(response.data.data);
          setTotal(response.data.total);
        } else {
          setCoupons([]);
          setTotal(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  return (
    <div className={`page ${expanded ? "expanded" : ""}`}>
      <Sidebar />
      <div className="container container-panel">
        <div className="panel__content">
          <div className="balance admin-settings">
            <h3>{t("menu.coupons")}</h3>
            <CouponsForm
              callback={() => {
                page == 1 ? getCoupons(1, perPage, filters, activeSorting) : setPage(1);
              }}
            />
            <br />
            <CouponsTable
              coupons={coupons}
              setCoupons={setCoupons}
              getCoupons={getCoupons}
              total={total}
              setTotal={setTotal}
              currency={user?.currency}
              page={page}
              sorting={sorting}
              activeSorting={activeSorting}
              setSorting={setActiveSorting}
              perPage={perPage}
              title={`${t("menu.coupons")} — ${total}`}
              setPage={setPage}
              setPerPage={setPerPage}
              isLoading={isLoading}
              setLoading={setIsLoading}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPromoPage;
