import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";

// import OrderTable from './OrderTable';
import PaymentTable from "./PaymentTable";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import { CButton } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "core/contexts";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { defaultBackMonthDate, defaultEndDate, defaultStartDate, useLocalStorage } from "utils/utils";

function BalanceHistoryPage(props) {
  const user = useContext(UserContext).user;
  const { t } = useTranslation();
  let url = new URL(window.location);
  let params = new URLSearchParams(url.search);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("success")) {
      ToastComponent("success", t("balance.payment_success"));
      queryParams.delete("success");
      history.replace({
        search: queryParams.toString(),
      });
    } else if (queryParams.has("failed")) {
      ToastComponent("error", t("balance.payment_failed"));
      queryParams.delete("failed");
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);
  const [filters, setFilters] = useState([
    {
      name: "type",
      items: ["all", "refill", "balance_correction"],
      type: "select",
      value: null,
    },
    {
      name: "statuses",
      items: ["all", "waiting", "approved", "declined", "canceled"],
      type: "multiselect",
      value: null,
    },
    {
      name: "dateFrom",
      type: "date",
      value:  defaultBackMonthDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);

  const getPayments = async (reqPage, reqPerPage, reqFilters) => {
    setIsLoading(true);
    let params = {
      page: reqPage,
      perPage: reqPerPage,
    };
    reqFilters?.length > 0 &&
      reqFilters?.forEach((filter) => {
        if (
          (filter.value && filter.value !== "all") ||
          filter.type === "date"
        ) {
          if (filter.type === "multiselect") {
            params = { ...params, [filter.name]: [filter.value] };
            return;
          }
          if (filter.type === "date" && filter.value) {
            let date = new Date(filter.value);
            params = {
              ...params,
              [filter.name]: date.toISOString(),
            };
            return;
          }
          params = { ...params, [filter.name]: filter.value };
        }
      });

    try {
      const response = await Axios.get(`${API_URL}/payment`, {
        params,
      });
      setIsLoading(false);
      if (response.data.data?.length > 0) {
        setPayments(response.data.data);
        setTotal(response.data.total);
      } else {
        setPayments([]);
        setTotal(0);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className={`page ${expanded ? "expanded" : ""}`}>
        <Sidebar />
        <div className="container container-panel">
          <div className="panel__content">
            <div className="balance">
              <div className="shadow-block">
                {user && (
                  <PaymentTable
                    currency={user.currency}
                    page={page}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    total={total}
                    setTotal={setTotal}
                    filters={filters}
                    setFilters={setFilters}
                    getPayments={getPayments}
                    payments={payments}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BalanceHistoryPage;
