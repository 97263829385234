import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

function RulesBlock(props) {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="rules-block"
        dangerouslySetInnerHTML={{ __html: t("rules.content") }}
      />
    </>
  );
}

export default RulesBlock;
