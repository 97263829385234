// import logo from './logo.svg';
import React, { useEffect } from "react";
import "./App.scss";
import AppRouter from "./core/router";
import Axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocalStorage } from "utils/utils";
import classNames from "classnames";
import Favicon from "react-favicon";
import Fav from "./assets/images/favicon.png";
import i18n from "utils/i18n";
import ToastComponent from "components/ToastComponent";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import CIcon from "@coreui/icons-react";
import {
  cilChatBubble,
  cilSettings,
  cibTelegram,
  cilSend,
  cilContact,
  cibTelegramPlane,
} from "@coreui/icons";
import { CButton, CCallout, CCloseButton } from "@coreui/react";
import TagManager from "react-gtm-module";

function App() {
  const [theme] = useLocalStorage("theme", "light");
  const [locale, setLocale] = useLocalStorage("locale", "gb");
  const [cookieNotice, setCookieNotice] = useLocalStorage(
    "cookieNotice",
    false
  );
  const { t } = useTranslation();
  const [panelType, setPanelType] = useLocalStorage("panelType", "user");

  useEffect(() => {
    i18n.changeLanguage(locale);
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", '"gb"');
    }
  }, [locale]);

  useEffect(() => {
    theme == "dark"
      ? document.documentElement.style.setProperty("color-scheme", "dark")
      : document.documentElement.style.setProperty("color-scheme", "light");
  }, [theme]);
  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.code === 401) {
        if (
          window.location.href.includes("panel") ||
          window.location.href.includes("admin")
        ) {
          window.location.href = "/auth";
          return;
        }
        if (
          error.response.data.message &&
          !error.response.data.message.includes("JWT")
        ) {
          ToastComponent("error", t(error.response.data.message));
        }
      }
      if (error.response.data.errors) {
        const errors = error?.response?.data?.errors;
        if (errors) {
          errors.forEach((el) => {
            if (el.detail.includes('"') || el.detail.includes(":")) {
              ToastComponent("error", new String(el.detail));
            } else {
              ToastComponent("error", t(el.detail));
            }
          });
        }
      } else {
        if (window.location.href.includes("panel")) {
          ToastComponent("error", t("common.notifications.fetch_data_error"));
        }
      }
    }
  );
  if (window.location.host == "sms-online.pro") {
    TagManager.initialize({
      gtmId: "G-00TCHN52NC",
    });
  }
  return (
    <div
      className={classNames({
        app: true,
        [`${theme}`]: true,
        [`${panelType}`]: true,
      })}
    >
      <Helmet>
        <title>{t("helmet.title")}</title>
        <meta name="description" content={t("helmet.description")} />
      </Helmet>
      <Favicon url={Fav} />
      <AppRouter locale={locale} />
      <ToastContainer />
      <Fab
        mainButtonStyles={{
          background: "#8C589E",
        }}
        icon={<CIcon icon={cilChatBubble} />}
        alwaysShowTitle={true}
      >
        <Action
          text={t("fab.telegram")}
          onClick={() => {
            window.open("https://t.me/smsonlinepro");
          }}
        >
          <CIcon icon={cibTelegramPlane} />
        </Action>
        <Action
          text={t("fab.mail")}
          onClick={() => {
            window.location.href = "mailto: support@sms-online.pro";
          }}
        >
          <CIcon icon={cilContact} />
        </Action>
        <Action
          text={t("fab.support")}
          onClick={() => {
            window.open("https://t.me/sms_online_pro_Support_Bot");
          }}
        >
          <CIcon icon={cilSettings} />
        </Action>
      </Fab>
      {!cookieNotice && (
        <CCallout color="primary" className="cookie">
          {/* <CCloseButton /> */}

          <h6>{t("cookie.heading")}</h6>
          {t("cookie.subheading")}
          <div className="cookie__footer">
            <CButton color="light" onClick={() => setCookieNotice(true)}>
              {t("cookie.submit")}
            </CButton>
          </div>
        </CCallout>
      )}
    </div>
  );
}

export default App;
