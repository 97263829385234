import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import CIcon from "@coreui/icons-react";
import { cilMoon, cilSun } from "@coreui/icons";
import { useLocalStorage } from "utils/utils";
function ThemeSwitcher(props) {
  const { t } = useTranslation();
  const [theme, setTheme] = useLocalStorage("theme", "light");
  return (
    <div class="btn-container">
      <label class="switch btn-color-mode-switch">
        <input
          type="checkbox"
          name="color_mode"
          id="color_mode"
          checked={theme == "dark" ? true : false}
          defaultChecked={theme == "dark" ? true : false}
          onChange={() => setTheme(theme == "light" ? "dark" : "light")}
        />
        <label for="color_mode" class="btn-color-mode-switch-inner">
          <CIcon icon={cilSun} className="sun" />
          <CIcon icon={cilMoon} className="moon" color="primary" />
        </label>
      </label>
    </div>
  );
}

export default ThemeSwitcher;
