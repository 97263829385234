import React, { useContext, useEffect, useState } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavItem,
  CSidebarToggler,
  CFormSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormSwitch,
  CNavGroup,
} from "@coreui/react";
import "./index.scss";
import "./index.dark.scss";
import { API_URL } from "utils/settings";
import Axios from "axios";
import { withTranslation } from "react-i18next";
import { icons } from "utils/icons";
import Logo from "assets/images/logo-sms-white.png";
import LogoCut from "assets/images/logo-cut.png";
import ExpandIcon from "assets/images/expand.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { renderCurrency, useLocalStorage } from "utils/utils";

import {
  cilPlus,
  cilDescription,
  cilSettings,
  cilBarChart,
  cilAccountLogout,
  cilCopy,
  cilExpandLeft,
  cilSnowflake,
  cilChart,
  cilListRich,
  cilSpreadsheet,
  cilPeople,
  cilImageBroken,
  cilHandPointDown,
  cilAddressBook,
  cilContact,
  cilCreditCard,
} from "@coreui/icons";
import ToastComponent from "components/ToastComponent";
import { UserContext } from "core/contexts";
import ThemeSwitcher from "pages/ThemeSwitcher";

function Sidebar({ t }) {
  const location = useLocation();
  const [isVisible, setVisible] = useState(false);
  const [localExpanded, setLocalExpanded] = useLocalStorage("expanded", "true");
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [expanded, setExpanded] = useState(localExpanded || false);
  const [locale, setLocale] = useLocalStorage("locale", "en");
  const [panelType, setPanelType] = useLocalStorage("panelType", "user");
  const user = useContext(UserContext).user;
  const history = useHistory();

  const userMenu = [
    {
      icon: cilDescription,
      label: t("menu.sms"),
      link: "/panel/main",
    },
    {
      icon: cilPlus,
      label: t("menu.pay"),
      link: "/panel/pay",
    },
    {
      icon: cilBarChart,
      label: t("menu.history"),
      link: "/panel/balance",
    },
    {
      icon: cilSettings,
      label: t("menu.settings"),
      link: "/panel/settings/",
    },
    {
      icon: cilListRich,
      label: t("nav.rules"),
      link: "/panel/rules/",
    },
    {
      icon: cilAccountLogout,
      label: t("common.logout"),
      type: "logout",
    },
  ];
  const adminMenu = [
    {
      icon: cilPeople,
      label: t("heading.users"),
      link: "/admin/stats/users",
    },
    {
      icon: cilContact,
      label: t("heading.partners"),
      link: "/admin/partners",
    },
    {
      icon: cilSpreadsheet,
      label: t("menu.stats"),
      dropdown: [
        {
          icon: cilListRich,
          label: t("heading.orders"),
          link: "/admin/stats/orders",
        },
        {
          icon: cilCreditCard,
          label: t("heading.payments"),
          link: "/admin/stats/payments",
        },
      ],
    },
    {
      icon: cilImageBroken,
      label: t("menu.coupons"),
      link: "/admin/coupons",
    },
    {
      icon: cilSettings,
      label: t("menu.admin_settings"),
      link: "/admin/settings/",
    },
    {
      icon: cilChart,
      label: t("menu.logs"),
      link: "/admin/logs",
    },
    {
      icon: cilListRich,
      label: t("nav.rules"),
      link: "/admin/rules/",
    },
    {
      icon: cilAccountLogout,
      label: t("common.logout"),
      type: "logout",
    },
  ];
  const inspectorMenu = [
    {
      icon: cilChart,
      label: t("menu.logs"),
      link: "/inspector/logs",
    },
    {
      icon: cilAccountLogout,
      label: t("common.logout"),
      type: "logout",
    },
  ];
  useEffect(() => {
    const page = document.querySelector(".page");

    if (expanded) {
      page.classList.add("expanded");
      setLocalExpanded(true);
    } else {
      page.classList.remove("expanded");
      setLocalExpanded(false);
    }
  }, [expanded]);
  const expand = (val) => {
    setExpanded(val);
  };
  useEffect(() => {
    setShowMobileSidebar(false);
  }, [location]);
  const locales = {
    ru: "RU",
    gb: "EN",
    es: "ES",
    cn: "CN",
  };
  const menu = () => {
    if (user?.isInspector) {
      return inspectorMenu;
    }
    return panelType == "user" ? userMenu : adminMenu;
  };
  useEffect(() => {
    panelType == "admin" &&
      location?.pathname?.includes("panel") &&
      history.push("/admin/stats/users");
  }, [panelType]);
  useEffect(() => {
    if (user?.hasOwnProperty("isAdmin") && user.isAdmin == false) {
      setPanelType("user");
    }
  }, [user?.isAdmin]);
  useEffect(() => {
    if (user?.isInspector) {
      history.push("/inspector/logs");
    }
  }, [user?.isInspector]);
  return (
    <>
      {showMobileSidebar && (
        <div
          className="sidebar-plug"
          onClick={() => setShowMobileSidebar(false)}
        />
      )}
      <CSidebar
        height="100vh"
        className={`${panelType} ${showMobileSidebar ? "visible" : ""}`}
      >
        <CSidebarBrand>
          {!expanded ? (
            <img src={Logo} alt="" />
          ) : (
            <img src={LogoCut} width="50px" alt="" />
          )}
        </CSidebarBrand>
        <CSidebarNav>
          {menu().map((el) => {
            if (el.dropdown?.length > 0) {
              return (
                <CNavGroup
                  className={
                    el.dropdown.filter((nav) => nav.link == location.pathname)
                      ?.length > 0
                      ? "show"
                      : ""
                  }
                  toggler={
                    <>
                      {" "}
                      <CIcon customClassName="nav-icon" icon={el.icon} />
                      {t(el.label)}
                    </>
                  }
                >
                  {el.dropdown.map((item) => {
                    return (
                      <CNavItem
                        key={item.label}
                        to={item.link}
                        className={`nav-item ${
                          location.pathname.includes(item.link) ||
                          location.pathname == item.link
                            ? "link-active"
                            : ""
                        }`}
                        component={Link}
                      >
                        <CIcon customClassName="nav-icon" icon={item.icon} />
                        {item.label}
                      </CNavItem>
                    );
                  })}
                </CNavGroup>
              );
            }
            if (el.type == "logout") {
              return (
                <li
                  className="nav-item nav-item"
                  key="logoutButton"
                  onClick={() => {
                    localStorage.removeItem("token");
                    window.location.href = "/";
                  }}
                >
                  {/* <CNavItem key={el.label} to={el.link} className={`nav-item nav-link `}

                        > */}
                  <div className="nav-link nav-item">
                    <CIcon customClassName="nav-icon" icon={el.icon} />
                    {el.label}
                  </div>
                  {/* </CNavItem> */}
                </li>
              );
            }
            return (
              <CNavItem
                key={el.label}
                to={el.link}
                className={`nav-item ${
                  location.pathname.includes(el.link) ||
                  location.pathname == el.link
                    ? "link-active"
                    : ""
                }`}
                component={Link}
              >
                <CIcon customClassName="nav-icon" icon={el.icon} />
                {el.label}
              </CNavItem>
            );
          })}
        </CSidebarNav>
        <div className="sidebar-footer">
          <div className="sidebar-footer__block">
            <ThemeSwitcher />
          </div>
          {user?.isAdmin && (
            <div className="sidebar-panel-type">
              <p>{t("panel.type")}</p>
              <CFormSwitch
                size="lg"
                onChange={() => {
                  if (panelType == "user") {
                    setPanelType("admin");
                    history.push("/admin/stats/users");
                  } else {
                    setPanelType("user");
                    history.push("/panel/main");
                  }
                }}
                value={panelType == "admin" ? true : false}
                name={"panelType"}
                id={"panelType"}
                defaultChecked={panelType == "admin" ? true : false}
              />
            </div>
          )}

          <div className="sidebar-select sidebar-footer__block">
            <CDropdown visible={isVisible}>
              <CDropdownToggle
                trigger="click"
                // variant="secondary"
                onClick={() => setVisible(!isVisible)}
              >
                <div class={`flag flag-${locale}`} />{" "}
                <span>{t(`language.${locale}`)}</span>
              </CDropdownToggle>
              <CDropdownMenu>
                {Object.entries(locales).map((el) => {
                  return (
                    <CDropdownItem
                      // href="#"
                      onClick={() => {
                        setLocale(el[0]);
                        setVisible(false);
                      }}
                    >
                      <div class={`flag flag-${el[0]}`} /> {el[1]}
                    </CDropdownItem>
                  );
                })}
              </CDropdownMenu>
            </CDropdown>
          </div>
        </div>
      </CSidebar>
      <div className="sidebar-expand" onClick={() => expand(!expanded)}>
        <img src={ExpandIcon} alt="" />
      </div>
      <div className={`bottom-bar ${panelType}`}>
        <div className="bottom-bar__content">
          <div className="bottom-bar__start">
            {user && (
              <div className="bottom-bar__item">
                <span>{t("common.balance")}:</span> {user.balance}
                {renderCurrency(user?.currency)}
                <p className="frozen">
                  &nbsp;<span>(</span>
                  <CIcon icon={cilSnowflake} />
                  {user.frozenBalance}
                  {renderCurrency(user?.currency)}
                  <span>)</span>
                </p>
              </div>
            )}
          </div>
          <div className="bottom-bar__end">
            <div
              class={`hamburger-lines ${showMobileSidebar ? "active" : ""}`}
              onClick={() => setShowMobileSidebar(!showMobileSidebar)}
            >
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
            <Link
              to={`${panelType == "user" ? "/panel/rules" : "/admin/rules"}`}
            >
              {t("nav.rules")}
            </Link>
          </div>
          {/* <div className="bottom-bar__item">
              <CIcon icon={cilExpandLeft} onClick={() => expand(!expanded)} />
            </div> */}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(Sidebar);
