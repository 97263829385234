import React from "react";
import "./index.scss";
import { CButton, CButtonGroup, CFormSelect } from "@coreui/react";
import { useTranslation } from "react-i18next";
import CIcon from "@coreui/icons-react";
import {
  cilArrowBottom,
  cilListFilter,
  cilSortDescending,
} from "@coreui/icons";

function Sorting(props) {
  const { t } = useTranslation();
  const { items, setItems, active } = props;
  let options = [];
  items.forEach((el) => {
    options.push({
      label: t(`sorting.${el.name}`),
      value: `${el.name}`,
    });
  });

  const handleChange = (val) => {
    if (active?.includes(val)) {
      if (active.includes("-")) {
        setItems(null);
      } else {
        setItems(`-${val}`);
      }
    } else {
      setItems(`${val}`);
    }
  };
  return (
    <div className="sorting">
      {
        <>
          <b>{t("sorting.for")}</b>
          {options?.length > 0 && (
            <CButtonGroup >
              {options.map((el) => {
                return (
                  <CButton
                    variant="outline"
                    className="sorting__item"
                    onClick={() => handleChange(el.value)}
                  >
                    {t(el.label)}{" "}
                    {active?.includes(el.value) && (
                      <CIcon
                        icon={cilListFilter}
                        className={active?.includes("-") ? "revert" : ""}
                      />
                    )}
                  </CButton>
                );
              })}{" "}
            </CButtonGroup>
          )}
        </>
      }
    </div>
  );
}

export default Sorting;
