import React, { useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { API_URL } from "utils/settings";
import {
  CAlert,
  CButton,
  CFormInput,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from "@coreui/react";
import { toast } from "react-toastify";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ToastComponent from "components/ToastComponent";
function TokenBlock(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState("asd");
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const location = useLocation();

  const [notify, showNotify] = useState(false);
  let url = new URL(window.location);
  const history = useHistory();
  let token = new URLSearchParams(window.location?.search).get("token");

  useEffect(() => {
    if (token) {
      getApiKey();
    }
  }, [token]);
  const generateKey = () => {
    setLoading(true);
    Axios.post(`${API_URL}/user/apikey`)
      .then(function (response) {
        if (response) {
          showNotify(true);
          setLoading(false);
          toast.info(t("heading.api_notify"));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getApiKey = () => {
    const params = {
      token: token,
    };
    Axios.get(`${API_URL}/user/apikey`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.apiKey) {
          setApiKey(response.data.apiKey);
          setShowModal(true);
        }
      })
      .catch((error) => {
        setShowErrorModal(true);
      });
  };
  return (
    <>
      <div className="token">
        <h6>{t("heading.api")}</h6>
        <p>{t("heading.api_text")}</p>
        {notify && (
          <div className="mb-4 mt-4">
            <CAlert color="primary" style={{ display: "inline" }}>
              {t("heading.api_notify")}
            </CAlert>
          </div>
        )}
        <button
          className="btn btn-primary btn-lg"
          onClick={() => generateKey()}
          disabled={loading}
        >
          {loading && <CSpinner size="lg" color="white" />}
          {t("common.generate_api")}
        </button>
      </div>
      <CModal
        visible={showModal}
        onClose={() => {
          history.replace({
            search: "",
          });
          setShowModal(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>{t("api.new")}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>{t("api.modal_text")}</p>
          <p className="text-success">{t("api.modal_security")}</p>
          <p>{t("api.modal_save")}</p>
          <div>
            <CInputGroup>
              <CFormInput type="text" id="apiKey" value={apiKey} disabled />
              <CButton
                color="primary"
                className="btn-block"
                onClick={() => {
                  navigator.clipboard.writeText(apiKey);
                  ToastComponent("success", t("menu.apikey_copy"));
                }}
              >
                {t("api.copy")}
              </CButton>
            </CInputGroup>
          </div>
        </CModalBody>
      </CModal>
      <CModal
        visible={showErrorModal}
        onClose={() => {
          history.replace({
            search: "",
          });
          setShowErrorModal(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>{t("api.error_modal")}</CModalTitle>
        </CModalHeader>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              history.replace({
                search: "",
              });
              setShowErrorModal(false);
            }}
          >
            {t("modal.cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default TokenBlock;
