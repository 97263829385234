import {
  cilPeople,
  cilDevices,
  cilSim,
  cilCreditCard,
  cilSettings,
  cilTransfer,
  cilVoiceOverRecord,
  cilUser,
  cilChatBubble,
  cilListRich,
  cibServerFault,
  cilVector,
} from "@coreui/icons";

export const icons = {
  "menu.partner": cilUser,
  "menu.partners": cilPeople,
  "menu.devices": cilDevices,
  "menu.simcards": cilSim,
  "menu.payments": cilCreditCard,
  "menu.services": cilSettings,
  "menu.transactions": cilTransfer,
  "menu.operators": cilVoiceOverRecord,
  "menu.profiles": cilUser,
  "menu.simfilters": cilChatBubble,
  "menu.orders": cilListRich,
  "menu.simbanks": cibServerFault,
  "menu.iccid_bindings": cilVector,
};
