import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle } from "@coreui/icons";
import Axios from "axios";
import { API_URL } from "utils/settings";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CSpinner,
} from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { renderCurrency, useLocalStorage } from "utils/utils";
import { UserContext } from "core/contexts";
import classNames from "classnames";
import MasterCard from "assets/images/mastercard.svg";
import Mir from "assets/images/miraccept.svg";
import Visa from "assets/images/visa.svg";
function AddBalancePage() {
  const [active, setActive] = useState("");
  const [minCount, setMinCount] = useState(1);
  const [maxCount, setMaxCount] = useState(10000);
  const [promo, setPromo] = useState(null);
  const [lava, setLava] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const user = useContext(UserContext).user;
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState(null);
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [moneyRate, setMoneyRate] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const getMoneyRates = () => {
    Axios.get(`${API_URL}/money_rate/rates`)
      .then(function (response) {
        if (response.data.rates?.length > 0) {
          setMoneyRate(response.data.rates);
        }
      })
      .catch((error) => {});
  };
  const getInfo = () => {
    Axios.get(`${API_URL}/payment/zerox/coin-info`)
      .then(function (response) {
        if (response.data.data) {
          const data = response.data.data;
          setItems(
            Object.values(data).map((el) => {
              return {
                currency: el.currency,
                minCount: el.min,
                maxCount: el.max,
              };
            })
          );
        }
      })
      .catch((error) => {});
  };
  const getPaymentsData = () => {
    Axios.get(`${API_URL}/payment/provider/all`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          let obj = {};
          response.data.data.forEach((el) => {
            obj[el.provider] = el.commission;
          });
          setPaymentData(obj);
        }
      })
      .catch((error) => {});
  };
  const submitPromo = (e) => {
    e.preventDefault();
    Axios.put(`${API_URL}/coupon/activate`, {
      coupon: promo,
    })
      .then(function (response) {
        if (response) {
          ToastComponent("success", t("toast.coupon"));
          userContext.updateUser();
        }
      })
      .catch((error) => {});
  };
  const submitLava = (e) => {
    e.preventDefault();
    if (lava == 0) {
      ToastComponent("error", t("balance.empty"));
      return;
    }
    if (+lava < 1) {
      ToastComponent("error", t("balance.min"));
      return;
    }
    setLoading(true);
    Axios.post(`${API_URL}/payment/lava/refill`, {
      amount: lava.toString(),
    })
      .then(function (response) {
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
          setTimeout(() => setLoading(false), 5000);
        }
      })
      .catch((error) => {});
  };
  const handlePromo = (e) => {
    setPromo(e.target.value);
  };
  const handleLava = (e) => {
    if (e.target.value.split(".")?.[1]?.length > 2) {
      return;
    }
    if (e.target.value > 999.99) {
      return setLava("999.99");
    }
    if (e.target.value < -999.99) {
      return setLava("-999.99");
    }
    setLava(e.target.value);
  };
  useEffect(() => {
    getInfo();
    getMoneyRates();
    getPaymentsData();
  }, []);
  const submit = () => {
    if (!value) {
      ToastComponent("error", t("balance.empty"));
      return;
    }
    if (+value <= 1) {
      ToastComponent("error", t("balance.min"));
      return;
    }
    if (+value > 10000) {
      ToastComponent("error", t("balance.max"));
      return;
    }
    setLoading(true);
    Axios.post(`${API_URL}/payment/zerox/refill`, {
      currency: active,
      amountUsd: value.toString(),
    })
      .then(function (response) {
        if (response.data.paymentUrl) {
          window.location.href = response.data.paymentUrl;
          setTimeout(() => setLoading(false), 5000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (item, i) => {
    setActive(item.currency);
    setMinCount(item.minCount);
    setMaxCount(item.maxCount);
    setIndex(i);
  };
  return (
    <div className={`page ${expanded ? "expanded" : ""}`}>
      <Sidebar />

      <div className="container container-panel">
        <div className="panel__content">
          <div className="shadow-block add-pay">
            <h2 className="text-left">{t("heading.add_pay")}</h2>
            <div className="add-tabs">
              <button
                onClick={() => setActiveTab(0)}
                className={`add-tabs__button ${activeTab == 0 ? "active" : ""}`}
              >
                <b>{t("tabs.crypto")}</b>
                {paymentData?.["0xprocessing"] && (
                  <p>
                    {t("commission")}: {paymentData["0xprocessing"]}%
                  </p>
                )}
              </button>
              <button
                onClick={() => setActiveTab(1)}
                className={`add-tabs__button ${activeTab == 1 ? "active" : ""}`}
              >
                <b>{t("tabs.coupon")}</b>
                {paymentData?.["coupon"] && (
                  <p>
                    {t("commission")}: {paymentData["coupon"]}%
                  </p>
                )}
              </button>
              <button
                onClick={() => setActiveTab(2)}
                className={`add-tabs__button ${activeTab == 2 ? "active" : ""}`}
              >
                <b>Lava</b>
                {paymentData?.["lava"] && (
                  <p>
                    {t("commission")}: {paymentData["lava"]}%
                  </p>
                )}
              </button>
            </div>
            <form
              onSubmit={(e) => submitLava(e)}
              className={classNames({
                active: activeTab == 2,
                promocode: true,
                "add-tab": true,
              })}
            >
              <div className="promocode__inner">
                <div>
                  <CFormLabel>Lava</CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      type={"number"}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      id="promocode"
                      placeholder={t(`table.amount`)}
                      value={lava}
                      defaultValue={lava}
                      step="any"
                      required
                      onChange={(e) => handleLava(e)}
                    />
                    <CInputGroupText>$</CInputGroupText>
                  </CInputGroup>
                </div>
                <CButton type="submit">{t("common.submit")}</CButton>
              </div>
              {moneyRate?.length > 0 && (
                <div className="promocode__rates">
                  {t("money_rate")}:
                  {moneyRate.map((el) => {
                    if (el.currency !== "RUB") return;
                    return (
                      <div className="promocode__rate">
                        1 USD = {(Math.floor(el.rate * 100) / 100).toFixed(2)}{" "}
                        {el.currency}
                      </div>
                    );
                  })}
                </div>
              )}
              <div  className="lava__footer" style={{ marginTop: "20px", marginBottom: "-20px" }}>
                <div style={{ maxWidth: "320px", fontSize: "14px", fontStyle: 'italic' }}>
                  GLOGAMING LLC. Reg Number: 36-5040821, 919 North Market st.,
                  Suite 950, Wilmington, DE, 19801
                </div>
                <div>
                  <img src={MasterCard} alt="" />
                  <img src={Mir} alt="" />
                  <img src={Visa} alt="" />
                </div>
              </div>
            </form>

            <form
              onSubmit={(e) => submitPromo(e)}
              className={classNames({
                active: activeTab == 1,
                promocode: true,
                "add-tab": true,
              })}
            >
              <div className="promocode__inner">
                <div>
                  <CFormLabel>{t("table.coupon")}</CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      type={"text"}
                      id="promocode"
                      placeholder={t(`table.coupon`)}
                      value={promo}
                      defaultValue={promo}
                      onChange={(e) => handlePromo(e)}
                    />
                  </CInputGroup>
                </div>
                <CButton type="submit">{t("common.submit")}</CButton>
              </div>
            </form>

            <div
              className={classNames({
                active: activeTab == 0,
                "add-pay__content": true,
                "add-tab": true,
              })}
            >
              <div className="add-pay__items">
                {items?.length > 0 ? (
                  items.map((el, i) => {
                    return (
                      <div
                        className={`add-pay__item ${
                          active == el.currency ? "active" : ""
                        }`}
                        onClick={() => handleChange(el, i)}
                      >
                        <div className="add-pay__item-currency">
                          {el.currency}
                          <CIcon
                            icon={cilCheckCircle}
                            className="add-pay__item-check"
                          />
                        </div>
                        <div className="add-pay__item-conditions">
                          <span>
                            {t("text.minCount")}:{" "}
                            <b>
                              {el.minCount}{" "}
                              <small>
                                {el.currency?.split(" ")?.[0] || el.currency}
                              </small>
                            </b>
                          </span>
                          <span>
                            {t("text.maxCount")}:{" "}
                            <b>
                              {el.maxCount}{" "}
                              <small>
                                {el.currency?.split(" ")?.[0] || el.currency}
                              </small>
                            </b>
                          </span>
                        </div>
                        <div className="add-pay__item-form">
                          <CInputGroup>
                            <CInputGroupText id="basic-addon1">
                              {renderCurrency(user?.currency)}
                            </CInputGroupText>
                            <CFormInput
                              placeholder={t("table.amount")}
                              type="number"
                              // min={minCount}
                              // max={maxCount}
                              value={value}
                              onChange={(e) => {
                                if (
                                  e.target.value.split(".")?.[1]?.length > 2
                                ) {
                                  return;
                                } else {
                                  setValue(e.target.value);
                                }
                              }}
                              aria-label="Amount"
                              aria-describedby="Amount"
                            />
                          </CInputGroup>
                          <CButton
                            onClick={() => submit()}
                            type="button"
                            color="primary"
                            // variant="outline"
                            id="button-addon2"
                            disabled={loading}
                          >
                            {loading ? (
                              <CSpinner size="sm" color="white" />
                            ) : (
                              t("common.submit")
                            )}
                          </CButton>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <CSpinner color="primary" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddBalancePage;
