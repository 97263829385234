import React, { useEffect, useState } from "react";
import "./index.scss";
import "./index.dark.scss";
import { CFormSelect } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import { cilAccountLogout } from "@coreui/icons";
import Day from "assets/images/day.svg";
import Night from "assets/images/night.svg";
import classNames from "classnames";
import { useLocalStorage } from "utils/utils";
import { withTranslation } from "react-i18next";
import Logo from "../../assets/images/logo-sms.png";
import { useTranslation } from "react-i18next";

function Header(props) {
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const [locale, setLocale] = useLocalStorage("locale", "gb");
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const html = document.querySelector("html").classList;
    if (theme === "light") {
      html.add("light-theme");
      html.remove("dark-theme");
    } else {
      html.add("dark-theme");
      html.remove("light-theme");
    }
  }, [theme]);
  const links = [
    {
      label: t("nav.main"),
      link: "/#about",
    },
    {
      label: t("nav.work"),
      link: "/#work",
    },
    // {
    //   label: t("nav.services"),
    //   link: "/#services",
    // },
    {
      label: t("nav.faq"),
      link: "/faq/index.html",
      type: "href",
    },
    {
      label: t("nav.api"),
      link: "/docs/index.html",
      type: "href",
    },
    // {
    //   label: t("nav.news"),
    //   link: "/news",
    // },
    {
      label: t("nav.rules"),
      link: "/rules",
    },
    // {
    //   label: t("nav.contacts"),
    //   link: "/contacts",
    // },
    {
      label: t("nav.profile"),
      link: `${props.authorized ? "/panel/main" : "/auth"}`,
    },
  ];
  return (
    <>
      <header className={`header ${active ? 'active' : ''}`}>
        <div className="container">
          <div className="d-flex justify-content-between w-100">
            <div className="header__content">
              <img src={Logo} className="header__logo" alt="" />

              <nav className="nav">
                {links.map((el) => {
                  if (el.type == "href") {
                    return <a href={el.link}>{el.label}</a>;
                  }
                  return el.link.includes("#") ? (
                    <a href={el.link} onClick={()=> setActive(false)}>{el.label}</a>
                  ) : (
                    <Link to={el.link} onClick={()=> setActive(false)}>{el.label}</Link>
                  );
                })}
              </nav>
            </div>
            {/* <div className="header__theme">

                    <button
                        className={classNames({
                            "active": theme === 'light',
                        })}
                        onClick={() => {
                            setTheme('light')
                        }}
                    >
                        <img src={Day} alt="" />
                    </button>
                    <span className="header__separator" />
                    <button
                        className={classNames({
                            "active": theme === 'dark',
                        })}
                        onClick={() => {
                            setTheme('dark')
                        }}
                    >
                        <img src={Night} alt="" />
                    </button>


                </div> */}
            <CFormSelect
              aria-label="Default select example"
              onChange={(e) => {
                setLocale(e.target.value);
              }}
              value={locale}
              options={[
                { label: t("language.ru"), value: "ru" },
                { label: t("language.en"), value: "gb" },
                { label: t("language.es"), value: "es" },
                { label: t("language.cn"), value: "cn" },
              ]}
            />
            {/* <button
                    className="reset-btn"
                    onClick={() => {
                        localStorage.removeItem('token');
                        window.location = '/auth'
                    }}
                >
                    <CIcon icon={cilAccountLogout} />
                </button> */}
          </div>
        </div>
      </header>
      <div className="mobile-page-header">
        <div className="container">
          <div className="mobile-page-header__content">
            <div
              class={`hamburger-lines ${active ? "active" : ""}`}
              onClick={() => setActive(!active)}
            >
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
            <Link to={`${props.authorized ? "/panel/main" : "/auth"}`}>
              {t("nav.profile")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(Header);
