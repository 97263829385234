const generateInfo = (value) => {
  return Object.entries(value)
    .map(([name, value]) =>
      typeof value === "boolean" ? { name, value } : null
    )
    .filter(Boolean);
};

const generateBoolData = (value) => {
  return Object.entries(value)
    .map((entry) => {
      if (
        entry[0] === "currency" ||
        entry[0] === "frozenBalance" ||
        typeof entry[1] === "boolean"
      ) {
        return;
      } else {
        return {
          name: entry[0],
          value: entry[1],
          type:
            entry[0] === "registeredAt" || entry[0] === "lastLoginAt"
              ? "datetime"
              : "text",
        };
      }
    })
    .filter((entry) => entry);
};

export { generateInfo, generateBoolData };
