import React from "react";
import "./index.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Breadcrumbs({ crumbs }) {
  return (
    <div className="breadcrumbs">
      {crumbs.map((el) => {
        return (
          <Link
            to={el.link}
            className="breadcrumbs__item"
            disabled={el.disabled}
          >
            {el.label}
          </Link>
        );
      })}
    </div>
  );
}
export default Breadcrumbs;
