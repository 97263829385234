import React from "react";
import "./index.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import { cibTelegramPlane, cilContact, cilSettings } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

function ContactsPage(props) {
  const { t } = useTranslation();

  return (
    <>
      <Header authorized={props.authorized} />

      <div className="main-rules contacts">
        <div className="container">
          <h4 className="text-center">{t("nav.contacts")}</h4> <br />
          <div className="contacts__row">
            <a href="mailto: support@sms-online.pro" target="_blank">
              <CIcon icon={cilContact} /> <span>{t("fab.mail")}</span>
            </a>
            <a href="https://t.me/smsonlinepro" target="_blank">
              <CIcon icon={cibTelegramPlane} /> <span>{t("fab.telegram")}</span>
            </a>

            <a href="https://t.me/sms_online_pro_Support_Bot" target="_blank">
              <CIcon icon={cilSettings} /> <span>{t("fab.support")}</span>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactsPage;
