import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../assets/images/logo-sms.png";
import CIcon from "@coreui/icons-react";
import { cibTelegramPlane, cilContact, cilSettings } from "@coreui/icons";
function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__start ">
            <div className="footer__contacts">
              <a href="mailto: support@sms-online.pro" target="_blank">
                <CIcon icon={cilContact} /> <span>{t("fab.mail")}</span>
              </a>
              <a href="https://t.me/smsonlinepro" target="_blank">
                <CIcon icon={cibTelegramPlane} />{" "}
                <span>{t("fab.telegram")}</span>
              </a>

              <a href="https://t.me/sms_online_pro_Support_Bot" target="_blank">
                <CIcon icon={cilSettings} /> <span>{t("fab.support")}</span>
              </a>
            </div>
          </div>
          <img src={Logo} className="footer__logo" alt="" />
          <div>
            <Link to="/rules">{t("nav.rules")}</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
