import React, { useState, useEffect } from "react";
import "./index.scss";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle, cilXCircle } from "@coreui/icons";
import { useTranslation } from "react-i18next";
import { CTooltip } from "@coreui/react";

function PasswordValidation({ value, setPasswordValidation }) {
  const [registerCase, setRegisterCase] = useState(false);
  const [specialSymbols, setSpecialSymbols] = useState(false);
  const [excludedSymbols, setExcludedSymbols] = useState(false);
  const [haveNumbers, setHaveNumbers] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (value?.length >= 10) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }

    if (/[A-Z]/.test(value) && /[a-z]/.test(value)) {
      setRegisterCase(true);
    } else {
      setRegisterCase(false);
    }

    if (/[-!$%^&*()_+|~={}\[\]:\/;<>?,.@#№]/.test(value)) {
      setSpecialSymbols(true);
    } else {
      setSpecialSymbols(false);
    }

    if (/[0-9]/.test(value)) {
      setHaveNumbers(true);
    } else {
      setHaveNumbers(false);
    }
    if (
      !/[^\w\-!$%^&*()_+|~={}\[\]:\/;<>?,.@#№]/.test(value) &&
      value?.length > 0
    ) {
      setExcludedSymbols(true);
    } else {
      setExcludedSymbols(false);
    }
  }, [value]);
  useEffect(() => {
    if (
      specialSymbols &&
      haveNumbers &&
      passLength &&
      registerCase &&
      excludedSymbols
    ) {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  }, [specialSymbols, excludedSymbols, haveNumbers, passLength, registerCase]);

  return (
    <div className="password-info">
      <div className="password__item">
        <CIcon
          icon={passLength ? cilCheckCircle : cilXCircle}
          size="lg"
          className={`text-${passLength ? "success" : "danger"}`}
        />{" "}
        <span>{t("password.length")}</span>
      </div>
      <div className="password__item">
        <CIcon
          icon={registerCase ? cilCheckCircle : cilXCircle}
          size="lg"
          className={`text-${registerCase ? "success" : "danger"}`}
        />{" "}
        <span>{t("password.register")}</span>
      </div>
      <div className="password__item">
        <CIcon
          icon={haveNumbers ? cilCheckCircle : cilXCircle}
          size="lg"
          className={`text-${haveNumbers ? "success" : "danger"}`}
        />{" "}
        <span>{t("password.numbers")}</span>
      </div>
      <div className="password__item">
        <CIcon
          icon={specialSymbols ? cilCheckCircle : cilXCircle}
          size="lg"
          className={`text-${specialSymbols ? "success" : "danger"}`}
        />{" "}
        <span>
          {t("password.symbols")}{" "}
          <CTooltip
            content={
              <>
                {t("password.included_symbols")} {"-!$%^&*()_+|~={}[]:/;<>?,.@#№"}
              </>
            }
          >
            <b className="question">?</b>
          </CTooltip>
        </span>
      </div>

      <div className="password__item">
        <CIcon
          icon={excludedSymbols ? cilCheckCircle : cilXCircle}
          size="lg"
          className={`text-${excludedSymbols ? "success" : "danger"}`}
        />{" "}
        <span>{t("password.excluded_symbols")} </span>
      </div>
    </div>
  );
}

export default PasswordValidation;
