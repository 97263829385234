import React, { useEffect, useState } from "react";
import "./index.scss";
import Axios from "axios";
import { API_URL } from "utils/settings";
import {
  CAccordion,
  CButton,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CSpinner,
} from "@coreui/react";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import PartnersProductItem from "./productItem";
import Breadcrumbs from "components/Breadcrumbs";
import { renderCurrency } from "utils/utils";
import PayoutModal from "./payoutModal";
function PartnersProducts(props) {
  const { currency, id, userInfo, user, callback } = props;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [openedItems, setOpenedItems] = useState([]);
  const [data, setData] = useState([]);
  const getProducts = () => {
    setLoading(true);
    Axios.get(`${API_URL}/admin/partner/${id}/product`).then(function (
      response
    ) {
      if (response.data) {
        const items = response.data.data.map((el) => {
          const percent = el.products.every(
            (item) => item.percent === el.products[0].percent
          );
          return {
            serviceUid: el.serviceUid,
            serviceName: el.serviceName,
            percent: percent ? el.products[0].percent : null,
            products: el.products,
          };
        });
        setData([]);
        setData(items);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <div
        className={classNames({
          "partners-wrapper": true,
          // "with-preloader": loading,
        })}
      >
        {loading && (
          <div className="with-preloader">
            <CSpinner color="primary" />
          </div>
        )}
        <div
          className={classNames({
            partners: true,
          })}
        >
          {userInfo?.email && (
            <>
              <Breadcrumbs
                crumbs={[
                  {
                    link: "/admin/partners",
                    label: t("heading.partners"),
                  },
                  {
                    link: `/admin/users/${id}`,
                    label: `${t("table.user")} ${userInfo?.email}`,
                  },
                  {
                    link: `/admin/users/${id}/products`,
                    label: t("heading.partner"),
                    disabled: true,
                  },
                ]}
              />
              <h4 className="user__heading">
                {t("heading.partner")} {userInfo?.email}
                <CButton
                  color="primary"
                  disabled={parseFloat(user.partner.balance == 0)}
                  onClick={() => setShowModal(true)}
                >
                  {t("heading.payout")}:{" "}
                  {parseFloat(user.partner.balance).toFixed(2)}
                  {renderCurrency(userInfo.currency)}
                </CButton>
              </h4>
            </>
          )}
          <CAccordion activeItemKey={openedItems}>
            {data.map((el, i) => {
              return (
                <PartnersProductItem
                  key={`${el.serviceUid}_block`}
                  loading={loading}
                  setLoading={setLoading}
                  item={el}
                  index={i+1}
                  openedItems={openedItems}
                  setOpenedItems={setOpenedItems}
                  id={id}
                  callback={getProducts}
                />
              );
            })}
          </CAccordion>
        </div>
      </div>
      {console.log("user", userInfo)}
      <PayoutModal
        show={showModal}
        setShow={setShowModal}
        balance={user?.partner.balance}
        currency={userInfo?.currency}
        id={id}
        callback={callback}
      />
    </>
  );
}
export default PartnersProducts;
