import React from "react";
import "./index.scss";
import CountrySelect from "./select";

function ChooseCountries(props) {
  const { countries, selectedCountries, setSelectedCountries, setActiveCountry, activeCountry } = props;
  return (
    <div className="choose-countries">
      <CountrySelect
        countries={countries}
        defaultValue={selectedCountries}
        handler={setSelectedCountries}
        setActiveCountry={setActiveCountry}
        index="0"
        activeCountry={activeCountry}
      />
      <CountrySelect
        countries={countries}
        defaultValue={selectedCountries}
        handler={setSelectedCountries}
        setActiveCountry={setActiveCountry}
        index="1"
        activeCountry={activeCountry}
      />
      <CountrySelect
        countries={countries}
        defaultValue={selectedCountries}
        handler={setSelectedCountries}
        setActiveCountry={setActiveCountry}
        index="2"
        activeCountry={activeCountry}
      />
      <CountrySelect
        countries={countries}
        defaultValue={selectedCountries}
        handler={setSelectedCountries}
        setActiveCountry={setActiveCountry}
        index="3"
        activeCountry={activeCountry}
      />
      <CountrySelect
        countries={countries}
        defaultValue={selectedCountries}
        handler={setSelectedCountries}
        setActiveCountry={setActiveCountry}
        index="4"
        activeCountry={activeCountry}
      />
      <CountrySelect
        countries={countries}
        defaultValue={selectedCountries}
        handler={setSelectedCountries}
        setActiveCountry={setActiveCountry}
        index="5"
        activeCountry={activeCountry}
      />
    </div>
  );
}

export default ChooseCountries;
