import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserContext } from "core/contexts";
import OrderTable from "pages/AdminMainPage/OrderTable";
import PaymentTable from "pages/BalanceHistory/PaymentTable";
import UserForm from "pages/SettingsPage/userForm";
import TransactionsTable from "pages/SettingsPage/transactionsTable";
import CouponsTable from "pages/AdminPromoPage/table";
import { generateUserInfo } from "./userUtils";
import PartnersProducts from "./partnersProduct";
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import Breadcrumbs from "components/Breadcrumbs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  defaultBackMonthDate,
  defaultBackWeekDate,
  defaultEndDate,
  defaultStartDate,
  defaultStartYeartDate,
  useLocalStorage,
  useWindowSize,
} from "utils/utils";
import { cibLinuxMint } from "@coreui/icons";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import ToastComponent from "components/ToastComponent";
import _ from "lodash";
import Filters from "components/Filters";

function AdminUserPage(props) {
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const { t } = useTranslation();
  // START ORDERS //
  const [needFetch, setNeedFetch] = useState(true);
  const [counter, setCounter] = useState(0);
  const [total, setTotal] = useState(0);
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const windowSize = useWindowSize()[0];
  const isMobile = windowSize < 991;
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [userInfo, setUserInfo] = useState(null);
  const [userData, setUserData] = useState(null);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [couponsIsLoading, setCouponsIsLoading] = useState(true);
  const [couponsPerPage, setCouponsPerPage] = useState(20);
  const [couponsTotal, setCouponsTotal] = useState(0);
  const [couponsPage, setCouponsPage] = useState(1);
  const [coupons, setCoupons] = useState([]);
  const [orderStats, setOrderStats] = useState([]);
  const [aggrFilters, setAggrFilters] = useState([
    {
      name: "dateFrom",
      type: "date",
      value: defaultBackWeekDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);
  const [filters, setFilters] = useState([
    {
      name: "statuses",
      items: [
        "all",
        "new",
        "waiting_phone",
        "waiting_sms",
        "finished",
        "canceled",
        "declined",
        "no_numbers",
      ],
      type: "multiselect",
      value: null,
    },
    // {
    //   name: "dateFrom",
    //   type: "date",
    //   value: defaultStartDate(),
    // },
    // {
    //   name: "dateTo",
    //   type: "date",
    //   value: defaultEndDate(),
    // },
  ]);
  // const [transFilters, setTransFilters] = useState([
  //   {
  //     name: "statuses",
  //     items: [
  //       "all",
  //       "new",
  //       "waiting_phone",
  //       "waiting_sms",
  //       "finished",
  //       "canceled",
  //       "declined",
  //       "no_numbers",
  //     ],
  //     type: "multiselect",
  //     value: null,
  //   },
  //   // {
  //   //   name: "dateFrom",
  //   //   type: "date",
  //   //   value: defaultStartDate(),
  //   // },
  //   // {
  //   //   name: "dateTo",
  //   //   type: "date",
  //   //   value: defaultEndDate(),
  //   // },
  // ]);
  // const [couponsFilters, setCouponsFilters] = useState([
  //   {
  //     name: "statuses",
  //     items: ["all", "new", "activated", "expired"],
  //     type: "multiselect",
  //     value: null,
  //   },
  //   // {
  //   //   name: "dateFrom",
  //   //   type: "date",
  //   //   value: defaultBackMonthDate(),
  //   // },
  //   // {
  //   //   name: "dateTo",
  //   //   type: "date",
  //   //   value: defaultEndDate(),
  //   // },
  // ]);
  const [orderSorting, setOrderSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
    {
      name: "status",
      label: t("sorting.status"),
      value: null,
    },
  ]);
  const [activeSorting, setActiveSorting] = useState(null);
  const [transactionSorting, setTransactionSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
  ]);
  const [transactionActiveSorting, setTransactionActiveSorting] =
    useState(null);
  // END ORDERS //
  // START PAYMENTS //
  const [payments, setPayments] = useState([]);
  const [paymentsPage, setPaymentsPage] = useState(1);
  const [paymentsPerPage, setPaymentsPerPage] = useState(20);
  const [paymentsTotal, setPaymentsTotal] = useState(0);
  const [paymentsIsLoading, setPaymentsIsLoading] = useState(true);
  // END PAYMENTS //
  useEffect(() => {
    if (counter > 1) {
      getOrderStats();
    }
    if (counter > 2) {
      getOrders(page, perPage, filters, false);
      getPayments(paymentsPage, paymentsPerPage, [], transactionActiveSorting);
      getCoupons(
        couponsPage,
        couponsPerPage,
        // couponsFilters,
        [],
        couponsActiveSorting
      );
    }
  }, [counter]);
  useEffect(() => {
    setCounter(counter + 1);
  }, [aggrFilters]);
  const getOrderStats = () => {
    let filters = aggrFilters;
    setIsLoading(true);
    let params = {};
    filters.forEach((el) => {
      if (el.value && el.value !== "all") {
        if (el.type == "date") {
          let date = new Date(el.value);
          // date.setDate(date.getDate() + 1);
          params = {
            ...params,
            [el.name]: date.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    params["userId"] = id;
    // if (!needFetch) {
    //   return;
    // }
    Axios.get(`${API_URL}/admin/order/aggregation`, {
      params: params,
    })
      .then(function (response) {
        setIsLoading(false);
        if (response.data.data) {
          setOrderStats(response.data.data);
        }
        // setNeedFetch(true);
      })
      .catch((error) => {
        setIsLoading(false);
        // setNeedFetch(false);
      });
  };

  const getOrders = (page, perPage, filters, file = false) => {
    setIsLoading(true);

    let params = {
      isPaginated: !file ? 1 : 0,
    };
    if (!file) {
      params["page"] = page;
      params["perPage"] = perPage;
    }
    if (activeSorting !== "sorting.null" && activeSorting !== null) {
      params["sort"] = [activeSorting];
    }
    filters.forEach((el) => {
      if ((el.value && el.value !== "all") || el.type == "date") {
        if (el.type == "multiselect") {
          params = { ...params, [el.name]: [el.value] };
          return;
        }

        params = { ...params, [el.name]: el.value };
      }
    });
    let dateFromInput = document.querySelectorAll(".orderAggr input")[0];
    let dateToInput = document.querySelectorAll(".orderAggr input")[1];
    params["dateFrom"] = new Date(dateFromInput.value).toISOString();
    params["dateTo"] = new Date(dateToInput.value).toISOString();
    let dateTo = new Date(params.dateTo?.split("T")?.[0]);
    let dateFrom = new Date(params.dateFrom?.split("T")?.[0]);

    const differenceInMilliseconds = dateTo.getTime() - dateFrom.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (differenceInDays > 14 && !file) {
      ToastComponent("info", t("filters.date_two_week"));
      setIsLoading(false);
      return;
    }
    params["userId"] = id;
    Axios.get(`${API_URL}/admin/order/all`, {
      params: params,
    })
      .then(function (response) {
        setIsLoading(false);
        if (response.data.data?.length > 0 && file) {
          const columns = Object.keys(response.data.data[0]);
          const fileType = "text/csv;charset=utf-8";
          const fileExtension = ".csv";

          const ws = XLSX.utils.json_to_sheet(
            _.flatten(
              response.data.data.map((el) => {
                return {
                  id: el.id,
                  [t("table.amount")]: el.amount
                    ? new Intl.NumberFormat("ru-RU", {
                        style: "currency",
                        currency: user?.currency || "USD",
                      }).format(el.amount || 0)
                    : 0,
                  [t("table.country")]: el.countryAlpha2,
                  [t("table.service")]: el.serviceName,
                  [t("table.status")]: t(`table.${el.status}`),
                  [t("common.phone")]: el.phone?.toString(),
                  [t("table.sms_content")]: el.smsContent,
                  [t("table.date")]:
                    new Date(el.createdAt).toLocaleString().split(",")?.[0] ||
                    "—",
                };
              })
            )
          );
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "csv",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, "orders" + fileExtension);
        }
        if (response.data.data?.length > 0 && !file) {
          setOrders(response.data.data);
          setTotal(response.data.total);
        } else if (response?.data?.data?.length == 0 && !file) {
          setOrders([]);
          setTotal(0);
        } else if (response?.data?.data?.length == 0 && file) {
          ToastComponent("error", t("table.notFound"));
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const changeBalance = (bal) => {
    setIsLoading(true);
    Axios.post(`${API_URL}/admin/user-balance`, {
      userId: parseInt(id),
      correctionAmount: bal,
    })
      .then(function (response) {
        if (response.data.resultingBalance) {
          getUser();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const getUser = () => {
    Axios.get(`${API_URL}/admin/user/${id}`, {}).then(function (response) {
      if (response.data) {
        const data = response.data;

        let endData = {};
        let newData = {
          balance: data.balance,
          currency: data.currency,
          email: data.email,
          frozenBalance: data.frozenBalance,
          isActivated: data.isActivated,
          isAdmin: data.isAdmin,
          isSupport: data.isSupport,
          lastIp: data.lastIp,
          lastLoginAt: data.lastLoginAt,
          registeredAt: data.registeredAt,
          restriction: data.restriction,
          partner: {
            // partnerBalance: data.partner.balance,
            isPartner: data.partner.isActive,
          },
        };
        setUserData(data);
        setUserInfo(generateUserInfo(endData, newData));
      } else {
      }
    });
  };
  const [couponsSorting, setCouponsSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
  ]);
  const [couponsActiveSorting, setCouponsActiveSorting] = useState(null);

  const getPayments = (ppage, pperPage, pfilters, psorting) => {
    setPaymentsIsLoading(true);
    let params = {
      perPage: pperPage,
      page: ppage,
      userId: id,
    };

    if (psorting !== "sorting.null" && psorting !== null) {
      params["sort"] = [psorting];
    }
    // transFilters.forEach((el) => {
    //   if ((el.value && el.value !== "all") || el.type == "date") {
    //     if (el.type == "multiselect") {
    //       params = { ...params, [el.name]: [el.value] };
    //       return;
    //     }

    //     params = { ...params, [el.name]: el.value };
    //   }
    // });

    let dateFromInput = document.querySelectorAll(".orderAggr input")[0];
    let dateToInput = document.querySelectorAll(".orderAggr input")[1];
    params["dateFrom"] = new Date(dateFromInput.value).toISOString();
    params["dateTo"] = new Date(dateToInput.value).toISOString();

    Axios.get(`${API_URL}/admin/transaction/all`, {
      params: params,
    })
      .then(function (response) {
        setIsLoading(false);
        if (response.data.data?.length > 0) {
          setPayments(response.data.data);
          setPaymentsTotal(response.data.total);
          setPaymentsIsLoading(false);
        } else {
          setPayments([]);
          setPaymentsTotal(0);
          setPaymentsIsLoading(false);
        }
      })
      .catch((error) => {
        setPaymentsIsLoading(false);
      });
  };
  const getCoupons = (pageVal, perPageVal, filtersVal, activeSortingVal) => {
    setCouponsIsLoading(true);

    let params = {
      perPage: perPageVal,
      page: pageVal,
      userId: id,
    };
    if (activeSortingVal !== "sorting.null" && activeSortingVal !== null) {
      params["sort"] = [activeSortingVal];
    }
    // filtersVal?.forEach((el) => {
    //   if ((el.value && el.value !== "all") || el.type == "date") {
    //     if (el.type == "multiselect") {
    //       params = { ...params, [el.name]: [el.value] };
    //       return;
    //     }

    //     params = { ...params, [el.name]: el.value };
    //   }
    // });
    let dateFromInput = document.querySelectorAll(".orderAggr input")[0];
    let dateToInput = document.querySelectorAll(".orderAggr input")[1];
    params["dateFrom"] = new Date(dateFromInput.value).toISOString();
    params["dateTo"] = new Date(dateToInput.value).toISOString();

    Axios.get(`${API_URL}/admin/coupon/all`, {
      params: params,
    })
      .then(function (response) {
        setCouponsIsLoading(false);

        if (response.data.data?.length > 0) {
          setCoupons(response.data.data);
          setCouponsTotal(response.data.total);
        } else {
          setCoupons([]);
          setCouponsTotal(0);
        }
      })
      .catch((error) => {
        setCouponsIsLoading(false);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className={`page ${expanded ? "expanded" : ""}`}>
      <Sidebar />

      <div className="container container-panel">
        <div className="panel__content">
          <br />

          {userInfo?.email && (
            <>
              <Breadcrumbs
                crumbs={[
                  {
                    link: "/admin/stats/users",
                    label: t("heading.users"),
                  },
                  {
                    link: `/admin/users/${id}`,
                    label: userInfo?.email,
                    disabled: true,
                  },
                ]}
              />
              <h4 className="user__heading">
                {t("heading.user")} {userInfo?.email}
                {userData?.partner?.hasProducts && (
                  <div className="user__buttons">
                    {!isMobile ? (
                      <Link
                        to={`/admin/users/${id}/products`}
                        className="btn btn-primary"
                      >
                        {t("heading.partners_products")}
                      </Link>
                    ) : (
                      <CDropdown>
                        <CDropdownToggle
                          color="info"
                          variant="outline"
                        ></CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem
                            component={Link}
                            to={`/admin/users/${id}/products`}
                          >
                            {t("heading.partners_products")}
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    )}
                  </div>
                )}
              </h4>
            </>
          )}
          {userInfo && (
            <UserForm
              user={userInfo}
              readOnly
              callback={getUser}
              changeBalance={changeBalance}
              id={id}
              admin={true}
            />
          )}
          <div className="balance stats">
            <div className="shadow-inset orderAggr">
              <h6>
                {t("heading.orders")}{" "}
                {aggrFilters && (
                  <Filters items={aggrFilters} setFilters={setAggrFilters} />
                )}
              </h6>

              <div className="stats-list">
                {orderStats?.map((el) => {
                  return (
                    <div className="stats-item">
                      <span>{t(`table.${el.status}`)}</span> <b>{el.count}</b>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <br />
          {user?.currency && (
            <OrderTable
              orders={orders}
              setOrders={setOrders}
              getOrders={getOrders}
              total={total}
              setTotal={setTotal}
              currency={user?.currency}
              page={page}
              sorting={orderSorting}
              activeSorting={activeSorting}
              setSorting={setActiveSorting}
              perPage={perPage}
              title={`${t("heading.order")} — ${total}`}
              setPage={setPage}
              setPerPage={setPerPage}
              isLoading={isLoading}
              filters={filters}
              setFilters={setFilters}
              updateUser={userContext.updateUser}
            />
          )}
          {user && (
            <>
              <br />
              <br />
              <TransactionsTable
                currency={user.currency}
                page={paymentsPage}
                perPage={paymentsPerPage}
                setPage={setPaymentsPage}
                sorting={transactionSorting}
                activeSorting={transactionActiveSorting}
                setSorting={setTransactionActiveSorting}
                setPerPage={setPaymentsPerPage}
                isLoading={paymentsIsLoading}
                total={paymentsTotal}
                setTotal={setPaymentsTotal}
                // filters={transFilters}
                // setFilters={setTransFilters}
                getPayments={getPayments}
                payments={payments}
              />
              <br />
              <CouponsTable
                coupons={coupons}
                setCoupons={setCoupons}
                getCoupons={getCoupons}
                total={couponsTotal}
                setTotal={setCouponsTotal}
                currency={user?.currency}
                page={couponsPage}
                sorting={couponsSorting}
                activeSorting={couponsActiveSorting}
                setSorting={setCouponsActiveSorting}
                perPage={couponsPerPage}
                title={`${t("menu.coupons")} — ${couponsTotal}`}
                setPage={setCouponsPage}
                setPerPage={setCouponsPerPage}
                isLoading={couponsIsLoading}
                setLoading={setCouponsIsLoading}
                // filters={couponsFilters}
                // setFilters={setCouponsFilters}
              />
              <br />
              <br />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminUserPage;
