import React, { useState, useEffect } from "react";
import "./index.scss";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import {
  CButton,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import Select from "react-select";
import { useLocalStorage } from "utils/utils";
import { cilPencil, cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import ToastComponent from "components/ToastComponent";
function PrefixIgnore(props) {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [show, setShow] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [locale] = useLocalStorage("locale", "gb");
  const [loading, setLoading] = useState(false);
  const [prefixes, setPrefixes] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const [editId, setEditId] = useState("");
  const [editPrefix, setEditPrefix] = useState("");
  const [type, setType] = useState("add");
  const countriesObj = {
    ru: ruCountries,
    gb: enCountries,
    cn: cnCountries,
    es: esCountries,
  };
  const actualLanguage = countriesObj[locale];
  useEffect(() => {
    getPrefix();
    getCountries();
  }, []);
  const getPrefix = () => {
    Axios.get(`${API_URL}/phone/prefix-ignore`)
      .then(function (response) {
        if (response.data) {
          const data = response.data.data;
          if (data?.legnth == 0) {
            setPrefixes({});
            return;
          }
          let obj = {};
          data.map((el) => {
            if (obj.hasOwnProperty(el.countryId)) {
              obj[el.countryId] = obj[el.countryId].concat(el);
            } else {
              obj[el.countryId] = [el];
            }
          });
          setPrefixes(obj);
        }
      })
      .catch((error) => {});
  };
  const getCountries = () => {
    Axios.get(`${API_URL}/country`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setCountries(response.data.data.filter((el) => el.phonePrefix));
        }
      })
      .catch((error) => {});
  };
  const deletePrefix = (id) => {
    setLoading(true);
    Axios.delete(`${API_URL}/phone/prefix-ignore`, {
      data: {
        id: id,
      },
    })
      .then(function (response) {
        if (response) {
          ToastComponent("success", t("prefix.delete"));
          getPrefix();
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div className="prefix">
      <h6>{t("prefix.heading")}</h6>
      <p>{t("prefix.subheading")}</p>
      {prefixes && (
        <div className="prefix-list">
          <CAccordion activeItemKey={activeKey}>
            {Object.entries(prefixes).map((el) => {
              return (
                <CAccordionItem
                  key={el[0]}
                  itemKey={el[0]}
                  onClick={() => setActiveKey(el[0])}
                >
                  <CAccordionHeader>
                    <div className="prefix-header">
                      <div className={`flag flag-${el[0]}`} />{" "}
                      <b>
                        {actualLanguage[el[0]]} +{el[1][0].countryPhonePrefix}
                      </b>
                    </div>
                  </CAccordionHeader>
                  <CAccordionBody>
                    {el[1].map((item, i) => {
                      if (i > 14) {
                        return;
                      }
                      return (
                        <div className="prefix-item" key={item.id}>
                          <div className="prefix-item__start">
                            <div className={`flag flag-${item.countryId}`} />{" "}
                            <b>+{item.countryPhonePrefix}</b>{" "}
                            {item.phonePrefixIgnore}
                          </div>
                          <div className="prefix-item__end">
                            <CButton
                              size="sm"
                              variant="outline"
                              color="secondary"
                              disabled={loading}
                              onClick={() => {
                                setType("edit");
                                setShow(true);
                                setDefaultValue({
                                  label: (
                                    <>
                                      <div
                                        className={`flag flag-${item.countryId}`}
                                      />{" "}
                                      +{item.countryPhonePrefix}
                                    </>
                                  ),
                                  value: item.countryId,
                                });
                                setEditId(item.id);
                                setEditPrefix(item.phonePrefixIgnore);
                              }}
                            >
                              <CIcon size="sm" icon={cilPencil} />
                            </CButton>
                            <CButton
                              size="sm"
                              variant="outline"
                              color="danger"
                              disabled={loading}
                              onClick={() => deletePrefix(item.id)}
                            >
                              <CIcon size="sm" icon={cilX} />
                            </CButton>
                          </div>
                        </div>
                      );
                    })}
                  </CAccordionBody>
                </CAccordionItem>
              );
            })}
          </CAccordion>
        </div>
      )}
      <CButton
        onClick={() => {
          setType("add");
          setShow(true);
          setEditId();
          setEditPrefix();
        }}
      >
        {t("prefix.add")}
      </CButton>
      {show && (
        <PrefixModal
          show={show}
          callback={() => {
            setShow(false);
            getPrefix();
          }}
          type={type}
          countries={countries}
          setShow={setShow}
          editId={editId}
          defaultValue={defaultValue}
          editPrefixValue={editPrefix}
        />
      )}
    </div>
  );
}

export default PrefixIgnore;

function PrefixModal(props) {
  const { t } = useTranslation();
  const {
    setShow,
    show,
    type,
    countries,
    callback,
    defaultValue,
    editId,
    editPrefixValue,
  } = props;
  const [value, setValue] = useState();
  const [selectValue, setSelectValue] = useState("");
  const submit = () => {
    type == "add" ? addPrefix() : editPrefix();
  };
  useEffect(() => {
    if (editPrefixValue && type == "edit") {
      setValue(editPrefixValue);
    }
  }, [editPrefixValue]);
  const addPrefix = () => {
    Axios.post(`${API_URL}/phone/prefix-ignore`, {
      countryId: selectValue.label.props.children[0]?.props.className
        .split(" ")[1]
        ?.split("-")[1],
      phonePrefix: +value,
    })
      .then(function (response, error) {
        if (response) {
          ToastComponent("success", t("prefix.added"));
          callback();
        }
      })
      .catch((error) => {});
  };
  const editPrefix = () => {
    Axios.put(`${API_URL}/phone/prefix-ignore`, {
      id: editId,
      countryId: defaultValue.value,
      phonePrefix: +value,
    })
      .then(function (response, error) {
        if (response) {
          ToastComponent("success", t("prefix.edited"));

          callback();
        }
      })
      .catch((error) => {});
  };
  const isDisabledButton = () => {
    if (type == "edit" && value == editPrefixValue) {
      return true;
    } else if (!value) {
      return true;
    } else if (!selectValue && type == "add") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <CModal visible={show} onClose={() => setShow(false)}>
      <CModalHeader>
        <CModalTitle>
          {type == "add" ? t("prefix.add") : t("prefix.edit")}
        </CModalTitle>
      </CModalHeader>
      <form className="prefix-modal" onSubmit={(e) => submit(e)}>
        <CModalBody>
          <CInputGroup>
            <CInputGroupText>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                name="prefix"
                defaultValue={type == "edit" && defaultValue}
                isDisabled={type == "edit"}
                placeholder=""
                options={countries.map((el) => {
                  return {
                    label: (
                      <div>
                        <div className={`flag flag-${el.id}`} />{" "}
                        {`+${el.phonePrefix}`}
                      </div>
                    ),
                    value: `+${el.phonePrefix}`,
                  };
                })}
                onChange={(value) => {
                  setValue("");
                  setSelectValue(value);
                }}
              />
            </CInputGroupText>
            <CFormInput
              value={value}
              type="number"
              defaultValue={editPrefixValue}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ",", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              onChange={(e) => {
                let length = e.target.value?.length;
                if (type == "add") {
                  if (selectValue?.value) {
                    length =
                      length + selectValue.value?.replace("+", "")?.length;
                  }
                } else if (type == "edit") {
                  if (defaultValue?.label) {
                    length =
                      length +
                      defaultValue?.label?.props?.children[
                        defaultValue?.label?.props?.children?.length - 1
                      ].toString().length;
                  }
                }
                if (length < 7) {
                  setValue(e.target.value);
                }
              }}
            />
          </CInputGroup>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            variant="outline"
            onClick={() => setShow(false)}
          >
            {t("common.cancel")}
          </CButton>
          <CButton
            color="primary"
            disabled={isDisabledButton()}
            onClick={() => submit()}
          >
            {t("button.save")}
          </CButton>
        </CModalFooter>
      </form>
    </CModal>
  );
}
