import React, { useContext } from "react";
import {
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import { getDate, renderCurrency } from "utils/utils";
import CIcon from "@coreui/icons-react";
import { cilSnowflake, cilPen } from "@coreui/icons";

export const renderInputs = (value, t, readOnly, user, data, setValue, addData = null) => {
  const label = renderLabel(value, t, user);
  const inputId = `form_${value.name}`;
  const placeholder = t(`placeholder.${value.name}`);
  const inputValue = renderValue(value);
  const isBalance = value.name === "balance";
  return (
    <div>
      <CFormLabel>{label}</CFormLabel>
      <CInputGroup>
        <CFormInput
          type={"text"}
          id={inputId}
          placeholder={placeholder}
          value={inputValue}
          // onChange={(e) =>
          //   setValue(
          //     data.map((el) =>
          //       el.name == value.name
          //         ? {
          //             name: el.name,
          //             value: e.target.value.toString(),
          //             type: el.type,
          //           }
          //         : el
          //     )
          //   )
          // }
          defaultValue={inputValue}
          disabled={readOnly}
        />
        {isBalance && (
          <>
            <CInputGroupText>{renderCurrency(user.currency)}</CInputGroupText>
            {setValue && !window.location.href.includes('panel/settings')  && (
              <CInputGroupText className="btn btn-primary" onClick={()=> addData.setShow(true)}>
                <CIcon icon={cilPen} />
              </CInputGroupText>
            )}
          </>
        )}
      </CInputGroup>
    </div>
  );
};

export const renderValue = (value) => {
  if (!value?.value) {
    return;
  }
  if (value.type === "datetime") {
    const date = getDate(value.value);
    return `${date}, ${value?.value?.split("T")[1].split("+")[0]}`;
  }
  return value.value;
};

function renderLabel(value, translator, user) {
  if (value.name === "balance" && user?.frozenBalance) {
    return (
      <>
        {translator(`placeholder.${value.name}`)}
        (<CIcon icon={cilSnowflake} />
        {user.frozenBalance}
        {renderCurrency(user?.currency)})
      </>
    );
  }

  return <>{translator(`placeholder.${value.name}`)}</>;
}
