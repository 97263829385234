import React from "react";
import { toast } from "react-toastify";

function ToastComponent(type, text) {
    return toast[type](
        () => (<><span>{text}</span></>),
        {
            position: "top-right",
        },
    );
}

export default ToastComponent;
