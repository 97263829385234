import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation()

    const items = [
        {
            "img": "https://sms-online.pro/files/images/alt/OD-300x150-resize-pad.png",
            "label": "Одноклассники"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/24-Windows-logo-300x150-resize-pad.jpg",
            "label": "Microsoft"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/av-300x150-resize-pad.png",
            "label": "Avito"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/ul-300x150-resize-pad.jpg",
            "label": "Юла"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/spaces-ru-logo-300x150-resize-pad.jpg",
            "label": "Spaces.ru"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/unnamed-300x150-resize-pad.png",
            "label": "uCoz"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/logo_bg_400x400-300x150-resize-pad.png",
            "label": "Beget"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/st-300x150-resize-pad.png",
            "label": "Steam"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/CP-300x150-resize-pad.png",
            "label": "Chauffeur Privé"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/bk-300x150-resize-pad.png",
            "label": "Burger_king"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/2018-08-29_13-59-15-300x150-resize-pad.png",
            "label": "ingos"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/2018-09-18_19-49-02-300x150-resize-pad.png",
            "label": "Dodopizza"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/unnamed-300x150-resize-pad.png",
            "label": "uCoz"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/logo_bg_400x400-300x150-resize-pad.png",
            "label": "Beget"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/st-300x150-resize-pad.png",
            "label": "Steam"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/2018-08-29_13-59-15-300x150-resize-pad.png",
            "label": "ingos"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/spaces-ru-logo-300x150-resize-pad.jpg",
            "label": "Spaces.ru"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/2018-09-18_19-49-02-300x150-resize-pad.png",
            "label": "Dodopizza"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/bk-300x150-resize-pad.png",
            "label": "Burger_king"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/ViaBTC-logo-300x150-resize-pad.jpg",
            "label": "ViaBTC"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/Dent-300x150-resize-pad.png",
            "label": "Dent"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/ViaBTC-logo-300x150-resize-pad.jpg",
            "label": "ViaBTC"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/Dent-300x150-resize-pad.png",
            "label": "Dent"
        },
        {
            "img": "https://sms-online.pro/files/images/alt/ViaBTC-logo-300x150-resize-pad.jpg",
            "label": "ViaBTC"
        },
   
    ]
    return <section id="services" className="services">
        <div className="container">
            <h3>
                {t('nav.services')}
            </h3>
            <p>{t('services.subtitle')}</p>
            <div className="services__content">
                {items.map((el) => {
                    return <div className="services__item">
                        <img src={el.img} alt="" />
                        <p>{el.label}</p>
                    </div>
                })}
            </div>
        </div>
    </section>
}
export default Services