import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cilClock,
  cilX,
  cibVk,
  cilCheck,
  cilOptions,
  cilListFilter,
} from "@coreui/icons";
import { CSpinner, CTooltip } from "@coreui/react";

import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import { useWindowSize } from "utils/utils";
import Sorting from "components/Sorting";

function PaymentTable(props) {
  const {
    currency,
    payments,
    page,
    setPage,
    total,
    perPage,
    setPerPage,
    filters,
    setFilters,
    isLoading,
    getPayments,
    sorting,
    activeSorting,
    setSorting,
  } = props;
  const [count, setCount] = useState(0);
  const windowSize = useWindowSize()[0];
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = windowSize < 767;

  const renderStatus = (status) => {
    return t(`table.${status}`);
  };
  const renderIcon = (iconName, country) => {
    const icons = {
      vk: cibVk,
    };
    if (!icons[iconName]) {
      return `${iconName} - ${country}`;
    } else {
      return (
        <>
          <CIcon icon={icons[iconName]} /> - {country}
        </>
      );
    }
  };
  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.id"),
      center: true,
      selector: (row) => row.id || "—",
      sortable: true,
      notMobile: true,
    },
    {
      id: "serviceUid",
      name: t("table.type"),
      center: true,
      selector: (row) => t(`table.${row.type}`) || "—",
      sortable: true,
    },
    {
      id: "provider",
      name: t("table.provider"),
      center: true,
      selector: (row) => t(`${row.paymentProvider}`) || "—",
      notMobile: true,
    },
    {
      id: "createDate",
      name: t("table.date"),
      center: true,
      selector: (row) =>
        (new Date(row.createdAt).toLocaleString() && !isMobile
          ? new Date(row.createdAt).toLocaleString()
          : new Date(row.createdAt).toLocaleString().split(",")?.[0]) || "—",
      sortable: true,
    },

    {
      id: "status",
      name: t("table.status"),
      center: true,
      selector: (row) => renderStatus(row.status),
      sortable: true,
    },
    {
      id: "amount",
      name: t("table.amount"),
      center: true,
      selector: (row) =>
        new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: currency,
        }).format(row.amount || 0),
      sortable: true,
    },
  ];

  useEffect(() => {
    getPayments(page, perPage, filters);
  }, []);
  useEffect(() => {
    count > 1 && getPayments(page, perPage, filters);
  }, [count]);

  return (
    <>
      {isLoading && (
        <div className="with-preloader">
          <CSpinner color="primary" />
        </div>
      )}
      <div className="orders-table">
        <div className="filters-mobile">
          <h6>{`${t("heading.payments")} — ${total}`}</h6>

          <span onClick={() => setShowFilters(true)}>
            <CIcon icon={cilListFilter} />
            {t("filters")}
          </span>
        </div>{" "}
        {showFilters && (
          <div className="filters-plug" onClick={() => setShowFilters(false)} />
        )}
        <div className={`filters-wrapper ${showFilters ? "active" : ""}`}>
          {showFilters && (
            <div
              className="filters-close"
              onClick={() => setShowFilters(false)}
            >
              <CIcon icon={cilX} />
            </div>
          )}
          {filters?.length > 0 && (
            <Filters
              items={filters}
              setFilters={(val) => {
                setFilters(val);
                setCount(count + 1);
              }}
              title={`${t("heading.payments")} — ${total}`}
            />
          )}
          {sorting?.length > 0 && (
            <>
              <Sorting
                items={sorting}
                setItems={setSorting}
                active={activeSorting}
              />
            </>
          )}
        </div>
        {!isMobile ? (
          <DataTable
            columns={columns}
            data={payments}
            sortIcon={<CIcon icon={cilArrowTop} />}
            // progressPending={isLoading}
            progressComponent={<CSpinner color="primary" />}
            noDataComponent={t("table.notFound")}
          />
        ) : (
          <div className="mobile-table">
            {payments?.length > 0 ? (
              payments.map((row) => {
                return (
                  <div className="mobile-table__item">
                    {isMobile && (
                      <>
                        <CTooltip
                          content={
                            <div className="tooltip-item">
                              {columns.map((el) => {
                                return (
                                  (el.id == "id" ||
                                    el.id == "createDate" ||
                                    el.id == "provider") && (
                                    <div className="tooltip-row">
                                      <div className="tooltip-key">
                                        {el.name}
                                      </div>
                                      <div className="tooltip-key">
                                        {el.selector(row)}
                                      </div>
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          }
                        >
                          <div className="mobile-info mobile-info_transparent">
                            <CIcon icon={cilOptions} />
                          </div>
                        </CTooltip>
                      </>
                    )}
                    {columns.map((el) => {
                      if (el.id == "phone") {
                        return (
                          <div className="mobile-table__row">
                            <div className="mobile-table__key">
                              <div>
                                {row.serviceUid &&
                                  renderIcon(row.serviceUid, row.countryAlpha2)}
                              </div>
                            </div>
                            <div className="mobile-table__value">
                              {el.selector(row)}
                            </div>
                          </div>
                        );
                      }
                      return !el.notMobile ? (
                        <div className="mobile-table__row">
                          {el.name && (
                            <div className="mobile-table_key">{el.name}</div>
                          )}
                          {el.selector(row) && (
                            <div className="mobile-table__value">
                              {el.selector(row)}
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <div className="mobile-table__empty">{t("table.notFound")}</div>
            )}
          </div>
        )}
        {payments?.length > 0 && (
          <TablePagination
            page={page}
            setPage={(page) => {
              setPage(page);
              getPayments(page, perPage, filters);
            }}
            total={total}
            perPage={perPage}
            setPerPage={(val) => {
              setPerPage(val);
              setPage(1);
              getPayments(1, val, filters);
            }}
          />
        )}
      </div>
    </>
  );
}

export default PaymentTable;
