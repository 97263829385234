import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserContext } from "core/contexts";
import Breadcrumbs from "components/Breadcrumbs";
import PartnersProducts from "pages/AdminUserPage/partnersProduct";
import { generateUserInfo } from "pages/AdminUserPage/userUtils";
import { useLocalStorage } from "utils/utils";

function PartnersProductsPage(props) {
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [userData, setUserData] = useState(null);
  const [total, setTotal] = useState(0);
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");

  const getUser = () => {
    Axios.get(`${API_URL}/admin/user/${id}`, {}).then(function (response) {
      if (response.data) {
        const data = response.data;
        let newData = {};
        setUserData(data);
        setUserInfo(generateUserInfo(newData, data));
      } else {
      }
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <div className={`page ${expanded ? "expanded" : ""}`}>
      <Sidebar />

      <div className="container container-panel">
        <div className="panel__content">
          <PartnersProducts
            id={id}
            currency={user?.currency}
            userInfo={userInfo}
            user={userData}
            callback={getUser}
          />
        </div>
      </div>
    </div>
  );
}

export default PartnersProductsPage;
