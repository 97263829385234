import React, { useState, useEffect } from "react";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Axios from "axios";
import { API_URL } from "utils/settings";

import { useTranslation } from "react-i18next";
import TablePagination from "components/TablePagination";
import { cilCheckCircle, cilSearch } from "@coreui/icons";
import ProductItemLine from "./productItemLine";
import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import { useLocalStorage } from "utils/utils";

function PartnersProductItem(props) {
  const { item, callback, id, index, openedItems, setOpenedItems } = props;
  const [percent, setPercent] = useState(props.item.percent);
  const [page, setPage] = useState(1);
  const [country, setCountry] = useState("");
  const [locale] = useLocalStorage("locale", "gb");
  const [total, setTotal] = useState(props.item.products?.length);
  const [perPage, setPerPage] = useState(20);
  const [showBtn, setShowBtn] = useState(false);
  const [ids, setIds] = useState([]);
  const [items, setItems] = useState([]);
  const [allIdsChecked, setAllIdsChecked] = useState(false);
  const { t } = useTranslation();
  const [blockLoading, setBlockLoading] = useState(false);
  const [activeItems, setActiveItems] = useState(
    props.item.products?.slice(0, +perPage)
  );

  useEffect(() => {
    setShowBtn(ids?.length > 0);
  }, [ids]);
  useEffect(() => {
    if (props.item?.products?.length > 0) setItems(props.item.products);
  }, [props?.item?.products]);
  useEffect(() => {
    setPage(1);
    setActiveItems(items?.slice(0, +perPage));
  }, [perPage]);

  useEffect(() => {
    setActiveItems([]);
    items?.length > 0
      ? setActiveItems(items?.slice(perPage * page - perPage, +perPage * page))
      : setActiveItems(
          props.item.products?.slice(perPage * page - perPage, +perPage * page)
        );
  }, [page]);

  const changePercent = (percentVal, items, itemCallback) => {
    Axios.put(`${API_URL}/admin/partner/${id}/product/reward`, {
      partnerProductIds: items || ids,
      percent: percentVal.toString(),
    }).then(function (response) {
      if (response) {
        callback();
        itemCallback && itemCallback();
      } else {
      }
    });
  };

  const searchHandle = (val) => {
    setCountry(val);

    const countriesObj = {
      ru: ruCountries,
      gb: enCountries,
      cn: cnCountries,
      es: esCountries,
    };
    let list = countriesObj[locale];
    let data = Object.entries(list).filter((el) =>
      el[1].toLowerCase().includes(val.toLowerCase())
    );
    let filteredList = item.products.filter((el) => {
      let countryItems = data.filter((countryItem) => {
        if (el.countryId === countryItem[0]) {
          return countryItem[0];
        }
        return null;
      });
      if (countryItems?.length > 0) {
        return el;
      }
      return null;
    });

    setPage(1);
    setTotal(filteredList?.length);
    setItems(filteredList);
    setActiveItems(filteredList.slice(perPage * 1 - perPage, +perPage * 1));
  };
  const setAllIds = () => {
    if (allIdsChecked) {
      setIds([]);
      setAllIdsChecked(false);
    } else {
      setIds(
        country?.length > 0
          ? items.map((el) => el.id)
          : props.item?.products.map((el) => el.id)
      );
      setAllIdsChecked(true);
    }
  };
  return (
      <CAccordionItem itemKey={index} setActiveKey={(key) => console.log(key)}>
        <div
          className={`partners__block ${blockLoading ? "with-preloader" : ""}`}
        >
          {" "}
          <div className="partners__header">
            <div className="partners__header-start">
              <CFormCheck
                button={{ color: "primary" }}
                id={`btn-check_${item.serviceUid}`}
                autoComplete="off"
                label={
                  allIdsChecked
                    ? t("table.deselect_all")
                    : t("table.choose_all")
                }
                defaultChecked
                onClick={() => {
                  setAllIds();
                }}
              />
              <div
                className={`bg bg-${item.serviceUid}`}
                alt={item.serviceName}
              />
              <span className="hidden_sm">{item.serviceName}</span>
              <div>
                <CInputGroup>
                  <CFormInput
                    placeholder="0-99"
                    type="number"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onChange={(e) => {
                      if (e.target.value.split(".")?.[1]?.length > 2) {
                        return;
                      }
                      const val = e.target.value
                        .replace("e", "")
                        .replace("е", "");
                      if (val >= 99.99) {
                        setPercent(99.99);
                      } else {
                        setPercent(e.target.value);
                      }
                    }}
                    value={percent}
                    min="1"
                    max="100"
                    style={{ minWidth: "150px" }}
                  />
                  {showBtn && (
                    <button
                      className="partners__body-button"
                      onClick={() =>
                        changePercent(percent, ids, () => {
                          setShowBtn(false);
                          setIds([]);
                        })
                      }
                    >
                      <CIcon icon={cilCheckCircle} />
                    </button>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="partners__header-end">
              <CInputGroup>
                <CFormInput
                  placeholder={t("world.choose")}
                  type="text"
                  onChange={(e) => {
                    searchHandle(e.target.value);
                  }}
                  value={country}
                  min="1"
                  max="100"
                />

                <CInputGroupText>
                  <CIcon icon={cilSearch} />
                </CInputGroupText>
              </CInputGroup>
            </div>
          </div>
          <CAccordionHeader
            onClick={() => {
              if (openedItems != index) {
                setOpenedItems(index);
              } else {
                setOpenedItems(null);
              }
            }}
          ></CAccordionHeader>
          <CAccordionBody>
            <div className="partners__body">
              {activeItems?.length > 0 &&
                items?.length > 0 &&
                activeItems.map((el) => {
                  return (
                    <ProductItemLine
                      el={el}
                      changePercent={changePercent}
                      ids={ids}
                      key={el.id}
                      setIds={setIds}
                      percent={el.percent}
                    />
                  );
                })}
            </div>

            <TablePagination
              page={page}
              setPage={setPage}
              total={total}
              perPage={perPage}
              showAll
              needAll
              setPerPage={setPerPage}
            />
          </CAccordionBody>
        </div>
      </CAccordionItem>
  );
}

export default PartnersProductItem;
