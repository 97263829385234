import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { userFormConstructor } from "./constructor";
import { generateBoolData, generateInfo } from "./generateDefaultProps";
import { generateRoles } from "./cells/boolean";
import { CButton } from "@coreui/react";
import { isEqual } from "lodash";
import BalanceModal from "./balanceModal";

function UserForm(props) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userData, setUserData] = useState([]);
  const [defaultUserData, setDefaultUserData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setUserRoles(generateInfo(props.user));
    setUserData(generateBoolData(props.user));
    setDefaultUserData(generateBoolData(props.user));
  }, [props.user]);
  return (
    <>
      <div className="user-form__wrapper">
        <div className="user-form">
          {userData?.map((data) =>
            userFormConstructor(
              data,
              t,
              props.readOnly,
              props.user,
              userData,
              setUserData,
              {
                show: show,
                setShow: setShow,
                callback: () => props.changeBalance(),
              }
            )
          )}
        </div>
      </div>
      <div className="user-form" style={{ marginTop: "30px" }}>
        {userRoles?.length > 0 && props.admin && (
          <>
            {userRoles?.map((role) =>
              generateRoles(
                role,
                isLoading,
                setLoading,
                props.callback,
                props.id,
                t
              )
            )}
          </>
        )}
      </div>
      {props.changeBalance && (
        <BalanceModal
          show={show}
          setShow={setShow}
          value={userData.filter((el) => el.name == "balance")?.[0]?.value}
          callback={(val) => props.changeBalance(val)}
        />
      )}
    </>
  );
}

export default UserForm;
