export const generateUserInfo = (newData, data) => {
  Object.entries(data).map((el) => {
    if (typeof el[1] === "object" && !Array.isArray(el[1]) && el[1] !== null) {
      Object.entries(el[1]).map((item) => {
        newData[item[0]] = item[1];
      });
    } else {
      newData[el[0]] = el[1];
    }
  });
  return newData
};
