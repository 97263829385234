import AdminMainPage from "pages/AdminMainPage";
import AuthPage from "pages/AuthPage";
import MainPage from "pages/MainPage";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_URL } from "utils/settings";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AddBalancePage from "pages/AddBalancePage";
import BalanceHistoryPage from "pages/BalanceHistory";
import NotFound from "pages/NotFound";
import { UserContext } from "./contexts";
import SettingsPage from "pages/SettingsPage";
import LogsPage from "pages/LogsPage";
import PanelRulesPage from "pages/PanelRules";
import RulesPage from "components/RulesPage";
import AdminStatsPage from "pages/AdminStatsPage";
import AdminUsersPage from "pages/AdminStatsPage/users";
import AdminSettingsPage from "pages/AdminSettingsPage";
import AdminUserPage from "pages/AdminUserPage";
import AdminPromoPage from "pages/AdminPromoPage";
import PartnersProductsPage from "pages/PartnersProductsPage";
import AdminPartnersPage from "pages/AdminPartnersPage";
import InspectorLogsPage from "pages/LogsPage/inspectorLogsPage";
import ContactsPage from "pages/ContactsPage";
import AdminPaymentsPage from "pages/AdminPaymentsPage";

function WithToken(props) {
  const { children } = props;
  if (!window.location.href.includes("auth") && localStorage.getItem("token")) {
    Axios.defaults.headers.common[
      "authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }
  return <>{children}</>;
}
function AppRouter() {
  const [authorized, setAuthorized] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [user, setUser] = useState(null);
  const search = new URLSearchParams(window.location.search).get("email_token");
  useEffect(() => {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    if (!search) {
      Axios.get(`${API_URL}/user`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(function (response) {
          setIsFetched(true);
          if (response.data.email) {
            // setMenuList(response.data.items)
            setAuthorized(true);
            setUser(response.data);
          } else {
            setAuthorized(false);
          }
        })
        .catch((error) => {
          setIsFetched(true);
          setAuthorized(false);
          localStorage.removeItem("token");
        });
    } else {
      Axios.post(`${API_URL}/confirm/email`, {
        token: search,
      })
        .then(function (response, error) {
          if (response?.data?.authToken) {
            localStorage.setItem("token", response.data.authToken);
          }
          window.location.href = "/panel/main?emailVerified=true";
        })
        .catch((error) => {});
    }
  }, []);
  const getUser = () => {
    Axios.get(`${API_URL}/user`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(function (response) {
      if (response.data.email) {
        setUser(response.data);
      } else {
        setAuthorized(false);
      }
    });
  };

  return (isFetched && window.location.href.includes("panel")) ||
    !window.location.href.includes("panel") ? (
    <UserContext.Provider
      value={{
        user: user,
        updateUser: (val) => getUser(),
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/auth">
            <AuthPage />
          </Route>

          <WithToken>
            <Route exact path="/">
              <MainPage authorized={authorized} />
            </Route>
            <Route exact path="/rules">
              <RulesPage authorized={authorized} />
            </Route>
            <Route exact path="/contacts">
              <ContactsPage authorized={authorized} />
            </Route>
            <Route exact path="/admin/logs">
              <LogsPage />
            </Route>
            <Route exact path="/admin/coupons">
              <AdminPromoPage />
            </Route>
            <Route exact path="/admin/stats/orders">
              <AdminStatsPage />
            </Route>
            <Route exact path="/admin/stats/users">
              <AdminUsersPage />
            </Route>
            <Route exact path="/admin/stats/payments">
              <AdminPaymentsPage />
            </Route>
            <Route exact path="/admin/partners">
              <AdminPartnersPage />
            </Route>
            <Route exact path="/admin/users/:id">
              <AdminUserPage />
            </Route>
            <Route exact path="/admin/users/:id/products">
              <PartnersProductsPage />
            </Route>
            <Route exact path="/admin/rules">
              <PanelRulesPage />
            </Route>
            <Route exact path="/inspector/logs">
              <InspectorLogsPage />
            </Route>
            <Route exact path="/admin/settings">
              <AdminSettingsPage />
            </Route>
            <Route exact path="/panel/main">
              <AdminMainPage />
            </Route>
            <Route exact path="/panel/pay">
              <AddBalancePage />
            </Route>
            {/* <Route exact path="/panel/settings/:id">
              <SettingsPage />
            </Route> */}
            <Route exact path="/panel/settings">
              <SettingsPage />
            </Route>
            <Route exact path="/panel/balance">
              <BalanceHistoryPage />
            </Route>
            <Route exact path="/panel/rules">
              <PanelRulesPage />
            </Route>
            <Route exact path="/payments/success">
              <Redirect to="/panel/balance?success=true" />
            </Route>
            <Route exact path="/payments/cancel">
              <Redirect to="/panel/balance?failed=true" />
            </Route>
            <Route path="*">
              <NotFound authorized={authorized} />
            </Route>
          </WithToken>
        </Switch>
      </Router>
    </UserContext.Provider>
  ) : (
    <></>
  );
}

export default AppRouter;
