import React, { useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { CButton, CCol, CFormLabel, CRow, CSpinner } from "@coreui/react";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import Axios from "axios";
import { API_URL } from "utils/settings";
import ExportExcel from "components/ExcelExport";
import CsvDownloader from "react-csv-downloader";
import exportJson from "components/ExportJson";
import _ from "lodash";
import ToastComponent from "components/ToastComponent";
function ValidateIPaddress(ipaddress) {
  const expression =
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

  if (expression.test(ipaddress)) {
    return true;
  }
  return false;
}
function PhonesFromIp({ method = "/admin/log/email/ip" }) {
  const [ips, setIps] = useState([]);
  const [ipsFrom, setIpsFrom] = useState([]);
  const [ipsTo, setIpsTo] = useState([]);
  const [phones, setPhones] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const getEmails = () => {
    const params = {};
    if (ips?.length > 0) {
      params["ipList"] = ips;
    }
    if (ipsFrom?.length > 0) {
      params["ipFromList"] = ipsFrom;
    }
    if (ipsTo?.length > 0) {
      params["ipToList"] = ipsTo;
    }

    if (ips?.length == 0 && ipsFrom?.length == 0 && ipsTo?.length == 0) {
      toast.error(t("ip.ip_error"));
      return;
    }
    if (ipsFrom?.length !== ipsTo?.length) {
      toast.error(t("ip.interval_error"));
      return;
    }
    setLoading(true);
    setPhones([]);
    Axios.get(`${API_URL}${method}`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.phones?.length > 0) {
          setPhones(
            response.data.phones.map((el) => {
              return { phone: el };
            })
          );
        } else {
          ToastComponent("error", t("phone.not_found"));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const tagsHandler = (value) => {
    let newValue = [];
    value.forEach((el) => {
      let splitted = el.split(" ");
      if (splitted?.length === 1) {
        ValidateIPaddress(el)
          ? newValue.push(el)
          : toast.warning(`${el} ${t("common.not_valid")}`);
      } else {
        splitted.forEach((item) => {
          if (item?.length > 0) {
            if (ValidateIPaddress(item)) {
              newValue.push(item);
              return;
            } else {
              toast.warning(`${item} ${t("common.not_valid")}`);
              return;
            }
          } else {
            return;
          }
        });
      }
    });
    return newValue;
  };

  return (
    <div className="shadow-block">
      <h6>{t("ip.ip_heading2")}</h6>
      <CCol>
        <CFormLabel>{t("ip.ips")}</CFormLabel>
        <TagsInput
          value={ips}
          onChange={(value) => {
            setIps(tagsHandler(value));
          }}
          onBlur={(event) => {
            if (event.target.value?.length > 1) {
              setIps(tagsHandler([...ips, ...event.target.value.split(" ")]));
              event.target.value = "";
            }
          }}
          name="ips"
          separators={["Enter", " ", "Tab"]}
        />
      </CCol>
      <br />
      <div className="ips-interval">
        <CCol>
          <CFormLabel>{t("ip.ips_from")}</CFormLabel>
          <TagsInput
            value={ipsFrom}
            onChange={(value) => {
              setIpsFrom(tagsHandler(value));
            }}
            onBlur={(event) => {
              if (event.target.value?.length > 1) {
                setIpsFrom(
                  tagsHandler([...ipsFrom, ...event.target.value.split(" ")])
                );
                event.target.value = "";
              }
            }}
            name="ipsFrom"
            separators={["Enter", " ", "Tab"]}
          />
        </CCol>
        <span className="ips-separator">—</span>
        <CCol>
          <CFormLabel>{t("ip.ips_to")}</CFormLabel>
          <TagsInput
            value={ipsTo}
            onChange={(value) => {
              setIpsTo(tagsHandler(value));
            }}
            onBlur={(event) => {
              if (event.target.value?.length > 1) {
                setIpsTo(
                  tagsHandler([...ipsTo, ...event.target.value.split(" ")])
                );
                event.target.value = "";
              }
            }}
            name="ipsTo"
            separators={["Enter", " ", "Tab"]}
          />
        </CCol>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <CButton onClick={() => getEmails()} disabled={loading}>
          {loading && (
            <>
              <CSpinner size="sm" color="white" />
              &nbsp;
            </>
          )}
          {t("ip.get_data")}
        </CButton>
        {phones?.length > 0 && (
          <div className="ips-buttons">
            <CsvDownloader
              filename="Phones"
              extension=".csv"
              separator=";"
              wrapColumnChar="'"
              columns={[
                {
                  id: "phone",
                  displayName: "Phone",
                },
              ]}
              datas={phones}
            >
              <CButton variant="outline">{t("ip.export_csv")}</CButton>
            </CsvDownloader>
            <ExportExcel excelData={phones} fileName="phones" />
            <CButton
              variant="outline"
              onClick={() => exportJson(phones, "phones.json")}
            >
              {t("ip.export_json")}
            </CButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default PhonesFromIp;
