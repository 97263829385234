import React, { useState, useEffect, useContext, useRef } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";

import OrderTable from "./OrderTable";
import { defaultEndDate, defaultStartDate, useLocalStorage } from "utils/utils";
import ServicesTable from "./ServicesTable";
import Axios from "axios";
import { API_URL } from "utils/settings";
import ChooseCountries from "components/ChooseCountries";
import { UserContext } from "core/contexts";
import _ from "lodash";
import { CFormSelect } from "@coreui/react";

import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ToastComponent from "components/ToastComponent";
import Notifier from "components/Notifier";
import CsvDownloader from "react-csv-downloader";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import ServicesList from "./ServiceList";
function AdminMainPage(props) {
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const ref = useRef(null);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [orders, setOrders] = useState([]);
  const [countries, setCountries] = useState([]);
  const [services, setServices] = useState([]);
  const [total, setTotal] = useState(0);
  const [notify, setNotify] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [needFetch, setNeedFetch] = useState(true);

  const [selectedCountries, setSelectedCountries] = useLocalStorage(
    "selectedCountries",
    null
  );
  const [localActiveKey, setLocalActiveKey] = useLocalStorage(
    "localActiveKey",
    null
  );
  const [adminActiveInterval, setAdminActiveInterval] = useLocalStorage(
    "adminActiveOrdersInterval",
    10000
  );
  const [perPage, setPerPage] = useState(20);
  const [activeCountry, setActiveCountry] = useState("");
  const [filters, setFilters] = useState([
    {
      name: "statuses",
      items: [
        "all",
        "new",
        "waiting_phone",
        "waiting_sms",
        "finished",
        "canceled",
        "declined",
        "no_numbers",
      ],
      type: "multiselect",
      value: null,
    },
    {
      name: "dateFrom",
      type: "date",
      value: defaultStartDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);
  const [orderSorting, setOrderSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
    {
      name: "status",
      label: t("sorting.status"),
      value: null,
    },
  ]);
  const [activeSorting, setActiveSorting] = useState(null);
  let url = new URL(window.location);
  let params = new URLSearchParams(url.search);
  const location = useLocation();
  const history = useHistory();
  const [defaultInterval, setDefaultInterval] = useState(adminActiveInterval);
  useEffect(() => {
    setAdminActiveInterval(defaultInterval);
    const interval = setInterval(() => {
      getOrders(page, perPage, filters, false);
    }, defaultInterval);
    return () => clearInterval(interval);
  }, [defaultInterval, perPage, page, filters, activeSorting]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("emailVerified")) {
      ToastComponent("success", t("register.email"));
      queryParams.delete("emailVerified");
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);
  const getNotifications = () => {
    Axios.get(`${API_URL}/user/notifier`).then(function (response) {
      if (response.data.data) {
        setNotify(response.data.data);
      }
    });
  };
  useEffect(() => {
    setLocalActiveKey(activeCountry);
  }, [activeCountry]);
  const getProducts = (country) => {
    if (!country) return;
    const params = {
      country: country,
    };

    Axios.get(`${API_URL}/product/list`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setServices(response.data.data.filter((el) => el.available));
        } else {
          setServices([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (!selectedCountries) {
      let obj = {
        id: "ru",
        name: "russian federation",
        alpha2: "ru",
        pathWorld: "europe",
        priority: 0,
      };
      setSelectedCountries({
        0: obj,
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
      });
      setActiveCountry({
        id: "ru",
        name: "russian federation",
        alpha2: "ru",
        pathWorld: "europe",
        priority: 0,
      });
    } else {
      let items = Object.entries(selectedCountries).filter(
        (el) => el[1].alpha2 && el[1]
      );
      if (items.length > 0) {
        localActiveKey
          ? setActiveCountry(localActiveKey)
          : setActiveCountry(items[0][1]);
      }
    }
    getNotifications();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const getOrders = (page, perPage, filters, file = false) => {
    setIsLoading(true);
    let params = {
      isPaginated: !file ? 1 : 0,
    };
    if (activeSorting !== "sorting.null" && activeSorting !== null) {
      params["sort"] = [activeSorting];
    }
    if (!file) {
      params["page"] = page;
      params["perPage"] = perPage;
    }
    filters.forEach((el) => {
      if ((el.value && el.value !== "all") || el.type == "date") {
        if (el.type == "multiselect") {
          params = { ...params, [el.name]: [el.value] };
          return;
        }
        if (el.type == "date") {
          if (!el.value) {
            return;
          }
          let date = new Date(el.value);
          // date.setDate(date.getDate() + 1);
          params = {
            ...params,
            [el.name]: date.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    let dateTo = new Date(params.dateTo?.split("T")?.[0]);
    let dateFrom = new Date(params.dateFrom?.split("T")?.[0]);

    const differenceInMilliseconds = dateTo.getTime() - dateFrom.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (differenceInDays > 14 && !file) {
      ToastComponent("info", t("filters.date_two_week"));
      setIsLoading(false);
      return;
    }
    if (!needFetch) {
      return;
    }
    Axios.get(`${API_URL}/order`, {
      params: params,
    })
      .then(function (response) {
        setIsLoading(false);
        setNeedFetch(true);
        if (response.data.data?.length > 0 && file) {
          const columns = Object.keys(response.data.data[0]);
          const fileType = "text/csv;charset=utf-8";
          const fileExtension = ".csv";

          const ws = XLSX.utils.json_to_sheet(
            _.flatten(
              response.data.data.map((el) => {
                return {
                  id: el.id,
                  [t("table.amount")]: el.amount
                    ? new Intl.NumberFormat("ru-RU", {
                        style: "currency",
                        currency: user?.currency || "USD",
                      }).format(el.amount || 0)
                    : 0,
                  [t("table.country")]: el.countryAlpha2,
                  [t("table.service")]: el.serviceName,
                  [t("table.status")]: t(`table.${el.status}`),
                  [t("common.phone")]: `${el.phone}`,
                  [t("table.sms_content")]: el.smsContent,
                  [t("table.date")]:
                    new Date(el.createdAt).toLocaleString().split(",")?.[0] ||
                    "—",
                };
              })
            )
          );
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "csv",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, "orders" + fileExtension);
        }
        if (response.data.data?.length > 0 && !file) {
          setOrders(response.data.data);
          setTotal(response.data.total);
        } else if (response?.data?.data?.length == 0 && !file) {
          setOrders([]);
          setTotal(0);
        } else if (response?.data?.data?.length == 0 && file) {
          ToastComponent("error", t("table.notFound"));
        }
      })
      .catch((error) => {
        setNeedFetch(false);
        setIsLoading(false);
      });
  };
  const getCountries = () => {
    Axios.get(`${API_URL}/country`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setCountries(response.data.data);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getCountries();
    getProducts();
  }, []);
  return (
    <>
      <div className={`page ${expanded ? "expanded" : ""}`}>
        <Sidebar />

        <div className="container container-panel">
          <div className="panel__content">
            {notify?.length > 0 && <Notifier items={notify} />}
            <div className="columns columns_md columns_sm">
              <div className="columns__item shadow-block services-table">
                <ServicesList
                  getOrders={getOrders}
                  page={page}
                  perPage={perPage}
                  filters={filters}
                  updateUser={userContext.updateUser}
                />
                {/* {selectedCountries && (
                  <ChooseCountries
                    countries={countries}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    setActiveCountry={setActiveCountry}
                    activeCountry={activeCountry}
                  />
                )} */}
                {/* <div className="inset-shadow">
                  <ServicesTable
                    orders={orders}
                    getOrders={getOrders}
                    page={page}
                    perPage={perPage}
                    filters={filters}
                    services={services}
                    updateUser={userContext.updateUser}
                  /> */}
                {/* </div> */}
              </div>
              <div className="columns__item shadow-block orders-table">
                <h6 className="heading_with_select">
                  <div>
                    {t("heading.order")} — {total}
                  </div>
                  <div className="heading__select">
                    <b>{t("heading.interval")}</b>
                    <CFormSelect
                      aria-label="Set currency"
                      placeholder="Set currency"
                      value={defaultInterval}
                      onChange={(event) =>
                        setDefaultInterval(event.target.value)
                      }
                      options={[
                        { label: "5", value: "5000" },
                        { label: "10", value: "10000" },
                        { label: "15", value: "15000" },
                        { label: "20", value: "20000" },
                      ]}
                    />
                  </div>
                </h6>

                <OrderTable
                  orders={orders}
                  setOrders={setOrders}
                  getOrders={getOrders}
                  total={total}
                  setTotal={setTotal}
                  currency={user?.currency}
                  page={page}
                  sorting={orderSorting}
                  activeSorting={activeSorting}
                  setSorting={setActiveSorting}
                  perPage={perPage}
                  // title={`${t("heading.order")} — ${total}`}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  needCancel
                  filters={filters}
                  setFilters={setFilters}
                  updateUser={userContext.updateUser}
                  needResend
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminMainPage;
