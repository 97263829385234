import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { UserContext } from "core/contexts";
// import UsersTable from "./usersTable";
import { defaultEndDate, defaultStartDate, useLocalStorage } from "utils/utils";
import AdminPaymentsTable from "./adminPaymentsTable";

function AdminPaymentsPage(props) {
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [activeSorting, setActiveSorting] = useState(null);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [sorting, setSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
    {
        name: "status",
        label: t("sorting.status"),
        value: null,
      },
  ]);
  const [filters, setFilters] = useState([
    {
      name: "statuses",
      items: ["all", "waiting", "approved", "declined", "canceled"],
      type: "multiselect",
      value: null,
    },
    {
      name: "dateFrom",
      type: "date",
      value: defaultStartDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);
  const user = userContext.user;

  const getPayments = (pageVal, perPageVal, filtersVal, activeSortingVal) => {
    setLoading(true);
    let params = {
      page: pageVal,
      perPage: perPageVal,
    };
    if (activeSortingVal !== "sorting.null" && activeSortingVal !== null) {
      params["sort"] = [activeSortingVal];
    }
    filtersVal?.forEach((el) => {
      if (el.value && el.value !== "all") {
        if (el.type == "multiselect") {
          params = { ...params, [el.name]: [el.value] };
          return;
        }
        if (el.type == "date") {
          let date = new Date(el.value);
          // date.setDate(date.getDate() + 1);
          params = {
            ...params,
            [el.name]: date.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    Axios.get(`${API_URL}/admin/payment/all`, {
      params: params,
    })
      .then(function (response) {
        setLoading(false);
        if (response.data?.data?.length > 0) {
          setPayments(response.data.data);
          setTotal(response.data.total);
        } else {
          setPayments([]);
          setTotal(0);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className={`page ${expanded ? "expanded" : ""}`}>
        <Sidebar />
        <div className="container container-panel">
          <div className="panel__content">
            <div className="balance admin-settings ">
              <h3>{t("heading.payments")}</h3>
            </div>
            <div className="stats-tables user-stats">
              {user?.currency && (
                <AdminPaymentsTable
                  payments={payments}
                  setPayments={setPayments}
                  getPayments={getPayments}
                  total={total}
                  setTotal={setTotal}
                  sorting={sorting}
                  filters={filters}
                  setFilters={setFilters}
                  activeSorting={activeSorting}
                  setSorting={setActiveSorting}
                  page={page}
                  currency={user?.currency}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  isLoading={loading}
                />
              )}
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminPaymentsPage;
