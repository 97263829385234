export const enCountries = {
    "om": "oman",
    "tg": "togo",
    "pf": "french polynesia",
    "af": "afghanistan",
    "tf": "french southern territories",
    "na": "namibia",
    "tk": "tokelau",
    "dj": "djibouti",
    "al": "albania",
    "nr": "nauru",
    "to": "tonga",
    "aq": "antarctica",
    "ga": "gabon",
    "dz": "algeria",
    "ge": "georgia",
    "np": "nepal",
    "tt": "trinidad and tobago",
    "gm": "gambia",
    "as": "eastern samoa",
    "nl": "netherlands",
    "ae": "united arab emirates",
    "an": "netherlands antilles",
    "ps": "state of palestine",
    "ad": "andorra",
    "de": "germany",
    "tn": "tunisia",
    "aw": "aruba",
    "ao": "angola",
    "tr": "turkey",
    "tm": "turkmenistan",
    "ag": "antigua and barbuda",
    "nc": "new caledonia",
    "tc": "turks and caicos islands",
    "tv": "tuvalu",
    "az": "azerbaijan",
    "ar": "argentina",
    "gh": "ghana",
    "ug": "uganda",
    "au": "australia",
    "gi": "gibraltar",
    "vu": "vanuatu",
    "ua": "ukraine",
    "mk": "republic of north macedonia",
    "at": "austria",
    "ki": "kiribati",
    "nz": "new zealand",
    "bs": "bahamas",
    "gr": "greece",
    "ni": "nicaragua",
    "bh": "bahrain",
    "gl": "greenland",
    "bd": "bangladesh",
    "ne": "niger",
    "eg": "egypt",
    "am": "armenia",
    "bb": "barbados",
    "gd": "grenada",
    "ng": "nigeria",
    "be": "belgium",
    "gp": "guadeloupe",
    "nu": "niue",
    "gb": "united kingdom of great britain and northern ireland",
    "bm": "bermuda",
    "gu": "guam",
    "nf": "norfolk island",
    "gg": "guernsey",
    "bt": "bhutan",
    "gt": "guatemala",
    "je": "jersey",
    "im": "isle of man",
    "no": "norway",
    "tz": "tanzania",
    "bo": "bolivia",
    "gn": "guinea",
    "mp": "northern mariana islands",
    "um": "united states minor outlying islands",
    "ba": "bosnia and herzegovina",
    "fm": "micronesia",
    "bw": "botswana",
    "gy": "guyana",
    "mh": "marshall islands",
    "us": "united states of america",
    "pw": "palau",
    "bv": "bouvet island",
    "pk": "pakistan",
    "br": "brazil",
    "ht": "haiti",
    "hm": "heard island and mcdonald islands",
    "pa": "panama",
    "va": "holy see",
    "vi": "virgin islands",
    "bz": "belize",
    "hn": "honduras",
    "io": "british territories",
    "pg": "papua new guinea",
    "bf": "burkina faso",
    "hk": "hong kong",
    "py": "paraguay",
    "sb": "solomon islands",
    "uy": "uruguay",
    "vg": "virgin islands, british",
    "hu": "hungary",
    "pe": "peru",
    "uz": "uzbekistan",
    "ve": "venezuela",
    "bn": "brunei",
    "is": "iceland",
    "ph": "philippines",
    "bg": "bulgaria",
    "in": "india",
    "pn": "pitcairn",
    "mm": "myanmar",
    "id": "indonesia",
    "pl": "poland",
    "bi": "burundi",
    "ir": "iran",
    "pt": "portugal",
    "wf": "wallis and futuna",
    "by": "belarus",
    "iq": "iraq",
    "gw": "guinea-bissau",
    "tp": "east timor",
    "ws": "samoa",
    "kh": "cambodia",
    "ie": "ireland",
    "pr": "puerto rico",
    "ye": "yemen",
    "cm": "cameroon",
    "il": "israel",
    "qa": "qatar",
    "ca": "canada",
    "it": "italy",
    "re": "réunion",
    "zm": "zambia",
    "tl": "timor-leste",
    "ci": "côte d'ivoire",
    "ro": "romania",
    "ru": "russian federation",
    "cv": "cape verde",
    "jm": "jamaica",
    "rw": "rwanda",
    "ky": "cayman islands",
    "jp": "japan",
    "cf": "central african republic",
    "kz": "kazakhstan",
    "sh": "saint helena",
    "lk": "sri lanka",
    "jo": "jordan",
    "kn": "saint kitts and nevis",
    "td": "chad",
    "ke": "kenya",
    "ai": "anguilla",
    "lc": "saint lucia",
    "cl": "chile",
    "kp": "democratic people's republic of korea",
    "kr": "korea",
    "pm": "saint pierre and miquelon",
    "cn": "china",
    "tw": "taiwan",
    "kw": "kuwait",
    "vc": "saint vincent and the grenadines",
    "kg": "kyrgyzstan",
    "cx": "christmas islands",
    "la": "lao people's democratic republic",
    "sm": "san marino",
    "cc": "cocos islands",
    "lb": "lebanon",
    "st": "sao tome and principe",
    "co": "colombia",
    "ls": "lesotho",
    "sa": "saudi arabia",
    "lv": "latvia",
    "km": "comoros",
    "lr": "liberia",
    "sn": "senegal",
    "yt": "mayotte",
    "rs": "serbia",
    "cg": "congo",
    "ly": "libya",
    "sc": "seychelles",
    "cd": "democratic republic of the congo",
    "li": "liechtenstein",
    "sl": "sierra leone",
    "ck": "cook islands",
    "lt": "lithuania",
    "lu": "luxembourg",
    "cr": "costa rica",
    "mo": "macao",
    "sg": "singapore",
    "hr": "croatia",
    "sk": "slovakia",
    "cu": "cuba",
    "vn": "viet nam",
    "si": "slovenia",
    "mg": "madagascar",
    "so": "somalia",
    "cy": "cyprus",
    "mw": "malawi",
    "za": "south africa",
    "my": "malaysia",
    "cz": "czechia",
    "bj": "benin",
    "zw": "zimbabwe",
    "mv": "maldives",
    "dk": "denmark",
    "ml": "mali",
    "dm": "dominica",
    "es": "spain",
    "do": "dominican republic",
    "mt": "malta",
    "ec": "ecuador",
    "mq": "martinique",
    "eh": "western sahara",
    "sv": "el salvador",
    "mr": "mauritania",
    "mu": "mauritius",
    "sd": "sudan",
    "gq": "equatorial guinea",
    "mx": "mexico",
    "sr": "suriname",
    "et": "ethiopia",
    "er": "eritrea",
    "sj": "svalbard and jan mayen",
    "ee": "estonia",
    "fo": "faroe islands",
    "mc": "monaco",
    "sz": "eswatini",
    "fk": "falkland islands",
    "gs": "south georgia and the south sandwich islands",
    "mn": "mongolia",
    "se": "sweden",
    "fj": "fiji",
    "md": "moldova",
    "me": "montenegro",
    "ms": "montserrat",
    "ch": "switzerland",
    "fi": "finland",
    "ax": "åland islands",
    "ma": "morocco",
    "sy": "syrian arab republic",
    "fr": "france",
    "tj": "tajikistan",
    "mz": "mozambique",
    "th": "thailand",
    "gf": "french guiana",
}

export const ruCountries = {
    "af": "Афганистан",
    "al": "Албания",
    "aq": "Антарктида",
    "dz": "Алжир",
    "as": "Восточное Самоа",
    "ad": "Андорра",
    "ao": "Ангола",
    "ag": "Антигуа и Барбуда",
    "az": "Азербайджан",
    "ar": "Аргентина",
    "au": "Австралия",
    "at": "Австрия",
    "bs": "Багамы",
    "bh": "Бахрейн",
    "bd": "Бангладеш",
    "am": "Армения",
    "bb": "Барбадос",
    "be": "Бельгия",
    "bm": "Бермуды",
    "bt": "Бутан",
    "bo": "Боливия",
    "ba": "Босния и Герцеговина",
    "bw": "Ботсвана",
    "bv": "Буве",
    "br": "Бразилия",
    "bz": "Белиз",
    "io": "Британ. Террит.",
    "sb": "Соломоновы О-ва",
    "vg": "Виргин. О-ва, Британские",
    "bn": "Бруней",
    "bg": "Болгария",
    "mm": "Мьянма",
    "bi": "Бурунди",
    "by": "Беларусь",
    "kh": "Камбоджа",
    "cm": "Камерун",
    "ca": "Канада",
    "cv": "Кабо-верде",
    "ky": "Кайман",
    "cf": "Центр. - Афр. Республика",
    "lk": "Шри-ланка",
    "td": "Чад",
    "cl": "Чили",
    "cn": "Китай",
    "tw": "Тайвань",
    "cx": "О-в Рождества",
    "cc": "Кокосовые О-ва",
    "co": "Колумбия",
    "km": "Коморы",
    "yt": "Майотта",
    "cg": "Конго",
    "cd": "Конго",
    "ck": "О-ва Кука",
    "cr": "Коста-рика",
    "hr": "Хорватия",
    "cu": "Куба",
    "cy": "Кипр",
    "cz": "Чехия",
    "bj": "Бенин",
    "dk": "Дания",
    "dm": "Доминика",
    "do": "Доминиканская Республика",
    "ec": "Эквадор",
    "sv": "Эль-сальвадор",
    "gq": "Экваториальная Гвинея",
    "et": "Эфиопия",
    "er": "Эритрея",
    "ee": "Эстония",
    "fo": "Фарерские О-ва",
    "fk": "Фолклендские О-ва",
    "gs": "Южная Джорджия и Южные Сандвичевы Острова",
    "fj": "Фиджи",
    "fi": "Финляндия",
    "ax": "Эландские Острова",
    "fr": "Франция",
    "gf": "Гвиана",
    "pf": "Французская Полинезия",
    "tf": "Фр. Южные Территории",
    "dj": "Джибути",
    "ga": "Габон",
    "ge": "Грузия",
    "gm": "Гамбия",
    "ps": "Палестинская Территория, Оккупированная",
    "de": "Германия",
    "gh": "Гана",
    "gi": "Гибралтар",
    "ki": "Кирибати",
    "gr": "Греция",
    "gl": "Гренландия",
    "gd": "Гренада",
    "gp": "Гваделупа",
    "gu": "Гуам",
    "gt": "Гватемала",
    "gn": "Гвинея",
    "gy": "Гайана",
    "ht": "Гаити",
    "hm": "Херд и Макдональд",
    "va": "Ватикан",
    "hn": "Гондурас",
    "hk": "Гонконг",
    "hu": "Венгрия",
    "is": "Исландия",
    "in": "Индия",
    "id": "Индонезия",
    "ir": "Иран",
    "iq": "Ирак",
    "ie": "Ирландия",
    "il": "Израиль",
    "it": "Италия",
    "ci": "Кот Д'ивуар",
    "jm": "Ямайка",
    "jp": "Япония",
    "kz": "Казахстан",
    "jo": "Иордания",
    "ke": "Кения",
    "kp": "Корея [КНДР)",
    "kr": "Корея",
    "kw": "Кувейт",
    "kg": "Кыргызстан",
    "la": "Лаос",
    "lb": "Ливан",
    "ls": "Лесото",
    "lv": "Латвия",
    "lr": "Либерия",
    "ly": "Ливия",
    "li": "Лихтенштейн",
    "lt": "Литва",
    "lu": "Люксембург",
    "mo": "Макао",
    "mg": "Мадагаскар",
    "mw": "Малави",
    "my": "Малайзия",
    "mv": "Мальдивы",
    "ml": "Мали",
    "mt": "Мальта",
    "mq": "Мартиника",
    "mr": "Мавритания",
    "mu": "Маврикий",
    "mx": "Мексика",
    "mc": "Монако",
    "mn": "Монголия",
    "md": "Молдова",
    "me": "Черногория",
    "ms": "Монтсеррат",
    "ma": "Марокко",
    "mz": "Мозамбик",
    "om": "Оман",
    "na": "Намибия",
    "nr": "Науру",
    "np": "Непал",
    "nl": "Нидерланды",
    "an": "Нидерландские Антилы",
    "aw": "Аруба",
    "nc": "Новая Каледония",
    "vu": "Вануату",
    "nz": "Новая Зеландия",
    "ni": "Никарагуа",
    "ne": "Нигер",
    "ng": "Нигерия",
    "nu": "Ниуэ",
    "nf": "Норфолк",
    "no": "Норвегия",
    "mp": "Марианские Острова",
    "um": "Малые Тихоок. Острова (США)",
    "fm": "Микронезия",
    "mh": "Маршалловы Острова",
    "pw": "Палау",
    "pk": "Пакистан",
    "pa": "Панама",
    "pg": "Папуа - Новая Гвинея",
    "py": "Парагвай",
    "pe": "Перу",
    "ph": "Филиппины",
    "pn": "Питкэрн",
    "pl": "Польша",
    "pt": "Португалия",
    "gw": "Гвинея-бисау",
    "tp": "Восточный Тимор",
    "pr": "Пуэрто-рико",
    "qa": "Катар",
    "re": "Реюньон",
    "ro": "Румыния",
    "ru": "Россия",
    "rw": "Руанда",
    "sh": "Святая Елена",
    "kn": "Сент-китс и Невис",
    "ai": "Ангилья",
    "lc": "Сент-люсия",
    "pm": "Сен-пьер и Микелон",
    "vc": "Сент-винсент и Гренадины",
    "sm": "Сан-марино",
    "st": "Сан-томе и Принсипи",
    "sa": "Саудовская Аравия",
    "sn": "Сенегал",
    "rs": "Сербия",
    "sc": "Сейшелы",
    "sl": "Сьерра-леоне",
    "sg": "Сингапур",
    "sk": "Словакия",
    "vn": "Вьетнам",
    "si": "Словения",
    "so": "Сомали",
    "za": "Южная Африка",
    "zw": "Зимбабве",
    "es": "Испания",
    "eh": "Западная Сахара",
    "sd": "Судан",
    "sr": "Суринам",
    "tr": "Турция",
    "xy": "Случайная страна",
    "ua": "Украина",
    "gb": "Великобритания",
    "se": "Швеция",
    "ch": "Швейцария",
    "mk": "Македония",
    "ae": "Объединённые Арабские Эмираты",
    "gg": "Гернси",
    "je": "Джерси",
    "im": "о-в Мэн",
    "sj": "Шпицберген и Ян-Майен",
    "tm": "Туркменистан",
    "uz": "Узбекистан",
    "ye": "Йемен",
    "tl": "Восточный Тимор",
    "tj": "Таджикистан",
    "th": "Тайланд",
    "sy": "Сирия",
    "tn": "Тунис",
    "tg": "Того",
    "ug": "Уганда",
    "tz": "Танзания",
    "bf": "Буркина-Фасо",
    "zm": "Замбия",
    "sz": "Эсватини",
    "tc": "Острова Теркс и Кайкос",
    "tt": "Тринидад и Тобаго",
    "uy": "Уругвай",
    "vi": "Американские Виргинские острова",
    "ve": "Венесуэла",
    "tk": "Токелау",
    "us": "Соединенные штаты Америки",
    "to": "Тонга",
    "tv": "Тувалу",
    "wf": "о-ва Уоллис и Футуна",
    "ws": "Самоа",
    "eg": "Египет",
}

export const cnCountries = {
    "ad": "安道尔",
    "ae": "阿拉伯联合酋长国",
    "af": "阿富汗",
    "ag": "安提瓜和巴布达",
    "ai": "安圭拉",
    "al": "阿尔巴尼亚",
    "am": "亚美尼亚",
    "an": "荷属安的列斯",
    "ao": "安哥拉",
    "aq": "南极洲",
    "ar": "阿根廷",
    "as": "美属萨摩亚",
    "at": "奥地利",
    "au": "澳大利亚",
    "aw": "阿鲁巴",
    "ax": "奥兰群岛",
    "az": "阿塞拜疆",
    "ba": "波斯尼亚和黑塞哥维那",
    "bb": "巴巴多斯",
    "bd": "孟加拉国",
    "be": "比利时",
    "bf": "布基纳法索",
    "bg": "保加利亚",
    "bh": "巴林",
    "bi": "布隆迪",
    "bj": "贝宁",
    "bm": "百慕大",
    "bn": "文莱",
    "bo": "玻利维亚",
    "br": "巴西",
    "bs": "巴哈马",
    "bt": "不丹",
    "bv": "布维岛",
    "bw": "博茨瓦纳",
    "by": "白俄罗斯",
    "bz": "伯利兹",
    "ca": "加拿大",
    "cc": "科科斯（基林）群岛",
    "cd": "刚果（金）",
    "cf": "中非共和国",
    "cg": "刚果（布）",
    "ch": "瑞士",
    "ci": "科特迪瓦",
    "ck": "库克群岛",
    "cl": "智利",
    "cm": "喀麦隆",
    "cn": "中国",
    "co": "哥伦比亚",
    "cr": "哥斯达黎加",
    "cu": "古巴",
    "cv": "佛得角",
    "cx": "圣诞岛",
    "cy": "塞浦路斯",
    "cz": "捷克",
    "de": "德国",
    "dj": "吉布提",
    "dk": "丹麦",
    "dm": "多米尼克",
    "do": "多米尼加共和国",
    "dz": "阿尔及利亚",
    "ec": "厄瓜多尔",
    "ee": "爱沙尼亚",
    "eg": "埃及",
    "eh": "西撒哈拉",
    "er": "厄立特里亚",
    "es": "西班牙",
    "et": "埃塞俄比亚",
    "fi": "芬兰",
    "fj": "斐济",
    "fk": "福克兰群岛",
    "fm": "密克罗尼西亚",
    "fo": "法罗群岛",
    "fr": "法国",
    "ga": "加蓬",
    "gb": "英国",
    "gd": "格林纳达",
    "ge": "格鲁吉亚",
    "gf": "法属圭亚那",
    "gg": "根西岛",
    "gh": "加纳",
    "gi": "直布罗陀",
    "gl": "格陵兰",
    "gm": "冈比亚",
    "gn": "几内亚",
    "gp": "瓜德罗普",
    "gq": "赤道几内亚",
    "gr": "希腊",
    "gs": "南乔治亚岛和南桑威奇群岛",
    "gt": "危地马拉",
    "gu": "关岛",
    "gw": "几内亚比绍",
    "gy": "圭亚那",
    "hk": "香港",
    "hm": "赫德岛和麦克唐纳群岛",
    "hn": "洪都拉斯",
    "hr": "克罗地亚",
    "ht": "海地",
    "hu": "匈牙利",
    "id": "印度尼西亚",
    "ie": "爱尔兰",
    "il": "以色列",
    "im": "马恩岛",
    "in": "印度",
    "io": "英属印度洋领地",
    "iq": "伊拉克",
    "ir": "伊朗",
    "is": "冰岛",
    "it": "意大利",
    "je": "泽西岛",
    "jm": "牙买加",
    "jo": "约旦",
    "jp": "日本",
    "ke": "肯尼亚",
    "kg": "吉尔吉斯斯坦",
    "kh": "柬埔寨",
    "ki": "基里巴斯",
    "km": "科摩罗",
    "kn": "圣基茨和尼维斯",
    "kp": "朝鲜",
    "kr": "韩国",
    "kw": "科威特",
    "ky": "开曼群岛",
    "kz": "哈萨克斯坦",
    "la": "老挝",
    "lb": "黎巴嫩",
    "lc": "圣卢西亚",
    "li": "列支敦士登",
    "lk": "斯里兰卡",
    "lr": "利比里亚",
    "ls": "莱索托",
    "lt": "立陶宛",
    "lu": "卢森堡",
    "lv": "拉脱维亚",
    "ly": "利比亚",
    "ma": "摩洛哥",
    "mc": "摩纳哥",
    "md": "摩尔多瓦",
    "me": "黑山",
    "mg": "马达加斯加",
    "mh": "马绍尔群岛",
    "mk": "北马其顿",
    "ml": "马里",
    "mm": "缅甸",
    "mn": "蒙古",
    "mo": "澳门",
    "mp": "北马里亚纳群岛",
    "mq": "马提尼克",
    "mr": "毛里塔尼亚",
    "ms": "蒙特塞拉特",
    "mt": "马耳他",
    "mu": "毛里求斯",
    "mv": "马尔代夫",
    "mw": "马拉维",
    "mx": "墨西哥",
    "my": "马来西亚",
    "mz": "莫桑比克",
    "na": "纳米比亚",
    "nc": "新喀里多尼亚",
    "ne": "尼日尔",
    "nf": "诺福克岛",
    "ng": "尼日利亚",
    "ni": "尼加拉瓜",
    "nl": "荷兰",
    "no": "挪威",
    "np": "尼泊尔",
    "nr": "瑙鲁",
    "nu": "纽埃",
    "nz": "新西兰",
    "om": "阿曼",
    "pa": "巴拿马",
    "pe": "秘鲁",
    "pf": "法属波利尼西亚",
    "pg": "巴布亚新几内亚",
    "ph": "菲律宾",
    "pk": "巴基斯坦",
    "pl": "波兰",
    // "pm": "圣皮埃尔和密克隆"
    "pn": "皮特凯恩群岛",
    "pr": "波多黎各",
    "ps": "巴勒斯坦领土",
    "pt": "葡萄牙",
    "pw": "帕劳",
    "py": "巴拉圭",
    "qa": "卡塔尔",
    "re": "留尼汪",
    "ro": "罗马尼亚",
    "rs": "塞尔维亚",
    "ru": "俄罗斯",
    "rw": "卢旺达",
    "sa": "沙特阿拉伯",
    "sb": "所罗门群岛",
    "sc": "塞舌尔",
    "sd": "苏丹",
    "se": "瑞典",
    "sg": "新加坡",
    "sh": "圣赫勒拿",
    "si": "斯洛文尼亚",
    "sj": "斯瓦尔巴特和扬马延",
    "sk": "斯洛伐克",
    "sl": "塞拉利昂",
    "sm": "圣马力诺",
    "sn": "塞内加尔",
    "so": "索马里",
    "sr": "苏里南",
    "st": "圣多美和普林西比",
    "sv": "萨尔瓦多",
    "sy": "叙利亚",
    "sz": "斯威士兰",
    "tc": "特克斯和凯科斯群岛",
    "td": "乍得",
    "tf": "法属南部领地",
    "tg": "多哥",
    "th": "泰国",
    "tj": "塔吉克斯坦",
    "tk": "托克劳",
    "tl": "东帝汶",
    "tm": "土库曼斯坦",
    "tn": "突尼斯",
    "to": "汤加",
    "tr": "土耳其",
    "tt": "特立尼达和多巴哥",
    "tv": "图瓦卢",
    "tw": "台湾",
    "tz": "坦桑尼亚",
    "ua": "乌克兰",
    "ug": "乌干达",
    "um": "美国本土外小岛屿",
    "us": "美国",
    "uy": "乌拉圭",
    "uz": "乌兹别克斯坦",
    "va": "梵蒂冈",
    "vc": "圣文森特和格林纳丁斯",
    "ve": "委内瑞拉",
    "vg": "英属维尔京群岛",
    "vi": "美属维尔京群岛",
    "vn": "越南",
    "vu": "瓦努阿图",
    "wf": "瓦利斯和富图纳",
    "ws": "萨摩亚",
    "ye": "也门",
    "yt": "马约特",
    "za": "南非",
    "zm": "赞比亚",
    "zw": "津巴布韦",
}

export const esCountries = {
    "ad": "Andorra",
    "ae": "Emiratos Árabes Unidos",
    "af": "Afganistán",
    "ag": "Antigua y Barbuda",
    "ai": "Anguila",
    "al": "Albania",
    "am": "Armenia",
    "an": "Antillas Neerlandesas",
    "ao": "Angola",
    "aq": "Antártida",
    "ar": "Argentina",
    "as": "Samoa Americana",
    "at": "Austria",
    "au": "Australia",
    "aw": "Aruba",
    "ax": "Islas Åland",
    "az": "Azerbaiyán",
    "ba": "Bosnia y Herzegovina",
    "bb": "Barbados",
    "bd": "Bangladés",
    "be": "Bélgica",
    "bf": "Burkina Faso",
    "bg": "Bulgaria",
    "bh": "Baréin",
    "bi": "Burundi",
    "bj": "Benín",
    "bm": "Bermudas",
    "bn": "Brunéi",
    "bo": "Bolivia",
    "br": "Brasil",
    "bs": "Bahamas",
    "bt": "Bután",
    "bv": "Isla Bouvet",
    "bw": "Botsuana",
    "by": "Bielorrusia",
    "bz": "Belice",
    "ca": "Canadá",
    "cc": "Islas Cocos",
    "cd": "Congo",
    "cf": "República Centroafricana",
    "cg": "Congo",
    "ch": "Suiza",
    "ci": "Costa de Marfil",
    "ck": "Islas Cook",
    "cl": "Chile",
    "cm": "Camerún",
    "cn": "China",
    "co": "Colombia",
    "cr": "Costa Rica",
    "cu": "Cuba",
    "cv": "Cabo Verde",
    "cx": "Isla de Navidad",
    "cy": "Chipre",
    "cz": "República Checa",
    "de": "Alemania",
    "dj": "Yibuti",
    "dk": "Dinamarca",
    "dm": "Dominica",
    "do": "República Dominicana",
    "dz": "Argelia",
    "ec": "Ecuador",
    "ee": "Estonia",
    "eg": "Egipto",
    "eh": "Sahara Occidental",
    "er": "Eritrea",
    "es": "España",
    "et": "Etiopía",
    "fi": "Finlandia",
    "fj": "Fiyi",
    "fk": "Islas Malvinas",
    "fm": "Micronesia",
    "fo": "Islas Feroe",
    "fr": "Francia",
    "ga": "Gabón",
    "gb": "Reino Unido",
    "gd": "Granada",
    "ge": "Georgia",
    "gf": "Guayana Francesa",
    "gg": "Guernsey",
    "gh": "Ghana",
    "gi": "Gibraltar",
    "gl": "Groenlandia",
    "gm": "Gambia",
    "gn": "Guinea",
    "gp": "Guadalupe",
    "gq": "Guinea Ecuatorial",
    "gr": "Grecia",
    "gs": "Georgia del Sur e Islas Sandwich del Sur",
    "gt": "Guatemala",
    "gu": "Guam",
    "gw": "Guinea-Bisáu",
    "gy": "Guyana",
    "hk": "Hong Kong",
    "hm": "Islas Heard y McDonald",
    "hn": "Honduras",
    "hr": "Croacia",
    "ht": "Haití",
    "hu": "Hungría",
    "id": "Indonesia",
    "ie": "Irlanda",
    "il": "Israel",
    "im": "Isla de Man",
    "in": "India",
    "io": "Territorio Británico del Océano Índico",
    "iq": "Irak",
    "ir": "Irán",
    "is": "Islandia",
    "it": "Italia",
    "je": "Jersey",
    "jm": "Jamaica",
    "jo": "Jordania",
    "jp": "Japón",
    "ke": "Kenia",
    "kg": "Kirguistán",
    "kh": "Camboya",
    "ki": "Kiribati",
    "km": "Comoras",
    "kn": "San Cristóbal y Nieves",
    "kp": "Corea del Norte",
    "kr": "Corea del Sur",
    "kw": "Kuwait",
    "ky": "Islas Caimán",
    "kz": "Kazajistán",
    "la": "Laos",
    "lb": "Líbano",
    "lc": "Santa Lucía",
    "li": "Liechtenstein",
    "lk": "Sri Lanka",
    "lr": "Liberia",
    "ls": "Lesoto",
    "lt": "Lituania",
    "lu": "Luxemburgo",
    "lv": "Letonia",
    "ly": "Libia",
    "ma": "Marruecos",
    "mc": "Mónaco",
    "md": "Moldavia",
    "me": "Montenegro",
    "mg": "Madagascar",
    "mh": "Islas Marshall",
    "mk": "Macedonia",
    "ml": "Malí",
    "mm": "Myanmar",
    "mn": "Mongolia",
    "mo": "Macao",
    "mp": "Islas Marianas del Norte",
    "mq": "Martinica",
    "mr": "Mauritania",
    "ms": "Montserrat",
    "mt": "Malta",
    "mu": "Mauricio",
    "mv": "Maldivas",
    "mw": "Malaui",
    "mx": "México",
    "my": "Malasia",
    "mz": "Mozambique",
    "na": "Namibia",
    "nc": "Nueva Caledonia",
    "ne": "Níger",
    "nf": "Isla Norfolk",
    "ng": "Nigeria",
    "ni": "Nicaragua",
    "nl": "Países Bajos",
    "no": "Noruega",
    "np": "Nepal",
    "nr": "Nauru",
    "nu": "Niue",
    "nz": "Nueva Zelanda",
    "om": "Omán",
    "pa": "Panamá",
    "pe": "Perú",
    "pf": "Polinesia Francesa",
    "pg": "Papúa Nueva Guinea",
    "ph": "Filipinas",
    "pk": "Pakistán",
    "pl": "Polonia",
    "pm": "San Pedro y Miquelón",
    "pn": "Islas Pitcairn",
    "pr": "Puerto Rico",
    "ps": "Territorios Palestinos",
    "pt": "Portugal",
    "pw": "Palaos",
    "py": "Paraguay",
    "qa": "Catar",
    "re": "Reunión",
    "ro": "Rumania",
    "rs": "Serbia",
    "ru": "Rusia",
    "rw": "Ruanda",
    "sa": "Arabia Saudita",
    "sb": "Islas Salomón",
    "sc": "Seychelles",
    "sd": "Sudán",
    "se": "Suecia",
    "search": "Buscar países",
    "sg": "Singapur",
    "sh": "Santa Elena",
    "si": "Eslovenia",
    "sj": "Svalbard y Jan Mayen",
    "sk": "Eslovaquia",
    "sl": "Sierra Leona",
    "sm": "San Marino",
    "sn": "Senegal",
    "so": "Somalia",
    "sr": "Surinam",
    "st": "Santo Tomé y Príncipe",
    "sv": "El Salvador",
    "sy": "Siria",
    "sz": "Esuatini",
    "tc": "Islas Turcas y Caicos",
    "td": "Chad",
    "tf": "Territorios Australes Franceses",
    "tg": "Togo",
    "th": "Tailandia",
    "tj": "Tayikistán",
    "tk": "Tokelau",
    "tl": "Timor Oriental",
    "tm": "Turkmenistán",
    "tn": "Túnez",
    "to": "Tonga",
    "tp": "Timor Oriental",
    "tr": "Turquía",
    "tt": "Trinidad y Tobago",
    "tv": "Tuvalu",
    "tw": "Taiwán",
    "tz": "Tanzania",
    "ua": "Ucrania",
    "ug": "Uganda",
    "um": "Islas menores alejadas de EE. UU.",
    "us": "Estados Unidos",
    "uy": "Uruguay",
    "uz": "Uzbekistán",
    "va": "Vaticano",
    "vc": "San Vicente y las Granadinas",
    "ve": "Venezuela",
    "vg": "Islas Vírgenes Británicas",
    "vi": "Islas Vírgenes de EE. UU.",
    "vn": "Vietnam",
    "vu": "Vanuatu",
    "wf": "Wallis y Futuna",
    "ws": "Samoa",
    "xy": "País aleatorio",
    "ye": "Yemen",
    "yt": "Mayotte",
    "za": "Sudáfrica",
    "zm": "Zambia",
    "zw": "Zimbabue",
}