import React, { useContext, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";

import { UserContext } from "core/contexts";
import RulesBlock from "components/RulesBlock";

function PanelRulesPage(props) {
  const user = useContext(UserContext).user;
  const { t } = useTranslation();

  return (
    <>
      <div className="page page-panel">
        <Sidebar />
        <div className="container container-panel">
          <div className="panel__content">
            <div className="balance">
              <div className="shadow-block">
                <RulesBlock />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PanelRulesPage;
