import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilCheck,
  cilListFilter,
  cilOptions,
  cilSettings,
} from "@coreui/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "utils/settings";
import { getDate, getStringDate } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CouponsTable(props) {
  let history = useHistory();

  const {
    getCoupons,
    coupons,
    total,
    page,
    setTotal,
    setCoupons,
    perPage,
    filters,
    setFilters,
    title,
    sorting,
    activeSorting,
    setSorting,
    setPage,
    setPerPage,
    isLoading,
    setLoading,
  } = props;
  const [deleteButton, showDeleteButton] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [count, setCount] = useState(0);

  const windowSize = useWindowSize()[0];
  const isMobile = windowSize < 767;
  const renderDate = (date) => {
    const newDate = new Date(date).toLocaleString();
    return (
      <div>
        <CTooltip content={<>{`${newDate}`}</>}>
          <div>{newDate?.split(",")[0] || "—"}</div>
        </CTooltip>
      </div>
    );
  };
  useEffect(() => {
    getCoupons(page, perPage, filters, activeSorting);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage, filters]);

  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.id"),
      center: true,
      selector: (row) => row.id || "—",
      sortable: false,
    },
    {
      id: "userId",
      name: t(`table.user`),
      center: true,
      selector: (row) => (
        <CTooltip content={<>{`${row.user.email || "—"}`}</>}>
          {row.user.id ? (
            <a href={`/admin/users/${row.user.id}`} target="_blank">
              {row.user.id || "—"}
            </a>
          ) : (
            <div>{row.user.id || "—"}</div>
          )}
        </CTooltip>
      ),
      sortable: false,
      notMobile: true,
    },

    {
      id: "balance",
      name: t("table.amount"),
      center: true,
      selector: (row) => {
        if (row.amount) {
          return new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: row.currency || "USD",
          }).format(row.amount || 0);
        } else {
          return 0;
        }
      },
      sortable: false,
    },
    {
      id: "status",
      name: t(`table.status`),
      center: true,
      selector: (row) => t(`table.${row.status}`),
      sortable: false,
    },
    {
      id: "adminId",
      name: t(`table.adminId`),
      center: true,
      selector: (row) => (
        <CTooltip content={<>{`${row.admin.email}`}</>}>
          {row.admin.id ? (
            <a href={`/admin/users/${row.admin.id}`} target="_blank">
              {row.admin.id || "—"}
            </a>
          ) : (
            <div>{row.admin.id || "—"}</div>
          )}
        </CTooltip>
      ),
      sortable: false,
      notMobile: true,
    },
    {
      id: "coupon",
      name: t("table.coupon"),
      center: true,
      selector: (row) => row.coupon || "—",
      sortable: false,
    },
    {
      id: "createDate",
      name: t("table.date_create"),
      center: true,
      selector: (row) => (row.createdAt ? renderDate(row.createdAt) : "—"),

      sortable: false,
      minWidth: "190px",
      notMobile: true,
    },
    {
      id: "deleteDate",
      name: t("table.date_delete"),
      center: true,
      selector: (row) => (row.deletedAt ? renderDate(row.deletedAt) : "—"),
      sortable: false,
      minWidth: "190px",
      notMobile: true,
    },
  ];
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    // console.log("Selected Rows: ", selectedRows);
    if (selectedRows?.length > 0) {
      setRowsToDelete(selectedRows.map((el) => el.id));
    } else {
      setRowsToDelete([]);
    }
  };
  useEffect(() => {
    setToggleClearRows(!toggledClearRows);
    setRowsToDelete([]);
  }, [page]);
  useEffect(() => {
    if (rowsToDelete?.length > 0) {
      showDeleteButton(true);
    } else {
      showDeleteButton(false);
    }
  }, [rowsToDelete]);
  const clearDeletedRows = () => {};
  const deleteItems = () => {
    setLoading(true);
    Axios.delete(`${API_URL}/admin/coupon`, {
      data: {
        idList: rowsToDelete,
      },
    })
      .then(function (response) {
        if (response) {
          ToastComponent("success", t("table.coupons_is_delete"));
          setRowsToDelete([]);
          setToggleClearRows(!toggledClearRows);
          clearDeletedRows();
          getCoupons(page, perPage, filters, activeSorting);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const rowDisabledCriteria = (row) =>
    row.status == "activated" || row.status == "deleted";
  const mobileChange = (e, id) => {
    if (e.target.checked) {
      setRowsToDelete(rowsToDelete.concat(id));
    } else {
      setRowsToDelete(rowsToDelete.filter((el) => el !== id));
    }
  };
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <CSpinner color="primary" />
          </div>
        )}
        <h6 className="hidden_sm hidden_md">
          {t("menu.coupons")} - {total}
        </h6>
        {(filters?.length > 0 || sorting?.length > 0) && (
          <>
            <div className="filters-mobile">
              <h6>{t("menu.coupons")}</h6>

              <span onClick={() => setShowFilters(true)}>
                <CIcon icon={cilListFilter} />
                {t("filters")}
              </span>
            </div>{" "}
            {showFilters && (
              <div
                className="filters-plug"
                onClick={() => setShowFilters(false)}
              />
            )}
            <div className={`filters-wrapper ${showFilters ? "active" : ""}`}>
              {showFilters && (
                <div
                  className="filters-close"
                  onClick={() => setShowFilters(false)}
                >
                  <CIcon icon={cilX} />
                </div>
              )}
              {filters?.length > 0 && (
                <Filters
                  title={title || ""}
                  items={filters}
                  setFilters={(val) => {
                    setFilters(val);
                    setCount(count + 1);
                    if (page == 1 && count > 0) {
                      getCoupons(page, perPage, val, activeSorting);
                    }
                  }}
                />
              )}
              {sorting?.length > 0 && coupons?.length > 0 && (
                <>
                  <Sorting
                    items={sorting}
                    setItems={setSorting}
                    active={activeSorting}
                  />
                </>
              )}
            </div>
          </>
        )}
        {!isMobile ? (
          <div className="position-relative">
            {deleteButton && (
              <CButton
                className="delete-button"
                onClick={() => deleteItems()}
                color="danger"
              >
                {t("table.delete")}
              </CButton>
            )}
            {/* {coupons?.length > 0 && ( */}
            <DataTable
              columns={columns}
              data={coupons}
              sortIcon={<CIcon icon={cilArrowTop} />}
              noDataComponent={t("table.notFound")}
              selectableRows={coupons?.length > 0}
              onSelectedRowsChange={(selectedRows) =>
                coupons?.length > 0 && handleChange(selectedRows)
              }
              clearSelectedRows={toggledClearRows}
              selectableRowDisabled={rowDisabledCriteria}

              // progressPending={isLoading}
              // progressComponent={<CSpinner color="primary" />}
            />
            {/* )} */}
          </div>
        ) : (
          <div className="mobile-table">
            {deleteButton && (
              <CButton
                className="delete-button"
                onClick={() => deleteItems()}
                color="danger"
              >
                {t("table.delete")}
              </CButton>
            )}
            {coupons?.length > 0 ? (
              coupons.map((row) => {
                return (
                  <div className="mobile-table__item mobile-table__item_xs mobile-table__item_check">
                    <input
                      type="checkbox"
                      className="mobile-table__checkbox"
                      disabled={
                        row.status == "deleted" || row.status == "activated"
                      }
                      checked={rowsToDelete?.includes(row.id)}
                      onChange={(e) => mobileChange(e, row.id)}
                    />
                    {columns.map((el, i) => {
                      return !el.notMobile ? (
                        <div
                          className={`mobile-table__row mobile-table__row_${i}`}
                        >
                          {el.name && (
                            <div className="mobile-table__key">{el.name}</div>
                          )}
                          {el.selector(row) && (
                            <div className="mobile-table__value">
                              {el.selector(row)}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className="mobile-info mobile-info_transparent">
                            <CTooltip
                              content={
                                <div className="tooltip-item">
                                  {columns.map((el) => {
                                    return (
                                      (el.id == "id" ||
                                        el.id == "createDate" ||
                                        el.id == "adminId" ||
                                        el.id == "deleteDate") && (
                                        <div className="tooltip-row">
                                          <div className="tooltip-key">
                                            {el.name}
                                          </div>
                                          <div className="tooltip-key">
                                            {el.selector(row)}
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                                </div>
                              }
                            >
                              <CIcon icon={cilOptions} />
                            </CTooltip>
                          </div>
                        </>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <div className="mobile-table__empty">{t("table.notFound")}</div>
            )}
          </div>
        )}
        {coupons?.length > 0 && (
          <TablePagination
            page={page}
            setPage={(page) => {
              setPage(page);
            }}
            total={total}
            perPage={perPage}
            setPerPage={(val) => {
              setPerPage(val);
              if (page == 1) {
                getCoupons(page, val, filters, activeSorting);
              }
            }}
          />
        )}
      </div>
    </>
  );
}

export default CouponsTable;
