import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Heart from "../../assets/images/heart.svg";
import Cart from "../../assets/images/cart.svg";
import HeartActive from "../../assets/images/heart_active.svg";
import { renderCurrency, useLocalStorage } from "utils/utils";
import classNames from "classnames";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { CFormInput, CSpinner } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilDelete, cilHeart, cilSearch, cilX } from "@coreui/icons";
import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import ToastComponent from "components/ToastComponent";

function ServiceItem(props) {
  const {
    el,
    favoriteServices,
    setFavoriteServices,
    getOrders,
    page,
    index,
    perPage,
    filters,
    updateUser,
    show,
    setShow,
  } = props;
  const [search, setSearch] = useState("");
  const [locale] = useLocalStorage("locale", "gb");
  const [loadingId, setLoadingId] = useState([]);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [topActive, setTopActive] = useState(false);
  const [preProducts, setPreProducts] = useState(null);
  const [defaultProducts, setDefaultProducts] = useState(null);
  const handleFavorite = (name) => {
    let items;
    if (!favoriteServices) {
      items = [];
    } else {
      items = JSON.parse(favoriteServices);
    }
    if (items?.includes(name)) {
      setFavoriteServices(JSON.stringify(items.filter((el) => el !== name)));
    } else {
      setFavoriteServices(JSON.stringify(items.concat([name])));
    }
  };
  const handleProduct = (id) => {
    setLoading(true);
    Axios.get(`${API_URL}/product/list`, {
      params: {
        service: id,
      },
    })
      .then(function (response) {
        setLoading(false);
        if (response.data.data) {
          setProducts(response.data.data.filter((pr) => pr.available));
          setDefaultProducts(response.data.data.filter((pr) => pr.available));
          setPreProducts(response.data.data.filter((pr) => pr.available));
          let fullItems = document.querySelectorAll(".services__item");
          const list = document.querySelector(".services-list");
          list?.scroll(0, fullItems[index].offsetTop - 100);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleTop = (id) => {
    setLoading(true);
    Axios.get(`${API_URL}/order/stats`, {
      params: {
        service: id,
      },
    }).then(function (response) {
      setLoading(false);
      if (response.data.data?.length > 0) {
        setTopActive(true);
        setProducts(
          preProducts.filter(
            (p) =>
              response.data.data.filter((it) => it.country == p.productId)?.[0]
          )
        );
        setDefaultProducts(
          preProducts.filter(
            (p) =>
              response.data.data.filter((it) => it.country == p.productId)?.[0]
          )
        );
      }
    });
  };
  const handleChange = (val) => {
    setSearch(val);
  };
  useEffect(() => {
    if (search?.length > 0) {
      const countriesObj = {
        ru: ruCountries,
        gb: enCountries,
        cn: cnCountries,
        es: esCountries,
      };
      let list = countriesObj[locale];
      let data = Object.entries(list).filter((el) =>
        el[1].toLowerCase().includes(search.toLowerCase()?.trim())
      );
      let filteredList = defaultProducts.filter((el) => {
        let countryItems = data.filter((countryItem) => {
          if (el.country === countryItem[0]) {
            return countryItem[0];
          }
          return null;
        });
        if (countryItems?.length > 0) {
          return el;
        }
        return null;
      });

      setProducts(filteredList);
    } else {
      setProducts(defaultProducts);
    }
  }, [search]);
  const buy = (id) => {
    setLoadingId(loadingId.concat([id]));
    Axios.post(`${API_URL}/order`, {
      productId: id,
    })
      .then(function (response) {
        if (response.data.status == "error") {
          ToastComponent("error", response.data.message);
        } else {
          ToastComponent("success", t("table.order_create"));
        }
        if (response.data.orderId) {
          getOrders(page, perPage, filters);
          updateUser();
          setLoadingId(loadingId.filter((ids) => ids !== id));
        }
      })
      .catch((error) => {
        updateUser();
        setLoadingId(loadingId.filter((ids) => ids !== id));
      });
  };
  return (
    <div
      className={classNames({
        services__item: true,
        show: show == el.service,
        disabled: loading,
      })}
      disabled={loading}
    >
      <div
        className="services__item-header"
        onClick={() => {
          if (show == el.service) {
            setShow(null);
          } else {
            setShow(el.service);
          }
          // if (!products) {
          handleProduct(el.service);
          // }
        }}
      >
        {loading && (
          <CSpinner color={"primary"} size="sm" className="service__spinner" />
        )}

        <img
          src={
            JSON.parse(favoriteServices)?.filter((fs) => fs == el.service)
              ?.length > 0
              ? HeartActive
              : Heart
          }
          className={classNames({
            services__favorite: true,
            services__favorite_default:
              JSON.parse(favoriteServices)?.filter((fs) => fs == el.service)
                ?.length == 0,
          })}
          onClick={(event) => {
            handleFavorite(el.service);
            event.stopPropagation();
          }}
        />
        <div className="service__info">
          <div className={`bg bg-${el.service}`} alt={el.service} />
          <div className="service__name">{el.serviceName}</div>
          <div className="service__count">
            {el?.availablePhonesAmount == 0
              ? t("table.few")
              : `${el.availablePhonesAmount} ${t("services.itemsCount")}`}
          </div>
        </div>
        <div className="service__price">
          {t("services.from")} {el.priceAmount}{" "}
          {renderCurrency(el.priceCurrency)}
        </div>
      </div>
      {products !== null && show == el.service && (
        <div className="service__products">
          <div className="service__products-search">
            <CFormInput
              placeholder={t("search.country")}
              value={search}
              onChange={(e) => handleChange(e.target.value)}
            />
            {search?.length > 1 ? (
              <CIcon icon={cilX} onClick={() => setSearch("")} />
            ) : (
              <CIcon icon={cilSearch} />
            )}
          </div>
          <div className="service__filters">
            <span
              className={classNames({
                active: topActive,
                disabled: loading,
              })}
              disabled={loading}
              onClick={() => {
                if (!topActive) {
                  handleTop(el.service);
                } else {
                  setTopActive(false);
                  setProducts(preProducts);
                  setDefaultProducts(preProducts);
                }
              }}
            >
              {t('CountriesTop')}&nbsp;
              {topActive && (
                <CIcon icon={cilX} style={{ opacity: topActive ? 1 : 0 }} />
              )}
            </span>
          </div>
          {products?.map((item) => {
            return (
              <div
                className={classNames({
                  service__product: true,
                  disabled: loadingId.includes(item.productId),
                })}
                disabled={loadingId.includes(item.productId)}
              >
                <div></div>
                <div className="service__info">
                  <div
                    className={`flag flag-${item.country}`}
                    alt={t(`country.${item.country}`)}
                  />
                  <div className="service__name">
                    {t(`country.${item.country}`)}
                  </div>
                  <div className="service__count">
                    {!item?.availablePhonesAmount ? (
                      t("table.few")
                    ) : (
                      <>{`${item.availablePhonesAmount} ${t(
                        "services.itemsCount"
                      )}`}</>
                    )}
                  </div>
                </div>
                <div className="service__price">
                  {item.priceAmount} {renderCurrency(item.priceCurrency)}
                </div>
                <button
                  className="service__cart"
                  onClick={() => buy(item.productId)}
                  disabled={loadingId?.includes(item.productId)}
                >
                  {loadingId?.includes(item.productId) ? (
                    <CSpinner size="sm" color="primary" />
                  ) : (
                    <img src={Cart} />
                  )}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ServiceItem;
