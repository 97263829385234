import React from 'react';
import './index.scss';
import NotFoundPic from '../../assets/images/404.png'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function NotFound(props) {
    const { t } = useTranslation()

    return <div className="not-found">
        <img src={NotFoundPic} alt="" />
        <div className="not-found__buttons">
            <Link  className="btn btn-primary btn-lg " to={`${props.authorized ? '/panel/main' : '/auth'}`}>{t('nav.profile')}</Link>
            <Link className="btn btn-primary btn-lg" to="/">{t('nav.main')}</Link>
        </div>
    </div>
}
export default NotFound